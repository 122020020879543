import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, message } from '@shipmnts/pixel-hub';
import { UPDATE_SHIPMENT_FIELDS } from 'operations/modules/shipment/components/DetailLayout/Containers/graphql';

interface DeLinkOrderProp {
  parentId: any;
  spoiId: any;
  onSuccess?: () => void;
  onClose?: () => void;
}

const DeLinkOrder = React.memo(function DeLinkOrder(props: DeLinkOrderProp) {
  const { spoiId, parentId, onSuccess, onClose } = props;

  const [upsertSpoi, { loading, data }] = useMutation(UPDATE_SHIPMENT_FIELDS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to De-Link this Product from current shipment`,
      okText: 'Ok',
      okType: 'danger',
      onOk: () => {
        upsertSpoi({
          variables: {
            id: parentId,
            shipment: {
              cargos: [
                {
                  id: spoiId,
                  _destroy: true,
                },
              ],
            },
          },
        });
      },
      cancelText: 'Cancel',
      onCancel: () => {
        if (onClose) onClose();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertSpoi]);

  useEffect(() => {
    if (!!data?.update_shipment) {
      message.success(`Successfully Deleted Item`);
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onSuccess]);

  if (loading) {
    return <div>loading...</div>;
  }

  return <></>;
});

export default DeLinkOrder;
