import { gql } from '@apollo/client';
import { minimumCompanyFields } from 'network/graphql/company';

export const productPropertyFields = gql`
  fragment productPropertyFields on CargoPropertiesObjectType {
    is_perishable
    is_temp_controlled
    is_hazardous
    is_battery
    un_number
    un_imo_class
    proper_shipping_name
    technical_name
    packaging_group
    net_weight
    flash_point
    flash_point_unit
    ems_number
    is_palletisable
    is_radioactive
    is_stackable
    is_tiltable
    temperature_unit
    cooling_type
    battery_type
    min_temperature
    max_temperature
    temperature_range
    emergency_contact_name
    emergency_contact_number
  }
`;

export const productPackagingOptionFields = gql`
  fragment productPackagingOptionFields on ProductPackagingOptionObjectType {
    id
    packing_type
    base_uom
    dimension_unit
    length
    breadth
    height
    weight_unit
    gross_weight
    net_weight
    tare_weight
    is_primary_packaging
    product_id
    base_qty
    gross_volume
    inner_by_outer_qty
    outer_packing_type
  }
`;

export const productFields = gql`
  fragment productFields on ProductObjectType {
    id
    product_name
    product_code
    uom
    gst_hsn_code
    country_of_origin
    description
    has_batch_no
    has_serial_no
    is_sales_item
    is_purchase_item
    buying_currency
    selling_currency
    isbn_number
    ean_number
    upc_number
    mpn_number
    disabled
    product_property {
      ...productPropertyFields
    }
    customer {
      ...minimumCompanyFields
    }
    customer_ref_code
    product_packaging_options {
      ...productPackagingOptionFields
    }
  }
  ${minimumCompanyFields}
  ${productPackagingOptionFields}
  ${productPropertyFields}
`;

export const GET_PRODUCT = gql`
  query get_product($id: ID!) {
    get_product(id: $id) {
      ...productFields
    }
  }
  ${productFields}
`;

export const UPSERT_WMS_PRODUCT = gql`
  mutation upsert_wms_product($product: ProductInputType!) {
    upsert_wms_product(product: $product) {
      ...productFields
    }
  }
  ${productFields}
`;
