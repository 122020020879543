// Base Modal component for Inquiry Form
// TODO: check responsiveness
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  addDocumentParents,
  dayjs,
  documentsStore,
  Form,
  message,
  Tabs,
} from '@shipmnts/pixel-hub';
import type { TabsProps } from '@shipmnts/pixel-hub';

import {
  LOAD_TYPE_OPTIONS,
  RENDER_INQUIRY_FOR_WON,
  SERVICES,
  TypeFreightType,
} from 'sales_hub/utils/constants';

import InquiryBasicDetails from './InquiryBasicDetails';
import { InquiryCustomerType, InquiryFormContextType, InquiryFormPropType } from './types';
import InquiryRoutingDetails from './InquiryRoutingDetails';
import InquiryCargoDetails from './InquiryCargoDetails';
import InquiryPartyDetails from './InquiryPartyDetails';
import InquiryServices from './InquiryServices';
import InquiryAdditionalDetails from './InquiryAdditionalDetails';
import { useSession } from 'common';
import { INQUIRY_LOST_STATUS } from 'sales_hub/models/inquiry';
import { getInquiryParamsFromInquiry } from '../common';
import InquiryDocumentUpload from './InquiryDocumentUpload';
import { BranchAccountValue } from 'common/models/BranchAccount';
import InquiryTeamDetails from './InquiryTeamDetails';
import { calculateVolumetricWeight, DIMENSION_CBM } from 'sales_hub/utils/dimensionHelper';
import { Unit } from 'convert-units';
import { round as _round } from 'lodash';
import { WEIGHT_UNIT_KGS } from 'network/baseConstants';
import { TeamValue } from 'common/models/Team';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

// All the sections of form are siblings,
// defining a context to avoid passing common inquiry details to each one of them.
const InquiryFormContext = createContext<InquiryFormContextType | null>(null);

const InquiryForm = (props: InquiryFormPropType) => {
  const session = useSession();

  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  const { form, data, inquiry, onFinish, noTabs = false, renderForm, isAmend = false } = props;
  const { fields } = data || {};

  // common fields, based on which other fields are shown / hidden.
  const freightType: TypeFreightType = Form.useWatch('freight_type', form);
  const loadType: string = Form.useWatch('load_type', form);
  const customer: InquiryCustomerType = Form.useWatch('customer', form);
  const branchAccount: BranchAccountValue = Form.useWatch('branchAccount', form);
  const businessReceivedThrogh: string = Form.useWatch('business_received_through', form);

  //states
  const [isDetailForm, setIsDetailForm] = useState(noTabs);

  const [documentsStoreValue, dispatch] = useReducer(documentsStore, {
    uploadingDocuments: [],
    errors: {},
    documents: [],
  });

  // effect - based on business vertical, prefilling fields
  useEffect(() => {
    if (data) {
      const { fields } = data;
      fields &&
        Object.entries(fields).forEach((entry) => {
          const field = entry[0].split('.');
          form.setFieldValue(field, entry[1]);
          if (entry[0].includes('freight_type') && entry[1]) {
            if (!Object.keys(LOAD_TYPE_OPTIONS).includes(entry[1])) return;
            const freight_type = entry[1] as TypeFreightType;
            form.setFieldValue('load_type', LOAD_TYPE_OPTIONS[freight_type || 'air'][0].value);
          }
        });
    }
  }, [data, form]);

  // Constants
  const items = useMemo<TabsProps['items']>( // Tabs for this modal - Quick & Detail Inquiry Form
    () => [
      {
        key: 'quick_inquiry_form',
        label: 'Quick Form',
      },
      {
        key: 'detailed_inquiry_form',
        label: 'Detail Form',
      },
    ],
    []
  );

  const disableInquiryForm = useMemo(
    () =>
      inquiry?.last_action_status === INQUIRY_LOST_STATUS && renderForm !== RENDER_INQUIRY_FOR_WON,
    [inquiry, renderForm]
  );

  // callbacks
  // based on business vertical which services to show selected
  const getInitialServices = useCallback(() => {
    if (inquiry) {
      return inquiry.services || [];
    }
    const keys = Object.keys(fields || {});
    return SERVICES.filter((service) => keys.includes(`services.${service.value}`)).map(
      (service) => service.value
    );
  }, [fields, inquiry]);

  // on changing weight unit or gross_volume, volumetric weight should get updated too
  const handleVolumetricWeight = useCallback(
    (volume: number, weightUnit: Unit) => {
      const volumetricWeight = calculateVolumetricWeight({
        volume: volume,
        volumeUnit: DIMENSION_CBM as Unit,
        weightUnit: weightUnit,
        factor: 6000,
      });

      form.setFieldValue(
        ['cargo', 'volumetric_weight'],
        !!volumetricWeight ? _round(volumetricWeight, 2) : 0
      );
    },
    [form]
  );

  // once inquiry is created we need to link this new inquiry
  // with documents that have been uploaded.
  const updateDocs = useCallback(
    async (inquiry_id: string) => {
      if (!isDetailForm) return; // document upload not possible in quick form
      const { documents } = documentsStoreValue;
      if (documents.length > 0) {
        const _updateDocIds = documents.map((d: any) => d.id);
        const { response, error } = await addDocumentParents(
          _updateDocIds,
          [{ parent_type: 'inquiry', parent_id: inquiry_id }],
          session.id,
          process.env.DOCGEN_URL || ''
        );
        if (error) {
          message.error('Document upload failed');
        } else if (response) {
          dispatch({ type: 'reset_state' });
        }
      }
    },
    [session.id, documentsStoreValue, isDetailForm]
  );

  // incase user fills some fields in detail form (which are not part of quick form)
  // and submits the quick form
  const clearDetailFormFields = useCallback(() => {
    form.resetFields([
      'priority',
      'received_at',
      'inquiryDescription',
      ['customer', 'party_address'],
      ['billing_party', 'party_address'],
      'shipment_parties',
      'teams',
      'services',
      'preferredCarrier',
      'target_rate',
      'tags',
      'targetClosingDate',
      'targetDeliveryDate',
      'cargoReadyDate',
      'freight_terms',
      'other_terms',
      'origin_carrier_free_days',
      'destination_carrier_free_days',
      'destination_port_free_days',
      'destinationCustomClearance',
      'originCustomClearance',
    ]);
  }, [form]);

  return (
    <>
      <div
        style={
          noTabs
            ? {}
            : {
                height: '80vh',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }
        }
        className={noTabs ? '' : 'custom-scrollbar'} // to hide scrollbar
      >
        <Form
          form={form}
          requiredMark={isDetailForm}
          layout="vertical"
          disabled={disableInquiryForm}
          scrollToFirstError={true}
          initialValues={{
            cargos: [
              {
                package_type: 'Box',
                weight_unit: 'kgs',
                volume_unit: 'cbm',
                commodity: {
                  commodity_classification_code: '000000',
                  commodity_coding_system: 'MHS',
                  dangerous_cargo: false,
                  dry_cargo: true,
                  id: '523',
                  live_reefer_cargo: false,
                  name: 'General Cargo',
                  oog_cargo: false,
                },
                commodity_description: 'General Cargo',
              },
            ],
            other_terms: 'prepaid',
            freight_terms: 'prepaid',
            priority: 'lowest',
            trade_type: 'import',
            received_at: dayjs(new Date()),
            business_received_through: 'direct',
            business_vertical_id: String(
              inquiry?.business_vertical?.id ||
                data?.business_vertical_id ||
                session.company_account.business_verticals?.[0]?.id
            ),
            customer: data?.company && { party_company: data?.company },
            containerRequests: [
              {
                container_type: '22G0',
                quantity: 1,
                weight_unit: WEIGHT_UNIT_KGS,
              },
            ],
            // incase values are filled in Quick form,
            // on first render of detail form, we prefill these values
            teams: [
              { role: 'pricing', user_account: form.getFieldValue('pricingPerson') },
              { role: 'sales', sales_person: form.getFieldValue('salesPerson') },
            ],
            ...inquiry,
            ...getInquiryParamsFromInquiry(inquiry),
          }}
          onValuesChange={(changedValues, values) => {
            const newFreightType: TypeFreightType = changedValues?.freight_type;
            // change load type when freight type is changed
            if (!!newFreightType && LOAD_TYPE_OPTIONS[newFreightType])
              form.setFieldValue('load_type', LOAD_TYPE_OPTIONS[newFreightType][0].value);

            if (changedValues?.cargo?.weight_unit || changedValues?.cargo?.gross_volume) {
              handleVolumetricWeight(values?.cargo?.gross_volume, values?.cargo?.weight_unit);
            }

            // if sales person or pricing person is changed in detailed form,
            // then change them in quick form too
            // NOTE: Considering,Detail form will only render after Quick form will render
            if (!!changedValues?.teams) {
              const salesPerson = changedValues?.teams.find((v: TeamValue) => v.role === 'sales');
              const pricingPerson = changedValues?.teams.find(
                (v: TeamValue) => v.role === 'pricing'
              );

              if (salesPerson?.sales_person) {
                form.setFieldValue('salesPerson', salesPerson?.sales_person);
              }
              if (pricingPerson?.user_contact) {
                form.setFieldValue('pricingPerson', pricingPerson.user_contact);
              }
            }
          }}
          onFinish={(values) => {
            if (!isDetailForm) clearDetailFormFields();
            const error = Boolean(cargoRef?.current?.runValidation());
            if (!error)
              onFinish &&
                onFinish(
                  {
                    ...values,
                    cargos: cargoRef?.current?.getPayload(),
                    cargo_properties: undefined,
                  },
                  updateDocs
                );
          }}
        >
          {!noTabs && (
            <Tabs
              items={items}
              onChange={(key) => setIsDetailForm(key === 'detailed_inquiry_form')}
            />
          )}
          <InquiryFormContext.Provider
            value={{
              freightType,
              loadType,
              isDetailForm,
              form,
              inquiry,
              customer,
              branchAccount,
              businessReceivedThrogh,
              isAmend,
              cargoRef,
            }}
          >
            <InquiryBasicDetails />
            {isDetailForm && (
              <>
                <InquiryPartyDetails />
                <InquiryTeamDetails />
                <InquiryServices values={getInitialServices()} />
              </>
            )}
            <InquiryRoutingDetails />
            <InquiryCargoDetails />
            {isDetailForm && (
              <>
                <InquiryAdditionalDetails />
                <InquiryDocumentUpload
                  documentsStoreValue={documentsStoreValue}
                  dispatch={dispatch}
                />
              </>
            )}
          </InquiryFormContext.Provider>
        </Form>
      </div>
    </>
  );
};

export const useInquiryForm = () => {
  const contextValue = useContext(InquiryFormContext);
  if (contextValue === null) {
    throw new Error('contextValue cannot be null, please add a context provider');
  }
  return contextValue;
};

export default InquiryForm;
