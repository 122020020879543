/**
 * ToDo:Container Actions => container.next_acttions + negative actions(update booking / cancel booking)
 *
 * Conainer Actions refrence: https://www.figma.com/file/UMpyv9bGC8cwYS8k1Tt9sO/Event-and-Milestones?node-id=0%3A1
 */

import React from 'react';
import { PRIMARY_TYPE, SECONDARY_TYPE, NEGATIVE_TYPE } from '../constants';
import { Action } from '../models';
import {
  assignLoadRender,
  assignVehicleRender,
  performContainerAction,
  performDeleteContainerAction,
  performEditContainerAction,
  performPrintContainer,
} from './containerActionRenderer';
import { ApartmentOutlined, FilePdfOutlined } from '@shipmnts/pixel-hub';
import { SessionDataValue } from 'operations/models/SessionData';
// import { EmailProps } from 'operations/modules/shipment/components/Tracking/ChangeEvent/EventEmailDrawer'

// import { EditIcon, DeleteIcon } from 'operations/icons';
// import { EmptyContainerSvg } from 'operations/modules/shipment/components/Tracking/Icons'
import { CustomIcon } from '@shipmnts/pixel-hub';
import { isEnableLoadAction, isEnableRoadAction } from '../ShipmentActions/shipmentActionHelper';
import { FREIGHT_TYPE_ROAD } from 'operations/utils/constants';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { ShipmentValue } from 'operations/models/Shipment';

const isEnableContainerLoadAction = (shipment: ShipmentValue) => {
  const isRoadCustomerOrder = shipment?.freight_type === FREIGHT_TYPE_ROAD && !shipment?.job_number;
  return isEnableRoadAction(shipment) && isRoadCustomerOrder;
};

//  get all container actions
export const getContainerActions = (
  container: ContainerReportData,
  setEmailProps: (emailProps: any) => void,
  setVisible: (visible: boolean) => void,
  sessionData: SessionDataValue
) => {
  const { next_actions } = container; // next action will be array of event names

  const actions: Action[] = [];
  const containerCargoDetailsLength = container.container_cargo_details?.length;
  //  Returns Next Possible actions
  next_actions &&
    container.container_number &&
    actions.push({
      key: 'Workflow',
      type: PRIMARY_TYPE,
      icon: <ApartmentOutlined />,
      displayComponent: 'Workflow',
      isEnable: true,
      childComponents: next_actions.map((action: string) => ({
        key: action,
        type: PRIMARY_TYPE,
        displayComponent: action,
        icon: <GetIconForContainerAction eventName={action} />,
        isEnable: true,
        performAction: performContainerAction,
        description: action,
        extraProps: { event_name: action },
      })),
    });

  actions.push({
    key: 'edit_container',
    type: SECONDARY_TYPE,
    displayComponent: 'Edit Container',
    icon: <CustomIcon icon="Edit" height={15} width={15} />,
    isEnable: true,
    performAction: performEditContainerAction,
    description: 'edit_action',
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'assign_vehicle',
    displayComponent: 'Assign Vehicle',
    icon: <CustomIcon icon="TruckIcon" />,
    isEnable:
      containerCargoDetailsLength === 1 &&
      !!container.container_cargo_details?.[0].shipment &&
      isEnableContainerLoadAction(container.container_cargo_details?.[0].shipment),
    performAction: assignVehicleRender,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'assign_load',
    displayComponent: 'Assign Load',
    icon: <CustomIcon icon="Package" />,
    isEnable:
      containerCargoDetailsLength === 1 &&
      !!container.container_cargo_details?.[0].shipment &&
      isEnableLoadAction(container.container_cargo_details?.[0].shipment),
    performAction: assignLoadRender,
  });

  actions.push({
    key: 'print_container',
    type: SECONDARY_TYPE,
    displayComponent: 'Share',
    isEnable: true,
    icon: <FilePdfOutlined />,
    performAction: performPrintContainer,
    description: 'Share Container',
  });

  actions.push({
    key: 'delete_container',
    type: NEGATIVE_TYPE,
    displayComponent: 'Delete Container',
    icon: <CustomIcon icon="Delete" height={15} width={15} />,
    isEnable: true,
    performAction: performDeleteContainerAction,
    description: 'delete_action',
    // extraProps: { event_name: action },
  });

  return actions;
};

const GetIconForContainerAction = (props: { eventName: string }) => {
  return <></>;
};
