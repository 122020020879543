/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  types,
  Instance,
  SnapshotIn,
  SnapshotOut,
  cast,
  destroy,
  IAnyModelType,
} from 'mobx-state-tree';
import Carrier from 'operations/models/Carrier';
import UserAccount from 'operations/models/UserAccount';
import { CompanyValue } from 'operations/models/Company';
import ContainerRequest, { ContainerRequestValue } from 'operations/models/ContainerRequest';
import OrderCollaboration from 'operations/models/OrderCollaboration';
import { startCase as _startCase, difference as _difference } from 'lodash';
import OceanTransportOrder, {
  OceanTransportOrderValue,
} from 'operations/models/OceanTransportOrder';
import { getCargoPackagesString } from 'operations/models/Cargo';
import RoadTransportOrder, { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import ShipmentContainer, { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import ShipmentParty from 'operations/models/ShipmentParty';
import { DayJsDate as DayJsDateObj, convertToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import EmailActivity, { isEmailSentBefore } from 'operations/models/EmailActivity';
import StuffingBufferServiceOrder, {
  StuffingBufferServiceOrderValue,
} from './StuffingBufferServiceOrder';
import { LOAD_TYPE_FCL, RTO_TYPE_LOOSE, LOAD_TYPE_LCL } from 'operations/baseConstants';
import {
  STATUS_REQUESTED,
  STATUS_CANCELLED,
  STATUS_EXPIRED,
} from 'operations/modules/reports/constants';
import { STATUS_CONFIRMED, DOCUMENT_STATUS_EXECUTED } from 'operations/modules/reports/constants';
import { SHIPMENT_TYPE_HOUSE } from 'operations/modules/shipment/constants';
import Shipment from './Shipment';
import MiscellaneousServiceOrder, {
  MiscellaneousServiceOrderValue,
} from './MiscellaneousServiceOrder';
import InquiryOption from './InquiryOption';
import ProductOrderItem from './ProductOrderItem';

export interface CompanyRoleObject {
  id: string;
  registered_name: string;
  address_id?: string;
  role: string;
}

export interface AmendmentDisableDetail {
  isDisable: boolean;
  disableReason?: string;
  blReleased?: boolean;
  disableFields: string[];
}

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);
const BookingRequest = types
  .model({
    id: types.identifier,
    status: types.maybe(
      types.union(types.literal(STATUS_REQUESTED), types.literal(STATUS_CANCELLED))
    ),
    load_type: types.maybe(types.string),
    shipment_booking_number: types.maybe(types.string),
    stuffing_location_type: types.maybe(types.string),
    high_priority: types.maybe(types.maybeNull(types.boolean)),
    quotation_number: types.maybe(types.maybeNull(types.string)),
    is_external_quotation_number: types.maybe(types.maybeNull(types.boolean)),
    inquiry_option: types.maybe(types.maybeNull(types.late((): IAnyModelType => InquiryOption))),
    incoterm: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybe(types.maybeNull(types.string)),
    cancellation_reason: types.maybe(types.string),
    preferred_carriers: types.maybe(types.array(Carrier)),
    shipment_ids: types.maybe(types.maybeNull(types.array(types.string))),
    shipments: types.maybe(types.maybeNull(types.array(types.late((): IAnyModelType => Shipment)))),
    created_by: types.maybe(UserAccount),
    created_at: types.maybe(types.maybeNull(DayJsDate)),
    updated_at: types.maybe(types.maybeNull(DayJsDate)),
    container_requests: types.array(ContainerRequest),
    cargos: types.array(ProductOrderItem),
    ocean_transport_orders: types.array(types.late((): IAnyModelType => OceanTransportOrder)),
    road_transport_orders: types.array(RoadTransportOrder),
    buy_collaborations: types.array(OrderCollaboration),
    sell_collaborations: types.array(OrderCollaboration),
    stuffing_buffer_service_orders: types.array(StuffingBufferServiceOrder),
    miscellaneous_service_orders: types.array(MiscellaneousServiceOrder),
    email_activities: types.maybe(types.maybeNull(types.array(EmailActivity))),
    booking_request_parties: types.maybe(types.maybeNull(types.array(ShipmentParty))),
  })
  .views((self) => ({
    get isFCL() {
      return self.load_type === LOAD_TYPE_FCL;
    },
    get customerCompany() {
      return self.sell_collaborations[0]?.customer_company;
    },
    get customerAddress() {
      return self.sell_collaborations[0]?.customer_address;
    },
    get salesPersonName() {
      return self.sell_collaborations[0]?.sales_person?.name;
    },
    get salesPerson() {
      return self.sell_collaborations[0]?.sales_person;
    },
    get createdByName() {
      return (
        _startCase(self.created_by?.first_name) + ' ' + _startCase(self.created_by?.last_name || '')
      );
    },
    get bookedByBranch() {
      return self.sell_collaborations[0]?.vendor_address;
    },
    get shipper() {
      return (self.booking_request_parties || []).find((party) => party.name === 'shipper');
    },
    get consignee() {
      return (self.booking_request_parties || []).find((party) => party.name === 'consignee');
    },
    get is_unfulfilled() {
      if (self?.load_type === LOAD_TYPE_LCL) {
        return !self.ocean_transport_orders[0].is_independent;
      }
      return self.container_requests.reduce((unfulfilled, cr) => {
        return unfulfilled || Boolean(cr.quantity_unfulfilled);
      }, false);
    },
    get isFullyUnallocated() {
      return (
        self.ocean_transport_orders.length === 1 && !self.ocean_transport_orders[0].is_independent
      );
    },
    get isOneOnOneAllocated() {
      if (self.ocean_transport_orders.length > 1) return false;
      else if (self.ocean_transport_orders.length === 1) {
        if (!self.ocean_transport_orders[0].is_independent) return false;
        else {
          if (
            self.ocean_transport_orders[0].booking_requests &&
            self.ocean_transport_orders[0].booking_requests.length > 1
          )
            return false;
          else return true;
        }
      }
      return false;
    },
    get carriageWiseRoutingLegs() {
      return self.ocean_transport_orders[0]?.carriageWiseRoutingLegs;
    },
    get isAnyContainerPickedUp() {
      return self.ocean_transport_orders.reduce((containerPickedUp, oto) => {
        return (
          containerPickedUp ||
          oto.shipment_containers.reduce(
            (otoContainerPickedup: boolean, sc: ShipmentContainerValue) => {
              return (
                otoContainerPickedup ||
                (sc.booking_request_id === self.id && Boolean(sc.container_number))
              );
            },
            false
          )
        );
      }, false);
    },
    get areAllContainersPickedUp() {
      if (
        self.ocean_transport_orders.length === 1 &&
        !self.ocean_transport_orders[0].is_independent
      )
        return false;
      return self.ocean_transport_orders.reduce((containersPickedUp, oto) => {
        return (
          containersPickedUp &&
          oto.shipment_containers.reduce(
            (otoContainerPickedup: boolean, sc: ShipmentContainerValue) => {
              return sc.booking_request_id === self.id
                ? otoContainerPickedup && Boolean(sc.container_number)
                : otoContainerPickedup;
            },
            containersPickedUp
          )
        );
      }, true);
    },
    get isAnyContainerNotAllocatedWithRto() {
      return self.ocean_transport_orders.reduce((containerNotAllocatedWithRto, oto) => {
        return (
          containerNotAllocatedWithRto ||
          oto.shipment_containers.reduce(
            (otoContainerNotAllocated: boolean, sc: ShipmentContainerValue) => {
              return (
                otoContainerNotAllocated ||
                (sc.booking_request_id === self.id &&
                  (!sc.road_transport_order_ids || sc.road_transport_order_ids.length === 0))
              );
            },
            containerNotAllocatedWithRto
          )
        );
      }, false);
    },
    get fcl_road_transport_orders() {
      return self.road_transport_orders.filter((rto) => rto.load_type === LOAD_TYPE_FCL);
    },
    get loose_road_transport_orders() {
      return self.road_transport_orders.filter((rto) => rto.load_type !== LOAD_TYPE_FCL);
    },
    get linkedCargoIds() {
      let linked_cargos: Array<string> = [];
      linked_cargos = self.road_transport_orders.reduce((acc, rto) => {
        const rto_cargo_ids = rto.cargos.map((c) => c.id || '');
        acc.push(...rto_cargo_ids);
        return acc;
      }, linked_cargos);
      return linked_cargos;
    },
    get isAnyCargoNotAllocatedWithRto() {
      const unlinked_cargo_ids = _difference(
        self.cargos.map((c) => c.id),
        this.linkedCargoIds
      );
      return unlinked_cargo_ids.length > 0;
    },
    get isLclRTOPresent() {
      return (self.road_transport_orders || []).some((rto) => rto.load_type === RTO_TYPE_LOOSE);
    },
    get isConfirmed() {
      const is_unfulfilled = self.container_requests.reduce((unfulfilled, cr) => {
        return unfulfilled || Boolean(cr.quantity_unfulfilled);
      }, false);
      if (is_unfulfilled) return false;
      return (self.ocean_transport_orders || []).reduce(
        (isConfirmed: boolean, oto: OceanTransportOrderValue) =>
          isConfirmed && oto.status === STATUS_CONFIRMED,
        true
      );
    },
    get totalGrossWeight() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_weight || 0;
        return sum;
      }, 0);
    },
    get totalPackagesString() {
      return getCargoPackagesString(self.cargos);
    },
    get isHazardous() {
      return self.cargos.some((cargo: any) => {
        return cargo?.cargo_properties?.is_hazardous;
      });
    },
    get shipmentContainers() {
      return self.ocean_transport_orders.flatMap((oto: OceanTransportOrderValue) =>
        (oto.shipment_containers || [])
          .filter((elem: ShipmentContainerValue) => elem.booking_request_id === self.id)
          .map((container) => ({
            ...container,
            booking_number: oto.booking_number,
          }))
      );
    },
    amendmentDisableDetails(section: string): AmendmentDisableDetail {
      let isAmendmentDisable = false;
      const amendmentDisableReason: any[] = [];
      let disableFields: string[] = [];

      if (self.status === STATUS_CANCELLED) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Customer order is cancelled');
      }
      const isLinkedWithShipments = self.shipments && self.shipments.length > 0;

      if (section === 'cargos' && self.load_type === LOAD_TYPE_FCL && !this.isFullyUnallocated) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Vendor booking  is linked with customer order');
      }
      if (
        ['overview', 'road_transport_orders', 'stuffing_buffer_service', 'cargos'].includes(
          section
        ) &&
        isLinkedWithShipments
      ) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Job created');
      }
      if (section === 'ocean_transport_orders') {
        if (self.load_type === LOAD_TYPE_FCL && this.areAllContainersPickedUp) {
          isAmendmentDisable = true;
          amendmentDisableReason.push('All containers are picked up');
        } else if (self.load_type === LOAD_TYPE_LCL) {
          isAmendmentDisable = true;
        }
      }
      if (section === 'shipment_containers' && !this.isAnyContainerPickedUp) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('No container is picked up');
      }

      const blReleased = (self.shipments || []).some((shipment) =>
        (shipment.shipment_documents || []).some(
          (sd: any) => sd.document_status === DOCUMENT_STATUS_EXECUTED
        )
      );

      if (['routing_details', 'container_requests'].includes(section)) {
        const isOTOExpired = self.ocean_transport_orders.some(
          (oto) => oto.status === STATUS_EXPIRED
        );
        if (isOTOExpired) {
          isAmendmentDisable = true;
          amendmentDisableReason.push('Vendor booking is expired');
        }

        const manyToManyAllocated = self.ocean_transport_orders.some(
          (oto) => oto.booking_requests.length > 1
        );

        if (section === 'routing_details') {
          if (manyToManyAllocated) {
            isAmendmentDisable = true;
            amendmentDisableReason.push(
              'Allocated Vendor bookings are linked with other customer orders'
            );
          } else if (self.ocean_transport_orders.length > 1) {
            isAmendmentDisable = true;
            amendmentDisableReason.push(
              'More than one vendor bookings are linked with customer order'
            );
          }
        }
        const independentHouseFound = (self.shipments || []).some(
          (shipment) =>
            shipment.shipment_type === SHIPMENT_TYPE_HOUSE && !Boolean(shipment.master_shipment_id)
        );
        if (independentHouseFound) {
          isAmendmentDisable = true;
          amendmentDisableReason.push('Independent house is linked with booking');
        }
        if (section === 'container_requests') {
          if (blReleased) {
            isAmendmentDisable = true;
            amendmentDisableReason.push('BL released for linked shipment');
          } else if (manyToManyAllocated && isLinkedWithShipments) {
            isAmendmentDisable = true;
            amendmentDisableReason.push(
              'Allocated Vendor bookings are linked with other customer orders & Job created'
            );
          } else if (manyToManyAllocated) {
            disableFields = [
              'container_type_code',
              'weight_per_container',
              'weight_unit',
              'is_shipper_owned',
              'container_settings',
            ];
          } else if (isLinkedWithShipments)
            disableFields = [
              'container_type_code',
              'quantity',
              'weight_per_container',
              'weight_unit',
              'is_shipper_owned',
            ];
        }
      }

      return {
        isDisable: isAmendmentDisable,
        disableReason: amendmentDisableReason.join(', '),
        blReleased,
        disableFields,
      };
    },
    willFullyAllocatedAfterAllocation(new_allocated_qty: number): boolean {
      const cr_quantities = self.container_requests.reduce(
        (
          cr_quantities: { total_requested_qty: number; total_allocated_qty: number },
          cr: ContainerRequestValue
        ) => {
          cr_quantities['total_requested_qty'] += cr?.quantity || 0;
          cr_quantities['total_allocated_qty'] += cr?.quantity_fulfilled || 0;
          return cr_quantities;
        },
        { total_requested_qty: 0, total_allocated_qty: 0 }
      );
      return (
        cr_quantities.total_requested_qty === cr_quantities.total_allocated_qty + new_allocated_qty
      );
    },
    isEmailSentBefore(action_name: string): boolean {
      return isEmailSentBefore(self.email_activities || [], action_name);
    },

    getAllCompaniesRolesMapping(
      default_company: CompanyValue,
      action_name?: string,
      resource_ids?: Array<string>
    ): CompanyRoleObject[] {
      const sell_collab = self.sell_collaborations[0];
      const companies_roles_mapping: CompanyRoleObject[] = [];
      companies_roles_mapping.push({
        id: default_company.id,
        registered_name: default_company.registered_name,
        address_id: sell_collab?.vendor_address?.id,
        role: 'Your Company',
      });
      if (sell_collab && sell_collab.customer_company) {
        companies_roles_mapping.push({
          id: sell_collab.customer_company.id,
          registered_name: sell_collab.customer_company?.registered_name,
          address_id: sell_collab.customer_address?.id,
          role: 'Customer',
        });
      }
      if (self.booking_request_parties && self.booking_request_parties?.length > 0) {
        const shipper = (self.booking_request_parties || []).find(
          (party) => party.name === 'shipper'
        );
        if (shipper?.party_company)
          companies_roles_mapping.push({
            id: shipper?.party_company?.id,
            registered_name: shipper?.party_company?.registered_name,
            address_id: shipper?.party_address?.id,
            role: 'Shipper',
          });
      }

      if (self.road_transport_orders?.length > 0) {
        const rtos = self.road_transport_orders;
        const rto_buy_collabs = rtos
          .filter((rto) => {
            const isMatchingTransporter =
              action_name === 'pickup_reminder' && rto?.id
                ? (resource_ids || []).includes(rto?.id)
                : true;
            return isMatchingTransporter;
          })
          .map((rto) => {
            return (rto.order_collaborations || []).filter(
              (oc) => oc?.customer_company?.id === default_company.id
            );
          })
          .flat();
        if (rto_buy_collabs && rto_buy_collabs.length > 0) {
          rto_buy_collabs.forEach((rto_buy_collab) => {
            if (rto_buy_collab && rto_buy_collab.vendor_company)
              companies_roles_mapping.push({
                id: rto_buy_collab.vendor_company.id,
                registered_name: rto_buy_collab.vendor_company?.registered_name,
                address_id: rto_buy_collab.vendor_address?.id,
                role: 'Transporter',
              });
          });
        }
      }
      return companies_roles_mapping;
    },
  }))
  .actions((self) => ({
    setContainerRequests(newContainerRequests?: Instance<typeof ContainerRequest>[]) {
      self.container_requests = cast(newContainerRequests);
    },
    setCargos(newCargos: Instance<typeof ProductOrderItem>[]) {
      self.cargos = cast(newCargos);
    },
    updateShipmentContainers(shipment_containers: Array<ShipmentContainerValue>) {
      self.ocean_transport_orders.forEach((oto) => {
        const containerIds =
          oto.shipment_containers.map(
            (container: Instance<typeof ShipmentContainer>) => container.id
          ) || [];
        oto.updateShipmentContainers(
          cast(shipment_containers.filter((container) => containerIds.includes(container.id)))
        );
      });

      self.road_transport_orders.forEach((rto) => {
        const containerIds =
          rto.shipment_containers.map(
            (container: Instance<typeof ShipmentContainer>) => container.id
          ) || [];
        rto.updateShipmentContainers(
          shipment_containers.filter((container) => containerIds.includes(container.id))
        );
      });
    },
    setRoadTransportOrders(newRoadTransportOrders: Instance<typeof RoadTransportOrder>[]) {
      self.road_transport_orders = cast(newRoadTransportOrders);
    },
    addRoadTransportOrder(roadTransportOrder: RoadTransportOrderValue) {
      self.road_transport_orders.push(roadTransportOrder);
      if (roadTransportOrder.shipment_containers.length > 0)
        self.ocean_transport_orders.forEach((oto) =>
          oto.updateShipmentContainers(roadTransportOrder.shipment_containers)
        );
    },
    updateRoadTransportOrder(roadTransportOrder: RoadTransportOrderValue) {
      const rto_index = self.road_transport_orders.findIndex((r) => r.id === roadTransportOrder.id);
      if (rto_index !== -1) {
        self.road_transport_orders[rto_index] = roadTransportOrder;
        if (roadTransportOrder.shipment_containers.length > 0)
          self.ocean_transport_orders.forEach((oto) =>
            oto.updateShipmentContainers(roadTransportOrder.shipment_containers)
          );
      }
    },
    deleteRoadTransportOrder(roadTransportOrderId: string) {
      const rto = self.road_transport_orders.find((r) => r.id === roadTransportOrderId);
      if (rto) destroy(rto);
    },
    setOceanTransportOrders(newOceanTransportOrders: Array<OceanTransportOrderValue>) {
      self.ocean_transport_orders = cast(newOceanTransportOrders);
    },
    addStuffingBufferServiceOrder(stuffingOrder: StuffingBufferServiceOrderValue) {
      self.stuffing_buffer_service_orders.push(stuffingOrder);
    },
    addMiscellaneousServiceOrder(miscellaneous_service_order: MiscellaneousServiceOrderValue) {
      self.miscellaneous_service_orders.push(miscellaneous_service_order);
    },
    replaceMiscellaneousServiceOrder(miscellaneous_service_order: MiscellaneousServiceOrderValue) {
      const index = self.miscellaneous_service_orders.findIndex(
        (r) => r.id === miscellaneous_service_order.id
      );
      if (index !== -1) {
        self.miscellaneous_service_orders[index] = miscellaneous_service_order;
      }
    },
    deleteMiscellaneousServiceOrder(miscellaneous_service_order: MiscellaneousServiceOrderValue) {
      const mso = self.miscellaneous_service_orders.find(
        (mso) => mso.id === miscellaneous_service_order.id
      );
      if (mso) destroy(mso);
    },
    setRemarks(remarks?: string | null) {
      self.remarks = remarks;
    },
    setStatus(status?: 'requested' | 'cancelled') {
      self.status = status;
    },
    setBasicDetails(bookingRequest: BookingRequestValue) {
      self.sell_collaborations = bookingRequest.sell_collaborations;
      self.high_priority = bookingRequest.high_priority;
      self.quotation_number = bookingRequest.quotation_number;
      self.incoterm = bookingRequest.incoterm;
      self.booking_request_parties = bookingRequest.booking_request_parties;
    },
    setShipmentIds(shipment_ids?: Array<string> | null) {
      self.shipment_ids = cast([...(shipment_ids || [])]);
    },
  }))
  .preProcessSnapshot(({ created_at, updated_at, ...snapshot }) => ({
    ...snapshot,
    created_at: convertToDayJs(created_at),
    updated_at: convertToDayJs(updated_at),
  }));

export interface BookingRequestValue extends Instance<typeof BookingRequest> {}
export interface BookingRequestIn extends SnapshotIn<typeof BookingRequest> {}
export interface BookingRequestOut extends SnapshotOut<typeof BookingRequest> {}

export default BookingRequest;
