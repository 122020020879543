import { Drawer, DrawerFooter, FormInstance } from '@shipmnts/pixel-hub';

import React, { MutableRefObject, useRef } from 'react';
import { useLocation } from 'wouter';
import { GridOptions } from '@ag-grid-community/core';
import { WarehouseTransactionValue } from 'operations/models/WarehouseTransaction';
import { WarehouseTransactionProductValue } from 'operations/models/WarehouseTransactionProduct';
import { omit } from 'lodash';
import StockSummaryTable from './StockSummaryTable';
import {
  calculateChargeableWeight,
  calculateOuterQty,
  calculatePackagingDesc,
  calculateTotalQty,
  calculateVolumetricWeight,
} from '../helpers';
interface FetchStockSummaryDrawerProps {
  transaction?: WarehouseTransactionValue;
  nextTransaction?: string;
  purposeOfTransfer?: string;
  setAllocatedProducts?: (value: WarehouseTransactionProductValue[]) => void;
  isVisible?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  setIsVisible?: (value: boolean) => void;
  gridRef: MutableRefObject<GridOptions | undefined>;
  customer?: any;
  branch: any;
  form?: FormInstance;
}

const FetchStockSummaryDrawer = (props: any) => {
  const {
    transaction,
    purposeOfTransfer,
    nextTransaction,
    onClose,
    setAllocatedProducts,
    isVisible,
    setIsVisible,
    gridRef,
    customer,
    branch,
    form,
  } = props;
  const { 1: navigate } = useLocation();

  const closeDrawer = () => {
    onClose && onClose();
    setIsVisible && setIsVisible(false);
  };
  const onSaveDrawer = () => {
    const selectedProducts = gridRef?.current?.api?.getSelectedRows() || [];
    const updatedData: any = selectedProducts.map((obj: any) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key.startsWith('wms_stock_summary_') ? key.replace('wms_stock_summary_', '') : key,
          value,
        ])
      )
    );
    const preComputationProduct = (product: WarehouseTransactionProductValue) => {
      const total_qty = calculateTotalQty({ qty: product?.qty });
      const volumetric_weight = calculateVolumetricWeight({ gross_volume: product?.gross_volume });
      const chargeable_weight = calculateChargeableWeight({
        weight_unit: 'kgs',
        gross_weight: product?.gross_weight,
        volumetric_weight: product?.gross_weight,
      });
      const outer_qty = calculateOuterQty({
        total_qty,
        inner_by_outer_qty: product?.inner_by_outer_qty,
      });
      const packing_desc = calculatePackagingDesc({
        packing_type: product?.packing_type,
        outer_qty,
        outer_packing_type: product?.outer_packing_type,
      });
      const per_pack_volume = (product?.gross_volume || 0) / total_qty || 0;
      const per_pack_weight = (product?.gross_weight || 0) / total_qty || 0;
      return {
        total_qty,
        volumetric_weight,
        chargeable_weight,
        outer_qty,
        packing_desc,
        per_pack_volume,
        per_pack_weight,
        dimension_unit: 'meter',
        weight_unit: 'kgs',
      };
    };
    const old_products = form?.getFieldValue('warehouse_transaction_products') || [];

    const products: any[] = updatedData
      .map((product: any) => {
        return {
          ...omit(product, [
            'childCount',
            'key_for_group',
            'pretty_display',
            'warehouse_unit_id',
            'product_id',
            'id',
          ]),
          compare_id: product?.id,
          product_name: product?.product_id?.record_details,
          product_id: product?.product_id?.id,
          from_location: {
            id: product?.warehouse_unit_id?.id,
            node_name: product?.warehouse_unit_id?.record_details,
          },
          ...preComputationProduct(product),
        };
      })
      .filter(
        (product: any) =>
          !old_products.some((old_product: any) => old_product.compare_id === product.compare_id)
      );

    if (!!setAllocatedProducts) setAllocatedProducts([...old_products, ...products]);
    else {
      const value = {
        ...transaction,
        purpose_of_transfer: purposeOfTransfer,
        warehouse_transaction_products: [...old_products, ...products],
      };
      navigate(`~/form/${nextTransaction}/new`, {
        state: {
          transaction: JSON.stringify(value),
        },
      });
    }
    closeDrawer();
  };

  return (
    <Drawer
      title={'Stock Ledger'}
      width={'60%'}
      onClose={closeDrawer}
      open={isVisible}
      footer={<DrawerFooter saveText="Add Products" onSave={onSaveDrawer} onClose={closeDrawer} />}
    >
      <StockSummaryTable
        rowSelection={'multiple'}
        gridRef={gridRef}
        transaction={transaction}
        purposeOfTransfer={purposeOfTransfer}
        customer={customer}
        branch={branch}
      />
    </Drawer>
  );
};

export default FetchStockSummaryDrawer;

export const FetchStockSummaryDrawerWrapper = (props: FetchStockSummaryDrawerProps) => {
  const gridRef = useRef<GridOptions>();
  return (
    <FetchStockSummaryDrawer
      {...props}
      branch={props?.transaction?.branch?.id}
      gridRef={gridRef}
    ></FetchStockSummaryDrawer>
  );
};
