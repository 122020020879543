import React from 'react';

import { Button, Tag } from '@shipmnts/pixel-hub';
import { MailOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation } from 'wouter';
import { capitalize as _capitalize } from 'lodash';

import { DetailsCard, useSession } from 'common';
import { useCompanyView } from '../CompanyView';
import { WithPermission, ConditionalPermissions } from '@shipmnts/pixel-hub';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';

const AddAddress = () => {
  const { 1: navigate } = useLocation();
  const { company } = useCompanyView();
  const session = useSession();
  return (
    <Button
      size="small"
      icon={<PlusOutlined />}
      disabled={
        company?.is_disabled ||
        (company?.company_group === 'Self' && session?.user_level !== 'admin')
      }
      onClick={() => {
        navigate('~/form/address/new', {
          state: {
            company,
          },
        });
      }}
    >
      {'Add Address'}
    </Button>
  );
};

export const AddressCard = () => {
  const { company, loading, accounts_data, accounts_loading, is_customer, is_vendor } =
    useCompanyView();
  const data = company?.addresses;
  const { 1: navigate } = useLocation();
  const session = useSession();

  let permission: ConditionalPermissions | undefined = undefined;
  if (is_customer || is_vendor)
    permission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };

  const headingStyle = {
    display: 'block',
    opacity: 0.6,
    fontSize: '12px',
    fontWeight: '600',
    color: '#000000D9',
  };

  return (
    <DetailsCard
      style={{ width: '100%', marginInline: 'auto' }}
      loading={loading || accounts_loading}
      header={{ title: 'Addresses', icon: <MailOutlined /> }}
      Action={
        <WithPermission permission={permission}>
          <AddAddress />
        </WithPermission>
      }
    >
      {data && data.length > 0 ? (
        <>
          {data?.map((address, idx) => {
            return (
              <div
                key={address?.id}
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  alignItems: 'start',
                  gridTemplateColumns: '20% 40% 20%',
                  borderBottom: idx !== data.length - 1 ? '1px solid rgba(0, 0, 0, .1)' : '',
                  padding: '1em',
                  gap: '1em',
                }}
              >
                <span style={{ color: '#0A4FCA', fontWeight: '500', wordWrap: 'break-word' }}>
                  <span style={headingStyle}>Address Title</span>
                  {_capitalize(address.name || address.city_name || '-')}
                </span>
                <section style={{ wordWrap: 'break-word' }}>
                  <span style={headingStyle}>Print Address</span>
                  <span>{address?.print_address || '-'}</span>
                </section>
                <section style={{ wordWrap: 'break-word', alignSelf: 'start' }}>
                  <span style={headingStyle}>Address Type</span>
                  <span>
                    {address.entity_type ? (
                      <Tag
                        style={{ width: 'fit-content' }}
                        color={address.entity_type === 'billing' ? 'purple' : 'blue'}
                      >
                        {_capitalize(address.entity_type)}
                      </Tag>
                    ) : (
                      '-'
                    )}
                  </span>
                </section>
                {(company?.company_group !== 'Self' || session?.user_level === 'admin') && (
                  <WithPermission permission={permission}>
                    <EditOutlined
                      style={{
                        color: '#0A4FCA',
                        fontWeight: '500',
                        fontSize: '18px',
                        textAlign: 'right',
                        alignSelf: 'center',
                        justifySelf: 'end',
                      }}
                      onClick={() => {
                        navigate(`~/form/address/${address.id}`, {
                          state: {
                            address,
                            addressAccountsData: accounts_data?.addresses?.find(
                              (ad) => ad.operations_address_id === address.id
                            ),
                          },
                        });
                      }}
                    />
                  </WithPermission>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div
          style={{ textAlign: 'center', padding: '1em', fontSize: '16px', opacity: '0.4' }}
        >{`No Addresses Added Yet.`}</div>
      )}
    </DetailsCard>
  );
};
