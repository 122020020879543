import React from 'react';
import { PrintPreviewDocType } from '@shipmnts/pixel-hub';
import { ProductOrderValue } from 'operations/models/ProductOrder';

interface PrintTransactionProp {
  docTypeId: string;
  docType: string;
  doc: ProductOrderValue;
  onClose?: () => void;
}

export default function PrintTransaction(props: PrintTransactionProp) {
  const { docType, docTypeId, doc, onClose } = props;

  if (!doc) return <></>;

  return <PrintPreviewDocType docType={docType} resource_id={docTypeId} onClose={onClose} />;
}
