import React from 'react';

import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';

import { useCompanyView } from '../CompanyView';

import { CustomerLedgerCard } from '../Cards/CustomerLedgerCard';
import { SupplierLedgerCard } from '../Cards/SupplierLedgerCard';
import { CreditControlCard } from '../Cards/CreditControlCard';
import { BankAccountsCard } from '../Cards/BankAccountsCard';
import { ExemptionCard } from '../Cards/ExemptionCard';
import { Col, Row } from '@shipmnts/pixel-hub';

export const FinanceTab = () => {
  const { loading, accounts_loading, is_customer, is_vendor } = useCompanyView();
  const { erpnextConfigData, fetching } = useErpNextConfig();
  // const { erpnextConfigData, fetchConfigData, fetching, fetchError } = useErpNextConfig();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          width: '95%',
          marginInline: 'auto',
          marginTop: '1em',
        }}
      >
        {erpnextConfigData && (
          <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
            <CustomerLedgerCard
              erpnextConfigData={erpnextConfigData}
              loading={loading || fetching || accounts_loading}
            />
          </Col>
        )}
        {is_customer && erpnextConfigData && (
          <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
            <CreditControlCard loading={loading || fetching || accounts_loading} />
          </Col>
        )}
        {erpnextConfigData && (
          <Col span={8} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
            <SupplierLedgerCard
              erpnextConfigData={erpnextConfigData}
              loading={loading || fetching || accounts_loading}
            />
          </Col>
        )}
      </Row>
      <div
        style={{
          width: '95%',
          marginInline: 'auto',
          marginBlock: '1em 2em',
        }}
      >
        {is_customer && (
          <ExemptionCard type="receivable" loading={loading || fetching || accounts_loading} />
        )}
        {is_vendor && (
          <ExemptionCard type="payable" loading={loading || fetching || accounts_loading} />
        )}
        {erpnextConfigData && (
          <BankAccountsCard loading={loading || fetching || accounts_loading} />
        )}
      </div>
    </>
  );
};
