import { IAnyModelType, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import ShipmentParty from './ShipmentParty';
import BranchAccount from './BranchAccount';
import Carrier from './Carrier';
import Location from './Location';
import WarehouseTransactionProduct from './WarehouseTransactionProduct';
import UserAccount from './UserAccount';
import Company from 'network/models/Company';
import WarehouseUnit from './WarehouseUnit';
import { CargoProperty } from './Cargo';

export const TRANSACTION_TYPE_INCOMING = 'incoming_shipment';
export const TRANSACTION_TYPE_RECEIPT = 'receipt';
export const TRANSACTION_TYPE_OUTGOING = 'outgoing_shipment';
export const TRANSACTION_TYPE_DELIVERY = 'delivery';
export const TRANSACTION_TYPE_GTO = 'goods_transfer';

export const CANCELLED = 'cancelled';
export const STATUS_DRAFT = 'draft';
export const STATUS_SUBMITTED = 'submitted';

const WarehouseTransaction = types.model({
  id: types.identifier,
  type: types.union(
    types.literal(TRANSACTION_TYPE_INCOMING),
    types.literal(TRANSACTION_TYPE_RECEIPT),
    types.literal(TRANSACTION_TYPE_OUTGOING),
    types.literal(TRANSACTION_TYPE_DELIVERY),
    types.literal(TRANSACTION_TYPE_GTO)
  ),
  status: types.maybe(types.string),
  services: types.maybe(types.maybeNull(types.string)),
  transaction_number: types.maybe(types.string),
  shipment_parties: types.maybe(types.maybeNull(types.array(ShipmentParty))),
  branch: types.maybe(types.maybeNull(types.late((): IAnyModelType => BranchAccount))),
  target_warehouse: types.maybe(types.maybeNull(types.late((): IAnyModelType => WarehouseUnit))),
  transaction_date: types.maybe(types.maybeNull(types.number)),
  customer_ref_date: types.maybe(types.maybeNull(types.number)),
  target_date: types.maybe(types.maybeNull(types.number)),
  carrier: types.maybe(types.maybeNull(Carrier)),
  received_transporter: types.maybe(types.maybeNull(Company)),
  delivered_transporter: types.maybe(types.maybeNull(Company)),
  origin: types.maybe(types.maybeNull(Location)),
  destination: types.maybe(types.maybeNull(Location)),
  origin_etd_date: types.maybe(types.maybeNull(types.number)),
  destination_eta_date: types.maybe(types.maybeNull(types.number)),
  created_at: types.maybe(types.maybeNull(types.number)),
  created_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
  transaction_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
  warehouse_transaction_products: types.maybe(
    types.array(types.late((): IAnyModelType => WarehouseTransactionProduct))
  ),
  customs_ref_code: types.maybe(types.maybeNull(types.string)),
  customer_ref_code: types.maybe(types.maybeNull(types.string)),
  delivery_ref_code: types.maybe(types.maybeNull(types.string)),
  driver_name: types.maybe(types.maybeNull(types.string)),
  freight_type: types.maybe(types.maybeNull(types.string)),
  shipment_document_number: types.maybe(types.maybeNull(types.string)),
  vehicle_number: types.maybe(types.maybeNull(types.string)),
  linked_shipment: types.maybe(types.maybeNull(types.string)),
  total_gross_wt: types.maybe(types.maybeNull(types.number)),
  total_gross_volume: types.maybe(types.maybeNull(types.number)),
  total_chargeable_wt: types.maybe(types.maybeNull(types.number)),
  total_packages: types.maybe(types.maybeNull(types.number)),
  total_volume_wt: types.maybe(types.maybeNull(types.number)),
  custom_clearance_date: types.maybe(types.maybeNull(types.number)),
  product_property: types.maybe(types.maybeNull(CargoProperty)),
  purpose_of_transfer: types.maybe(types.maybeNull(types.string)),
});

export type WarehouseTransactionValue = Instance<typeof WarehouseTransaction>;
export type WarehouseTransactionIn = SnapshotIn<typeof WarehouseTransaction>;
export type WarehouseTransactionOut = SnapshotOut<typeof WarehouseTransaction>;

export default WarehouseTransaction;
