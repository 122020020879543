import React from 'react';
import { ExclamationCircleOutlined, Form, Input, Modal, Row } from '@shipmnts/pixel-hub';

interface ConfirmCancelShipmentModalProps {
  showConfirmationModal?: any;
  setShowConfirmationModal?: any;
  form?: any;
}

const ConfirmCancelShipmentModal = React.memo(function ConfirmCancelShipmentModal(
  props: ConfirmCancelShipmentModalProps
): JSX.Element {
  const { setShowConfirmationModal, form } = props;
  const onCancel = () => {
    setShowConfirmationModal(false);
  };
  const onConfirm = () => {
    form.submit();
    setShowConfirmationModal(false);
  };
  return (
    <Modal
      title={
        <Row>
          <ExclamationCircleOutlined
            style={{ color: '#ff4d4f', fontSize: '20px', marginRight: '8px' }}
          />
          <div style={{ fontSize: '15px' }}>Confirm Shipment Cancellation</div>
        </Row>
      }
      open
      okText={`Confirm`}
      onOk={onConfirm}
      onCancel={onCancel}
      width={500}
      destroyOnClose
    >
      <div>
        <p>{'Are you sure you want to delete Shipment/ Customer Order?'}</p>
        <p>{"Type 'CANCEL SHIPMENT' to proceed."}</p>
        <Form.Item
          name="confirmation"
          required
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value && value.trim().toUpperCase() !== 'CANCEL SHIPMENT') {
                  return Promise.reject('The confirmation value must be "CANCEL SHIPMENT".');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input type={'text'} autoComplete="off" />
        </Form.Item>
      </div>
    </Modal>
  );
});

export default ConfirmCancelShipmentModal;
