// Possible Contract types
export const AIR_CONTRACT_TYPE = 'RateManagement::AirContract';
export const OCEAN_CONTRACT_TYPE = 'RateManagement::OceanContract';
export const ROAD_CONTRACT_TYPE = 'RateManagement::RoadContract';
export const OCEAN_LOCALS_CONTRACT_TYPE = 'RateManagement::OceanLocalsContract';
export const DUE_AGENT_CONTRACT_TYPE = 'RateManagement::AirDueAgentContract';
export const CONTRACT_DOCTYPES = [AIR_CONTRACT_TYPE, OCEAN_CONTRACT_TYPE, ROAD_CONTRACT_TYPE];

// Freight types
export const ROAD_FREIGHT = 'road';
export const AIR_FREIGHT = 'air';
export const OCEAN_FREIGHT = 'ocean';
export const OCEAN_LOCALS = 'ocean_locals';
export const DUE_AGENT = 'due_agent';
export const CONTRACT_TYPES = [ROAD_FREIGHT, AIR_FREIGHT, OCEAN_FREIGHT, OCEAN_LOCALS, DUE_AGENT];

// Contract type map
export const CONTRACT_DOCTYPE_MAP: { [key: string]: string } = {
  [AIR_FREIGHT]: AIR_CONTRACT_TYPE,
  [OCEAN_FREIGHT]: OCEAN_CONTRACT_TYPE,
  [ROAD_FREIGHT]: ROAD_CONTRACT_TYPE,
};

// Possible Load types of Air Contract
export const AIR_LOOSE_LOAD = 'loose';
export const AIR_ULD_LOAD = 'uld';
export const AIR_LOAD_TYPES = [AIR_LOOSE_LOAD, AIR_ULD_LOAD];

// Possible Load types of Ocean Contract
export const OCEAN_FCL_LOAD = 'fcl';
export const OCEAN_LCL_LOAD = 'lcl';
export const OCEAN_LOAD_TYPES = [OCEAN_FCL_LOAD, OCEAN_LCL_LOAD];

// Possivle Load types of Road Contract
export const ROAD_FCL_LOAD = 'fcl';
export const ROAD_LTL_LOAD = 'ltl';
export const ROAD_FTL_BREAK_BULK_LOAD = 'breakbulk';
export const ROAD_FTL_BULK_LOAD = 'bulk';
export const ROAD_FTL_LIQUID_LOAD = 'liquid';
export const ROAD_LOAD_TYPES = [
  ROAD_FCL_LOAD,
  ROAD_LTL_LOAD,
  ROAD_FTL_BULK_LOAD,
  ROAD_FTL_LIQUID_LOAD,
  ROAD_FTL_BREAK_BULK_LOAD,
];

// All Possible Load Types
export const LOAD_TYPES = [...AIR_LOAD_TYPES, ...OCEAN_LOAD_TYPES, ...ROAD_LOAD_TYPES];

// All Possible values for Contract Status
export const DRAFT_CONTRACT_STATUS = 'draft';
export const CANCEL_CONTRACT_STATUS = 'cancelled';
export const SUBMIT_CONTRACT_STATUS = 'submitted';
export const AMEND_CONTRACT_STATUS = 'amended';
export const EXPIRE_CONTRACT_STATUS = 'expired';
export const CONTRACT_STATUSES = [
  DRAFT_CONTRACT_STATUS,
  CANCEL_CONTRACT_STATUS,
  SUBMIT_CONTRACT_STATUS,
  EXPIRE_CONTRACT_STATUS,
  AMEND_CONTRACT_STATUS,
];
export const STATUS_CLASS_MAP = {
  draft: 'status_color_alert',
  submitted: 'status_color_green',
  cancelled: 'status_color_red',
  expired: 'black',
};

// Air Contract Types
export const AIR_CONTRACT_TYPE_TARIFF = 'tariff';
export const AIR_CONTRACT_TYPE_NET_NET = 'net_net';

export const CONTRACT_TYPE = [
  { key: AIR_CONTRACT_TYPE_TARIFF, name: 'Market (Tariff / Discounted)' },
  { key: AIR_CONTRACT_TYPE_NET_NET, name: 'Net Net' },
];
// Discount Types
export const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
export const DISCOUNT_TYPE_AMOUNT = 'amount';
export const DISCOUNT_TYPES = [DISCOUNT_TYPE_PERCENTAGE, DISCOUNT_TYPE_AMOUNT];

// Possible Weight Units
export const WEIGHT_UNIT_KGS = 'kgs';
export const WEIGHT_UNIT_MTS = 'mts';
export const WEIGHT_UNITS = [WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS];

// Possible Charge Basis
export const NET_WEIGHT_BASIS = 'net_weight';
export const GROSS_WEIGHT_BASIS = 'gross_weight';
export const CHARGEABLE_WEIGHT_BASIS = 'chargeable_weight';
export const CONTAINER_BASIS = 'container';
export const TRIP_BASIS = 'trip';
export const CHARGE_BASIS = [
  NET_WEIGHT_BASIS,
  GROSS_WEIGHT_BASIS,
  CHARGEABLE_WEIGHT_BASIS,
  CONTAINER_BASIS,
  TRIP_BASIS,
];

//Rate TYPES
export const RATE_TYPE_BUY = 'buy';
export const RATE_TYPE_SELL = 'sell';
export const RATE_TYPE_NAMED_ACCOUNT = 'named_account';

// Misc Constants
export const TERMS_AND_COND_TEMPLATE_TYPE = 'terms_and_condition';

export const LOCATION_TYPE_ICD = 'ICD';
export const LOCATION_TYPE_CITY = 'City';

// Possible Charge Terms
export const CHARGE_AMOUNT_PREPAID = 'prepaid';
export const CHARGE_AMOUNT_COLLECT = 'collect';
export const CHARGE_TERMS = [
  { key: CHARGE_AMOUNT_PREPAID, name: 'PP-prepaid' },
  { key: CHARGE_AMOUNT_COLLECT, name: 'CC-collect' },
];

// Air Surcharge Basis
export const SURCHARGE_BASIS_GROSS_WEIGHT = 'gross_weight';
export const SURCHARGE_BASIS_CHARGEABLE_WEIGHT = 'chargeable_weight';
export const SURCHARGE_BASIS_SHIPMENT = 'shipment';

export const SURCHARGE_BASIS = [
  { key: SURCHARGE_BASIS_GROSS_WEIGHT, name: 'Gross Weight' },
  { key: SURCHARGE_BASIS_CHARGEABLE_WEIGHT, name: 'Chargeable Weight' },
  { key: SURCHARGE_BASIS_SHIPMENT, name: 'Shipment' },
];

// Air Weight Slabs
export const DEFAULT_AIR_WEIGHT_SLABS = [
  { start_weight: 45, end_weight: 99 },
  { start_weight: 100, end_weight: 299 },
  { start_weight: 300, end_weight: 499 },
  { start_weight: 500, end_weight: null },
];

export const DEFAULT_AIR_WEIGHT_SLABS_OPTIONS = [
  { value: 45, label: '45' },
  { value: 100, label: '100' },
  { value: 250, label: '250' },
  { value: 300, label: '300' },
  { value: 500, label: '500' },
  { value: 1000, label: '1000' },
  { value: 2000, label: '2000' },
  { value: 5000, label: '5000' },
];

// Contract Permissions
export const PERMISSION_CREATE_CONTRACT = 'create';
export const PERMISSION_VIEW_CONTRACT = 'view';
export const PERMISSION_EDIT_CONTRACT = 'edit';
export const PERMISSION_AMEND_CONTRACT = 'amend';
export const PERMISSION_LIST_CONTRACT = 'list';
export const PERMISSION_DELETE_CONTRACT = 'delete';

/**
 * Contract Type to Component Map
 * componentPath is relative to src/packages/rate_management/components
 * all the fields are constants only, hence defined here
 */

export const CONTRACT_TYPE_COMPONENT_MAP: any = {
  'air-buy': {
    componentPath: './Air/AirContract',
    contractProps: {
      freightType: AIR_FREIGHT,
      rateType: RATE_TYPE_BUY,
      docType: AIR_CONTRACT_TYPE,
    },
  },
  'air-sell': {
    componentPath: './Air/AirContract',
    contractProps: {
      freightType: AIR_FREIGHT,
      rateType: RATE_TYPE_SELL,
      docType: AIR_CONTRACT_TYPE,
    },
  },
  'due-agent': {
    componentPath: './Air/AirContract',
    title: 'Due Agent Contract',
    contractProps: {
      freightType: AIR_FREIGHT,
      rateType: RATE_TYPE_SELL,
      docType: DUE_AGENT_CONTRACT_TYPE,
    },
  },
  'air-named-account': {
    componentPath: './Air/AirContract',
    contractProps: {
      freightType: AIR_FREIGHT,
      rateType: RATE_TYPE_NAMED_ACCOUNT,
      docType: AIR_CONTRACT_TYPE,
    },
  },
  'ocean-buy': {
    componentPath: './Ocean/OceanContract',
    contractProps: {
      freightType: OCEAN_FREIGHT,
      rateType: RATE_TYPE_BUY,
      docType: OCEAN_CONTRACT_TYPE,
    },
  },
  'ocean-sell': {
    componentPath: './Ocean/OceanContract',
    contractProps: {
      freightType: OCEAN_FREIGHT,
      rateType: RATE_TYPE_SELL,
      docType: OCEAN_CONTRACT_TYPE,
    },
  },
  'ocean-named-account': {
    componentPath: './Ocean/OceanContract',
    contractProps: {
      freightType: OCEAN_FREIGHT,
      rateType: RATE_TYPE_NAMED_ACCOUNT,
      docType: OCEAN_CONTRACT_TYPE,
    },
  },
  'ocean-locals': {
    title: 'Ocean Locals Contract',
    componentPath: './Ocean/OceanContract',
    contractProps: {
      freightType: OCEAN_FREIGHT,
      rateType: RATE_TYPE_BUY,
      docType: OCEAN_LOCALS_CONTRACT_TYPE,
    },
  },
  'road-sell': {
    componentPath: './Road/RoadContract',
    contractProps: {
      freightType: ROAD_FREIGHT,
      rateType: RATE_TYPE_SELL,
      docType: ROAD_CONTRACT_TYPE,
    },
  },
};
