/**
 * Purpose: This file, based on props, will identify which form / component needs to be rendered.
 */

import React, { useMemo, lazy, Suspense, useRef, useState, useCallback, useEffect } from 'react';
import { capitalize as _capitalize, startCase as _startCase } from 'lodash';
import { useParams, useLocation } from 'wouter';

import {
  Badge,
  Button,
  FallbackSkeleton,
  hasPermission,
  message,
  NoPermissionFallback,
  PageHeader,
} from '@shipmnts/pixel-hub';
import { ActionRenderer } from 'operations';
import {
  AMEND_CONTRACT_STATUS,
  CONTRACT_DOCTYPE_MAP,
  CONTRACT_TYPE_COMPONENT_MAP,
  DRAFT_CONTRACT_STATUS,
  PERMISSION_CREATE_CONTRACT,
  PERMISSION_EDIT_CONTRACT,
  PERMISSION_VIEW_CONTRACT,
  STATUS_CLASS_MAP,
  SUBMIT_CONTRACT_STATUS,
} from '../constants';
import { useSession } from 'common';
import { useMutation } from '@apollo/client';
import { UPSERT_CONTRACT } from '../graphql/mutations';

const getContractForm = (contractType?: string) => {
  const component =
    CONTRACT_TYPE_COMPONENT_MAP[contractType as keyof typeof CONTRACT_TYPE_COMPONENT_MAP];
  if (!component) message.error('Invalid Contract Type Passed to BaseContract');
  return !!component
    ? {
        ContractForm: lazy(() => import(`${component.componentPath}`)),
        ...component.contractProps,
        title: component.title,
      }
    : {};
};

const BaseContract = (props: { contract?: any; refetchContract?: any }) => {
  const { contract, refetchContract } = props;
  const { 1: navigate } = useLocation();
  const session = useSession();

  // params
  const params = useParams<{ id: string; type: string }>();
  const id = contract?.id || params.id;
  const contractType = params.type;

  // mutations & queries
  const [upsertContract, { data: contractData }] = useMutation(UPSERT_CONTRACT);

  // constants
  const isCreate = id === 'new';

  const { ContractForm, freightType, rateType, title } = useMemo(
    () => getContractForm(contractType),
    [contractType]
  );

  const doc_type = CONTRACT_DOCTYPE_MAP[freightType || ''];
  const contractStatus = useMemo(() => contract?.status, [contract]);

  // refs
  const ContractFormRef = useRef<{
    handleOnCreate: (status?: string) => void;
  }>();

  // states
  const [valuesChanged, setValuesChanged] = useState(isCreate);

  // callbacks
  const submitForm = useCallback(
    (payload: any) => {
      upsertContract({
        variables: {
          contract: payload,
          contract_id: !isCreate ? id : null,
        },
      });
    },
    [upsertContract, isCreate, id]
  );

  const handleSaveOrSubmit = useCallback(() => {
    if (ContractFormRef.current?.handleOnCreate) {
      ContractFormRef.current.handleOnCreate(
        valuesChanged ? DRAFT_CONTRACT_STATUS : SUBMIT_CONTRACT_STATUS
      );
    }
  }, [valuesChanged]);

  // effects
  useEffect(() => {
    if (contractData?.upsert_contract?.contract) {
      message.success(`Contract ${isCreate ? 'Created' : 'Updated'} Successfully`);
      if (!isCreate) refetchContract();
      else
        navigate(`~/form/contract/${contractType}/${contractData?.upsert_contract?.contract.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractData, navigate]);

  // Permission Check
  const hasViewPermission =
    !isCreate &&
    hasPermission(session.permissions, {
      name: PERMISSION_VIEW_CONTRACT,
      docType: doc_type,
    });

  const hasCreatePermission =
    isCreate &&
    hasPermission(session.permissions, {
      name: PERMISSION_CREATE_CONTRACT,
      docType: doc_type,
    });

  const hasEditPermission =
    !isCreate &&
    hasViewPermission &&
    hasPermission(session.permissions, {
      name: PERMISSION_EDIT_CONTRACT,
      docType: doc_type,
    });

  if (!hasCreatePermission && !hasViewPermission) {
    return (
      <Suspense fallback={<FallbackSkeleton />}>
        <NoPermissionFallback action={isCreate ? 'Create' : 'View this'} resource="contract" />
      </Suspense>
    );
  }

  return (
    <>
      <PageHeader
        style={{
          backgroundColor: '#FFF',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          top: '42px',
        }}
        title={
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: '16px' }}>
              {[AMEND_CONTRACT_STATUS, SUBMIT_CONTRACT_STATUS].includes(
                contract?.status as string | ''
              ) ? (
                contract?.contract_number
              ) : (
                <>
                  {!!contract?.amended_from_id ? `Amend` : !isCreate ? 'Edit' : 'Create'}{' '}
                  {!!title
                    ? title
                    : `${_capitalize(freightType)} ${_capitalize(rateType)} Contract`}
                </>
              )}
            </span>
            {contractStatus && (
              <span
                className={STATUS_CLASS_MAP[contractStatus as keyof typeof STATUS_CLASS_MAP]}
                style={{ fontSize: '14px', marginLeft: '14px', display: 'flex' }}
              >
                <Badge color={'red'} />
                <span style={{ marginLeft: '6px' }}>{_startCase(contractStatus)}</span>
              </span>
            )}
          </div>
        }
        onBack={() => window.history.back()}
        extra={[
          !isCreate && (
            <ActionRenderer
              id={contract?.id}
              key={'actions'}
              refetchData={refetchContract}
              data={contract}
              isDetailScreen={true}
              doc_type_id={doc_type}
            />
          ),
          (contract?.status === DRAFT_CONTRACT_STATUS || isCreate) &&
            (hasEditPermission || hasCreatePermission) && (
              <Button
                type="primary"
                key={'save_n_submit_button'}
                size="small"
                onClick={handleSaveOrSubmit}
              >
                {valuesChanged ? (isCreate ? 'Create Contract' : 'Save') : 'Submit'}
              </Button>
            ),
        ]}
      />
      <div
        style={{
          margin: '20px 30px',
          paddingTop: '40px',
          borderRadius: '4px',
        }}
      >
        <Suspense fallback={<FallbackSkeleton />}>
          <ContractForm
            ref={ContractFormRef}
            data={contract}
            contractType={contractType}
            onValuesChange={setValuesChanged}
            submitForm={submitForm}
          />
        </Suspense>
      </div>
    </>
  );
};

export default BaseContract;
