import React, { useEffect, useState, useCallback, useMemo, createRef } from 'react';
import { axiosInstance as axios, useSession } from 'common';
import { Layout, Card, Divider, Button, AppHelmet } from '@shipmnts/pixel-hub';
import { serializeQueryParams, navbarTypes } from 'shipmnts_navigation';

import { useLocation } from 'wouter';

const { Sider, Content } = Layout;

type NavGroup = navbarTypes.NavGroup;
type NavItem = navbarTypes.NavItem;

const sanitizeRoute = (route: string): string => {
  let sanitized = route.trim();
  if (sanitized.charAt(0) !== '/') sanitized = '/' + sanitized;
  if (sanitized.charAt(sanitized.length - 1) === '/') sanitized = sanitized.slice(0, -1);
  return sanitized;
};

const Home = (): JSX.Element => {
  const [navData, setNavData] = useState<NavGroup[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const session = useSession();
  const { 1: navigate } = useLocation();
  async function getNevData() {
    try {
      const response = await axios.post(`${process.env.ALEX_URL}/navigation/get_nav_json`);
      setNavData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const refs: any = useMemo(() => {
    return {};
  }, []);

  const scrollTo = useCallback((ref: any) => {
    if (ref && ref.current) {
      window.scrollTo({ top: ref.current.offsetTop - 45, behavior: 'smooth' });
    }
  }, []);
  const onTabChange = useCallback(
    (o: string) => {
      scrollTo(refs[o]);
      setActiveTab(o);
    },
    [refs, scrollTo]
  );

  useEffect(() => {
    if (navData.length > 0 && navData[0].modules.length > 0) {
      setActiveTab(navData[0].modules[0].module_name);
    }
  }, [navData]);

  useEffect(() => {
    getNevData();
  }, []);
  if (navData.length > 0) {
    navData.forEach((module_data: any, index) => {
      module_data.modules.forEach((module: any) => {
        refs[module.module_name] = createRef<HTMLDivElement>();
      });
      return 0;
    });
  }

  const handleChangeRoute = useCallback(
    (item: NavItem) => {
      navigate(`${item.route || ''}?${serializeQueryParams(item.route_options || {})}`);
    },
    [navigate]
  );

  const getUrl = useCallback(
    (itemAppName: string, route?: string, routeOptions?: Record<string, unknown>) => {
      let url: string | undefined = '';
      if (itemAppName === 'shipmntsWeb') {
        url = process.env.SHIPMNTS_WEB_URL;
      } else if (itemAppName === 'operations') {
        url = process.env.OPERATIONS_URL;
      } else {
        url = session?.company_account.subdomain;
      }
      if (route) {
        url = url + sanitizeRoute(route);
        if (routeOptions && Object.keys(routeOptions).length > 0) {
          url = url + `?${serializeQueryParams(routeOptions)}`;
        }
      }
      return url;
    },
    [session?.company_account.subdomain]
  );
  return (
    <Layout>
      <AppHelmet>
        <title>Home</title>
      </AppHelmet>
      <Sider
        width={'250px'}
        theme="light"
        style={{
          position: 'sticky',
          top: '43px',
          height: '95vh',
          flex: '0 0 250px',
          borderRight: '1px solid #f0f0f0',
        }}
      >
        {navData &&
          navData.length > 0 &&
          navData.map((module_data: any, index) => {
            return (
              <div key={index}>
                {module_data.title && (
                  <h2 className="sider-title" key={module_data.title}>
                    {module_data.title}
                  </h2>
                )}
                <Divider style={{ margin: '10px 0' }}></Divider>
                {module_data.modules.map((module: any) => {
                  return (
                    <div
                      className={`sider-link ${
                        activeTab === module.module_name && 'active-sider-link'
                      }`}
                      key={module.module_name}
                      onClick={() => onTabChange(module.module_name)}
                      style={{ margin: '10px 10px 10px 24px' }}
                      data-label={module.module_name || ''}
                      data-id={module.module_name || ''}
                    >
                      {module.module_name}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </Sider>
      <Content>
        {navData &&
          navData.length > 0 &&
          navData.map((module_data: any, index) => {
            return (
              <div
                key={index}
                id={module_data.title}
                ref={refs[module_data.title]}
                style={{ margin: '5px' }}
              >
                {module_data.modules.map((module: any) => {
                  return (
                    <div
                      key={module.module_name}
                      id={module.module_name}
                      ref={refs[module.module_name]}
                    >
                      <h3
                        key={module.module_name}
                        style={{ marginLeft: '1%', paddingTop: '7px', paddingLeft: '20px' }}
                      >
                        {module.module_name}
                      </h3>
                      <Divider style={{ margin: 0 }} />
                      <div className="home-module-head">
                        {module.sections.map((section: any) => {
                          return (
                            <Card
                              bordered={false}
                              title={section.label}
                              className="homepage-single-card"
                              style={{ margin: '5px' }}
                              key={section.label}
                            >
                              {section.items.map((item: any) => {
                                return (
                                  <div key={item.label}>
                                    <Button
                                      onClick={(e: any) => {
                                        if (item.coming_soon) return;
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if ('operations' === item.app_name) {
                                            e.preventDefault();
                                            handleChangeRoute(item);
                                          }
                                        }
                                      }}
                                      href={getUrl(item.app_name, item.route, item.route_options)}
                                      type="link"
                                      disabled={item.coming_soon}
                                    >
                                      {item.label}
                                    </Button>
                                  </div>
                                );
                              })}
                            </Card>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </Content>
    </Layout>
  );
};

export default Home;
