import React, { useCallback, useEffect } from 'react';
import { Card, Form, FormInstance, UploadedDocuments } from '@shipmnts/pixel-hub';
import { useErpNextConfig } from 'network';
import CustomerLedgerDetailsForm from 'network/components/CustomerLedgerDetailsForm';
import CreditControlDetailsForm from 'network/components/CreditControlDetailsForm';
import { useSession, TeamFormTable } from 'common';
import { WithPermission, hasPermission } from '@shipmnts/pixel-hub';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_CUSTOMER_VIEW,
  PERMISSION_SUPPLIER_CREATE_EDIT,
  PERMISSION_SUPPLIER_VIEW,
} from 'network/permissions';
import SupplierLedgerDetailsForm from 'network/components/SupplierLedgerDetailsForm';
import { getERPNextCompanyData } from 'network/erpnextApis';
import { pick as _pick } from 'lodash';

type Props = {
  initialValue?: any;
  form: FormInstance;
  action: string;
};

const CompanyConvertForm = (props: Props) => {
  const { form, action, initialValue } = props;
  const { erpnextConfigData } = useErpNextConfig();
  const session = useSession();

  const uploadViewPermission = {
    OR: [
      { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
      { name: PERMISSION_SUPPLIER_VIEW, docType: 'Network::Company' },
    ],
  };
  const uploadEditPermission = hasPermission(session.permissions, {
    OR: [
      { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
      { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
    ],
  });

  const fetchCompany = useCallback(async () => {
    let formData: any = { is_msme: 0, teams: initialValue?.teams };
    if (initialValue) {
      const { response } = await getERPNextCompanyData(initialValue?.registered_name);
      if (response) {
        const accountsData = response.data?.message?.company;
        formData = {
          ...formData,
          receivable_credit_control_type: accountsData.receivable_credit_control_type.toLowerCase(),
          ..._pick(accountsData, [
            'default_currency',
            'tax_registration_number',
            'tax_deduction_id',
            'receivable_accounts',
            'receivable_credit_limit',
            'is_msme',
            'credit_overdue_limit',
            'receivable_payment_terms',
            'receivable_tax_withholding_category',
            'payable_payment_terms',
            'payable_tax_withholding_category',
            'payable_accounts',
            'sales_team',
            'status',
          ]),
        };
      }
    }
    form.setFieldsValue(formData);
  }, [form, initialValue]);

  useEffect(() => {
    if (initialValue) fetchCompany();
  }, [fetchCompany, initialValue]);

  return (
    <>
      {['Customer', 'Agent'].includes(action) && (
        <WithPermission
          permission={{ name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' }}
        >
          {erpnextConfigData && (
            <Card className="CreateLeadCard" title="Customer Ledger Details">
              <CustomerLedgerDetailsForm erpnextConfigData={erpnextConfigData} />
            </Card>
          )}
          {erpnextConfigData && (
            <Card className="CreateLeadCard" title="Credit Details">
              <CreditControlDetailsForm form={form} />
            </Card>
          )}
          <Card className="CreateLeadCard" title="Team Details">
            <Form.Item name="teams">
              <TeamFormTable reference_type="Network::Company" />
            </Form.Item>
          </Card>
        </WithPermission>
      )}
      {['Supplier', 'Agent'].includes(action) && erpnextConfigData && (
        <Card className="CreateLeadCard" title="Supplier Ledger Details">
          <SupplierLedgerDetailsForm erpnextConfigData={erpnextConfigData} />
        </Card>
      )}
      {initialValue?.id && (
        <WithPermission permission={uploadViewPermission}>
          <Card id="attachments" title="Uploaded Documents" className="CreateLeadCard">
            <UploadedDocuments
              sessionData={session}
              parent_id={initialValue.id}
              parent_type={'company'}
              docgen_url={process.env.DOCGEN_URL || ''}
              uploadDisabled={!uploadEditPermission}
              deleteDisabled={!uploadEditPermission}
            />
          </Card>
        </WithPermission>
      )}
    </>
  );
};

export default CompanyConvertForm;
