import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';
import { shipmentContainerFields } from 'operations/modules/booking/graphql';
import { containerSettingsFields } from 'operations/modules/booking/graphql/containerRequest';

const containerReportCommonFields = gql`
  fragment containerReportCommonFields on ShipmentContainersReportObjectType {
    id
    booking_number
    booking_cargos {
      id
      product_name
      cargo_properties {
        is_hazardous
      }
    }
    booking_request {
      id
      shipment_booking_number
    }
    container_number
    container_type
    container_type_code
    customer_company {
      id
      registered_name
      status
      country_of_incorporation
      sales_partner {
        id
        registered_name
        country_of_incorporation
      }
    }
    container_settings {
      ...containerSettingsFields
    }
    global_carrier {
      id
      name
      carrier_type
    }
    last_action_status
    master_shipment {
      id
    }
    next_actions
    ocean_transport_order_id
    port_of_loading {
      id
      name
    }
    port_of_discharge {
      id
      name
    }
    remarks
    status
    vessel {
      imo
      name
    }
    voyage_number
    customer_company_id
    port_of_loading_id
    port_of_discharge_id
    sell_collaborations {
      id
      vendor_address {
        id
      }
      sales_person {
        ...salesPersonFields
      }
    }
  }
  ${containerSettingsFields}
  ${salesPersonFields}
`;

const preAlertContainerReportFields = gql`
  fragment preAlertContainerReportFields on ShipmentContainersReportObjectType {
    booking_request {
      id
      shipment_booking_number
      container_requests {
        id
        container_type
        container_type_code
        quantity
      }
      shipment_containers {
        id
        container_type
        container_type_code
        container_number
      }
    }
    customer_address {
      id
      name
      print_address
      company_id
    }
    dg_indexing_date
    doc_cutoff_date
    si_cutoff_date
    estimated_time_of_departure
    shipper_company {
      id
      registered_name
    }
    shipper_address {
      id
      name
      print_address
      company_id
    }
    verified_gross_mass
    email_activities_all {
      id
      purpose
    }
  }
`;

const allContainerReportFields = gql`
  fragment allContainerReportFields on ShipmentContainersReportObjectType {
    place_of_carrier_receipt {
      id
      name
    }
    place_of_carrier_delivery {
      id
      name
    }
    estimated_time_of_departure
    house_shipments {
      id
      job_number
      shipment_document_house {
        id
        document_id
        shipment_number
      }
    }
    master_shipment {
      id
      job_number
      shipment_type
    }
  }
`;

export const CONTAINER_REPORT = gql`
  query shipment_containers_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
    $pre_alert_pending: Boolean!
    $all: Boolean!
  ) {
    shipment_containers_report(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        ...containerReportCommonFields
        ...preAlertContainerReportFields @include(if: $pre_alert_pending)
        ...allContainerReportFields @include(if: $all)
      }
    }
  }
  ${containerReportCommonFields}
  ${preAlertContainerReportFields}
  ${allContainerReportFields}
`;

export const OFFLOAD_CONTAINERS = gql`
  mutation offload_containers(
    $shipment_container_ids: [ID!]!
    $offloaded_at: Time!
    $cancelled_by: String!
    $cancellation_reason: String!
    $return_location_id: ID
    $return_expected_at: Time
    $customer_company_id: ID
    $job_date: Time
  ) {
    offload_containers(
      shipment_container_ids: $shipment_container_ids
      offloaded_at: $offloaded_at
      cancelled_by: $cancelled_by
      cancellation_reason: $cancellation_reason
      return_location_id: $return_location_id
      return_expected_at: $return_expected_at
      customer_company_id: $customer_company_id
      job_date: $job_date
    ) {
      ...shipmentContainerFields
    }
  }
  ${shipmentContainerFields}
`;

export const GET_BULK_ACTIONS_BY_CONTAINER_IDS = gql`
  query get_bulk_actions_by_container_ids($container_ids: [ID!]!) {
    get_bulk_actions_by_container_ids(container_ids: $container_ids)
  }
`;
