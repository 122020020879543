import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Button, Card, message, Skeleton, Typography, Form } from '@shipmnts/pixel-hub';
import { PageHeader, NoPermissionFallback } from '@shipmnts/pixel-hub';
import { useLocation, useParams } from 'wouter';
import AddressFormContent, {
  AddressFormValues,
  AddressFormNullableValues,
} from './AddressFormContent';
import { CompanyValue, AddressAccountsData } from 'network/models/Company';
import { AddressValue } from 'network/models/Address';

import { GET_ADDRESS } from 'network/graphql/address';
import { useApolloClient, useMutation } from '@apollo/client';
import { getERPNextAddressData } from 'network/erpnextApis';
import { pick as _pick } from 'lodash';
import { UPSERT_COMPANY } from 'network/graphql/company';
import DisableAddress from './DisableAddress';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import { useSession, errorMessageHandlerGraphQL, isCustomer, isVendor } from 'common';
import { INVOICE_TYPE_REGULAR, INVOICE_TYPE_EXPORT_IMPORT } from 'network/regional/IN/constants';
import { useErpNextConfig } from 'network/utils/ErpNextConfigDataWrapper';
import { ConditionalPermissions, WithPermission, AppHelmet } from '@shipmnts/pixel-hub';

const { Header, Content } = Layout;
const { Text } = Typography;

interface InitialAddressValue extends Partial<AddressValue> {
  accounts_data?: AddressAccountsData;
}

interface InitialAddressFormValueType extends Partial<AddressValue> {
  is_billing?: boolean;
  preferred_invoice_type?: string;
  gstin?: string;
  tan_number?: string;
}

interface AddressFormProps {
  renderedFromAddressSearch?: boolean;
  onSuccess?: (value: AddressValue) => void;
  addressId?: string;
  initialAddress?: InitialAddressValue;
  initialCompany?: CompanyValue;
}

const getUpsertCompanyPayload = (
  formValues: AddressFormValues,
  initialValues: InitialAddressFormValueType,
  company: CompanyValue,
  address_id?: string
) => {
  const payload = { ...formValues };
  if (address_id !== 'new') {
    payload['id'] = address_id;
  }
  const address_entity_type = payload?.is_billing ? 'billing' : 'shipping';
  const city_id = payload?.city?.id || null;
  delete payload?.city;
  delete payload?.is_billing;
  payload['entity_type'] = address_entity_type;
  payload['city_id'] = city_id;

  if (payload.id) {
    const nullableKeys: Array<keyof AddressFormNullableValues> = ['state_name'];
    nullableKeys.forEach((key) => {
      if (key in (initialValues || {}) && !payload[key]) {
        payload[key] = null;
      }
    });
  }
  return { id: company.id, addresses: [payload] };
};

const getFormInitialData = (initialAddress?: InitialAddressValue) => {
  if (!initialAddress) return { preferred_invoice_type: INVOICE_TYPE_REGULAR };
  const { accounts_data } = initialAddress;
  const formPayload: InitialAddressFormValueType = {
    ..._pick(initialAddress, [
      'id',
      'name',
      'city',
      'address_line_1',
      'address_line_2',
      'postal_code',
      'print_address',
      'state_name',
      'attn',
    ]),
    is_billing: initialAddress.entity_type === 'billing',
    ..._pick(accounts_data, ['preferred_invoice_type', 'gstin', 'tan_number']),
  };
  return formPayload;
};

const AddressForm = React.memo(function AddressForm(props: AddressFormProps): JSX.Element {
  const { renderedFromAddressSearch, onSuccess } = props;
  const { 1: navigate } = useLocation();
  const params = useParams<{ id: string }>();
  const address_id = props.addressId || params.id;
  const session = useSession();
  const { fetchConfigData } = useErpNextConfig();

  useEffect(() => {
    if (fetchConfigData) fetchConfigData();
  }, [fetchConfigData]);

  const initial_address: InitialAddressValue | undefined = window.history?.state?.address
    ? {
        ...window.history?.state?.address,
        accounts_data: window.history?.state?.addressAccountsData,
      }
    : props.initialAddress;

  const [initialAddress, setInitialAddress] = useState<InitialAddressValue | undefined>(
    initial_address
  );

  const initial_company =
    window.history?.state?.company || props.initialCompany || initialAddress?.company;

  const [fetchingAddress, setFetchingAddress] = useState(false);
  const [fetchAddressError, setFetchAddressError] = useState<boolean | string>(false);

  const [form] = Form.useForm();
  const client = useApolloClient();
  const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;

  const [upsertCompany, { data, loading, error }] = useMutation(UPSERT_COMPANY);

  const fetchAddress = useCallback(async () => {
    setFetchingAddress(true);
    setFetchAddressError(false);
    const { data, errors } = await client.query({
      query: GET_ADDRESS,
      variables: { id: address_id },
    });
    if (data && data.address) {
      const address = data.address;
      if (address?.accounts_address_link) {
        const { response, error } = await getERPNextAddressData(address.accounts_address_link);
        if (response) {
          const accountsData = response.data?.message?.address;
          const initial_address = { ...address, accounts_data: accountsData };
          setInitialAddress(initial_address);
          const formData = getFormInitialData(initial_address);
          form.setFieldsValue(formData);
        } else if (error) {
          if (error instanceof Error) setFetchAddressError(error.message);
        }
      } else {
        setInitialAddress(address);
        const formData = getFormInitialData(address);
        form.setFieldsValue(formData);
      }
    } else if (errors) {
      setFetchAddressError('Error while fetching address');
    }
    setFetchingAddress(false);
  }, [address_id, client, form]);

  useEffect(() => {
    if (address_id !== 'new' && !initialAddress) {
      fetchAddress();
    }
  }, [address_id, initialAddress, fetchAddress]);

  useEffect(() => {
    if (error) {
    }
    if (data?.upsert_company) {
      message.success('Address saved successfully!');
      if (onSuccess) {
        const print_address = form.getFieldValue('print_address');
        const newAddress =
          data.upsert_company.company.addresses?.find(
            (address: any) => address.print_address === print_address
          ) || data.upsert_company.company.addresses?.at(-1);
        onSuccess(newAddress);
      } else {
        navigate(`~/view/company/${data.upsert_company?.company?.id}`, {
          replace: true,
          state: {
            company: data.upsert_company.company,
            company_accounts_data: data.upsert_company.company_accounts_data,
          },
        });
      }
    }
  }, [data, error, navigate, onSuccess, form]);

  let permission: ConditionalPermissions | undefined = undefined;
  if (isCustomer(initial_company?.company_type) || isVendor(initial_company?.company_type))
    permission = {
      OR: [
        { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
        { name: PERMISSION_SUPPLIER_CREATE_EDIT, docType: 'Network::Company' },
      ],
    };

  const permissionWrap = (content: React.ReactElement, key: string) => {
    let wrappedContent = content;
    wrappedContent = (
      <WithPermission key={key} fallback={<NoPermissionFallback />} permission={permission}>
        {wrappedContent}
      </WithPermission>
    );
    return wrappedContent;
  };

  const initialValues = getFormInitialData(initialAddress);
  return (
    <Form
      form={form}
      onFinish={(values) => {
        const payload = getUpsertCompanyPayload(values, initialValues, initial_company, address_id);
        upsertCompany({ variables: { company: payload } });
      }}
      scrollToFirstError
      name="address"
      layout="vertical"
      style={{
        height: '100%',
      }}
      initialValues={initialValues}
      onValuesChange={(changedValues, _allValues) => {
        if (tenant_country_code === 'IN' && Object.keys(changedValues).includes('is_billing')) {
          form.setFieldsValue({
            preferred_invoice_type:
              initial_company?.country_of_incorporation !== tenant_country_code
                ? INVOICE_TYPE_EXPORT_IMPORT
                : INVOICE_TYPE_REGULAR,
          });
        }
      }}
    >
      <Layout style={{ maxWidth: '1200px', margin: 'auto' }} className="form-layout">
        <AppHelmet>
          <title>
            {address_id === 'new'
              ? `New Address for ${initial_company?.registered_name || ''}`
              : `Edit Address for ${initial_company?.registered_name || ''}`}
          </title>
        </AppHelmet>
        <Header style={{ padding: 0, background: 'none', height: 'auto' }}>
          <PageHeader
            style={{ padding: '10px 0px' }}
            onBack={
              renderedFromAddressSearch
                ? undefined
                : () => {
                    window.history.back();
                  }
            }
            title={
              address_id === 'new'
                ? `New Address for ${initial_company?.registered_name || ''}`
                : `Edit Address for ${initial_company?.registered_name || ''}`
            }
            extra={[
              address_id &&
                address_id !== 'new' &&
                !initialAddress?.is_disabled &&
                permissionWrap(
                  <DisableAddress
                    company_id={initial_company?.id}
                    address_id={address_id}
                    flag={true}
                    key="disable"
                    onSave={(upsert_company) => {
                      navigate(`~/view/company/${upsert_company?.company?.id}?tab=5`, {
                        replace: true,
                        state: upsert_company,
                      });
                    }}
                  >
                    Disable
                  </DisableAddress>,
                  'disable'
                ),
              address_id &&
                address_id !== 'new' &&
                initialAddress?.is_disabled &&
                permissionWrap(
                  <DisableAddress
                    company_id={initial_company?.id}
                    address_id={address_id}
                    flag={false}
                    key="enable"
                    onSave={(upsert_company) => {
                      const address = upsert_company?.company?.addresses?.find(
                        (add) => add.id === address_id
                      );
                      setInitialAddress(address);
                    }}
                  >
                    Enable Address
                  </DisableAddress>,
                  'enable'
                ),
              <Button
                loading={loading}
                disabled={initialAddress?.is_disabled}
                htmlType="submit"
                type="primary"
                key="save"
              >
                Save
              </Button>,
            ]}
          >
            {error && errorMessageHandlerGraphQL(error)}
            {fetchAddressError && (
              <span>
                <Text type="danger">{fetchAddressError}</Text>{' '}
                <Button onClick={fetchAddress} type="link">
                  Retry
                </Button>
              </span>
            )}
          </PageHeader>
        </Header>
        <Content>
          {fetchingAddress && (
            <>
              <Card>
                <Skeleton avatar paragraph={{ rows: 3 }} loading={fetchingAddress} active />
              </Card>
            </>
          )}
          {!fetchingAddress && !fetchAddressError && (
            <Card>
              {permissionWrap(
                <AddressFormContent
                  form={form}
                  country_code={
                    initialAddress?.country_code || initial_company?.country_of_incorporation
                  }
                  is_customer_or_supplier={
                    isCustomer(initialAddress?.company?.company_type) ||
                    isVendor(initialAddress?.company?.company_type) ||
                    isCustomer(initial_company?.company_type) ||
                    isVendor(initial_company?.company_type)
                  }
                  address_id={address_id}
                />,
                'form'
              )}
            </Card>
          )}
        </Content>
      </Layout>
    </Form>
  );
});

export default AddressForm;
