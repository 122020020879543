import { gql } from '@apollo/client';
import { salesPersonFields } from './salesPerson';
export const minimumCompanyFields = gql`
  fragment minimumCompanyFields on CompanyObjectType {
    id
    status
    registered_name
    country_of_incorporation
    company_group
    entity_type
    logo
    is_disabled
    company_type
    remarks
    company_identification_number
  }
`;

export const invoiceAddressFields = gql`
  fragment invoiceAddressFields on AddressObjectType {
    name
    address_line_1
    address_line_2
    state_tax_code
    postal_code
    entity_type
    company_id
    id
    print_address
    state_code
    state_name
    city_name
    city {
      id
      name
      city_code
    }
    country_code
    country_name
  }
`;
export const addressFields = gql`
  fragment addressFields on AddressObjectType {
    id
    name
    entity_type
    city {
      id
      name
    }
    city_name
    company_id
    address_line_1
    address_line_2
    postal_code
    print_address
    state_tax_code
    state_name
    state_code
    country_name
    country_code
    attn
    accounts_address_link
    is_disabled
    company {
      id
      registered_name
      company_type
      status
      country_of_incorporation
    }
  }
`;
export const companyFields = gql`
  fragment companyFields on CompanyObjectType {
    id
    status
    created_at
    updated_at
    registered_name
    country_of_incorporation
    created_by {
      id
      first_name
      last_name
    }
    company_group
    entity_type
    logo
    is_disabled
    company_type
    remarks
    company_identification_number
    tax_registration_number
    tax_deduction_id
    receivable_credit_control_type
    addresses {
      ...addressFields
    }
    company_email_preferences {
      id
      email_template_id
      address_id
      to_emails
      cc_emails
      address {
        id
        name
        city_name
        print_address
      }
      email_template {
        id
        template_name
        action_name
      }
    }
    global_company_account {
      id
      registered_name
    }
  }
  ${addressFields}
  ${salesPersonFields}
`;

export const shipmentEstimateFields = gql`
  fragment shipmentEstimateFields on ShipmentEstimateObjectType {
    id
    uuid
    item
    shipment_id
    buy_branch_id
    sell_branch_id
    supplier_company_id
    supplier_company {
      ...minimumCompanyFields
    }
    customer_company_id
    customer_company {
      ...minimumCompanyFields
    }
    uom
    quantity
    buy_rate
    buy_exchange_rate
    buy_terms
    buy_currency
    total_buy_amount
    total_buy_billed_amount
    sell_rate
    sell_exchange_rate
    sell_terms
    sell_currency
    total_sell_amount
    total_sell_billed_amount
    sell_remarks
    remarks
    buy_status
    sell_status
    buy_transaction_identifier
    buy_transaction_doctype
    sell_transaction_identifier
    equipment_type
    equipment_name
    freight_contract_item_id
    tag
    product_rating {
      id
    }
    sequence
    item_group
    item_description
    sell_contract_item {
      id
      contract {
        id
        contract_number
        status
        valid_till
        amended_to {
          id
          contract_number
        }
      }
    }
    buy_contract_item {
      id
      contract {
        id
        contract_number
        status
        valid_till
        amended_to {
          id
          contract_number
        }
      }
    }
    tax_percentage
    taxable
    taxability
    markup_type
    markup_value
  }
  ${minimumCompanyFields}
`;

export const FETCH_SHIPMENT_ESTIMATES = gql`
  query fetch_shipment_estimate_list($shipment_ids: [ID!]!) {
    fetch_shipment_estimate_list(shipment_ids: $shipment_ids) {
      ...shipmentEstimateFields
    }
  }
  ${shipmentEstimateFields}
`;

export const SAVE_SHIPMENT_ESTIMATES = gql`
  mutation save_shipment_estimates_new($shipment_estimates: [SaveShipmentEstimateNewInputType!]!) {
    save_shipment_estimates_new(shipment_estimates: $shipment_estimates) {
      ...shipmentEstimateFields
    }
  }
  ${shipmentEstimateFields}
`;

export const DELETE_SHIPMENT_ESTIMATE = gql`
  mutation delete_shipment_estimate($id: ID!) {
    delete_shipment_estimate(id: $id) {
      message
    }
  }
`;

export const HANDOVER_TO_BILLING = gql`
  mutation handover_to_billing($shipment_id: ID!, $utc_time_zone_in_seconds: Int!) {
    handover_to_billing(
      shipment_id: $shipment_id
      utc_time_zone_in_seconds: $utc_time_zone_in_seconds
    ) {
      shipment_estimates {
        id
      }
    }
  }
`;

export const FETCH_AWB_DUE_CHARGES = gql`
  query fetch_awb_due_charges($document_id: ID!, $carrier_id: ID) {
    fetch_awb_due_charges(document_id: $document_id, carrier_id: $carrier_id) {
      item
      printable_item_name
      rate
      terms
      uom
      tag
    }
  }
`;

export const CHECK_CONTRACT_MISMATCH = gql`
  query check_contract_mismatch($shipment_id: ID!) {
    check_contract_mismatch(shipment_id: $shipment_id)
  }
`;

export const APPLY_CONTRACTS_ON_SHIPMENT = gql`
  mutation apply_contracts_on_shipment($shipment_id: ID!) {
    apply_contracts_on_shipment(shipment_id: $shipment_id) {
      id
    }
  }
`;
