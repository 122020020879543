import React, { useEffect, useCallback } from 'react';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { Drawer, message, Form, DrawerFooter } from '@shipmnts/pixel-hub';
import RoadTransportOrderForm from './RoadTransportOrderForm';
import { observer } from 'mobx-react-lite';
import { AddressValue } from 'operations/models/Address';

import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  CREATE_ROAD_TRANSPORT_ORDER,
  UPDATE_ROAD_TRANSPORT_ORDER,
} from 'operations/modules/booking/graphql/roadTransportOrder';
import { GET_BOOKING_REQUEST } from 'operations/modules/booking/graphql/bookingRequest';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { RoadTransportOrderValue } from 'operations/models/RoadTransportOrder';
import { getContainerTypeSetting } from 'operations/models/ShipmentContainer';
import { getCrTypeIdMapping } from 'operations/modules/booking/components/BookingRequestView/OceanTransportOrdersView';
import { errorMessageHandlerGraphQL } from 'common';

import {
  LOAD_TYPE_FCL,
  RTO_TYPE_LOOSE,
  STUFFING_LOCATION_TYPE_FACTORY,
} from 'operations/baseConstants';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

interface RTOFormValue {
  load_type?: string;
  vendor?: PartyValue;
  managed_by?: string;
  factory_location?: AddressValue;
  type?: 'origin' | 'destination';
  requested_containers?: { [oto_id: string]: { [oto_cr_id: string]: { count?: number } } };
  link_cargo_ids?: Array<string>;
}

const getRTOPayload = (values: RTOFormValue, roadTransportOrderId?: string) => {
  const oto_allocations = values.requested_containers || {};
  return {
    id: roadTransportOrderId,
    managed_by: roadTransportOrderId ? undefined : values.managed_by,
    load_type: roadTransportOrderId ? undefined : values.load_type,
    type: roadTransportOrderId ? undefined : values.type,
    vendor_company_id: values?.vendor?.party_company?.id,
    factory_location_id: values?.factory_location?.id,
    link_cargo_ids: values?.link_cargo_ids,
    ocean_transport_orders_allocation: Object.keys(oto_allocations).reduce(
      (
        allocation_arr: Array<{
          ocean_transport_order_id: string;
          requested_containers: Array<{ id: string; count: number }>;
        }>,
        oto_id
      ) => {
        if (oto_allocations[oto_id]) {
          const requested_containers = Object.keys(oto_allocations[oto_id]).reduce(
            (containers_arr: Array<{ id: string; count: number }>, oto_cr_id) => {
              if (oto_cr_id && (oto_allocations[oto_id][oto_cr_id]?.count || 0) >= 0)
                containers_arr.push({
                  id: oto_cr_id,
                  count: oto_allocations[oto_id][oto_cr_id]?.count || 0,
                });
              return containers_arr;
            },
            []
          );
          if (requested_containers.length > 0)
            allocation_arr.push({ ocean_transport_order_id: oto_id, requested_containers });
        }
        return allocation_arr;
      },
      []
    ),
  };
};

const getContainersGroupedByOTO = (
  road_transport_order: RoadTransportOrderValue,
  ocean_transport_orders: OceanTransportOrderValue[]
) => {
  const rto_containers = road_transport_order.shipment_containers;
  if (ocean_transport_orders) {
    return ocean_transport_orders.reduce(
      (oto_map: { [id: string]: { [container_type_code: string]: { count: number } } }, oto) => {
        if (oto.shipment_containers) {
          const otoCrMapping = getCrTypeIdMapping(oto.container_requests || []);
          oto_map[oto.id] = oto.shipment_containers.reduce(
            (containers_map: { [oto_cr_id: string]: { count: number } }, container) => {
              const containerSetting = getContainerTypeSetting(
                container?.container_type_code || '',
                container.container_settings
              );
              const oto_cr_id = otoCrMapping[containerSetting];
              if (
                container.id &&
                rto_containers.find((c) => c.id === container.id) &&
                container.container_type_code
              ) {
                if (!containers_map[oto_cr_id]) containers_map[oto_cr_id] = { count: 0 };
                containers_map[oto_cr_id].count++;
              }
              return containers_map;
            },
            {}
          );
        }
        return oto_map;
      },
      {}
    );
  }
  return {};
};

const CreateUpdateRoadTransportOrder = observer(function CreateUpdateRoadTransportOrder(props: {
  roadTransportOrder?: RoadTransportOrderValue;
  oceanTransportOrders: OceanTransportOrderValue[];
  rtoType: 'origin' | 'destination';
  defaultCompanyId?: string;
  onClose: () => void;
  onSuccess?: (value: RoadTransportOrderValue) => void;
  disabled?: boolean;
  bookingRequest: BookingRequestValue;
}): JSX.Element {
  const {
    bookingRequest,
    rtoType,
    oceanTransportOrders,
    roadTransportOrder,
    defaultCompanyId,
    onSuccess,
    onClose,
  } = props;

  const bookingRequestId = bookingRequest.id;
  const rtoLoadType = roadTransportOrder
    ? roadTransportOrder.load_type
    : bookingRequest.stuffing_location_type === STUFFING_LOCATION_TYPE_FACTORY
    ? LOAD_TYPE_FCL
    : RTO_TYPE_LOOSE;

  let disabledCargos: Array<string> = [];
  disabledCargos = bookingRequest.road_transport_orders.reduce((acc, rto) => {
    if (rto.id !== roadTransportOrder?.id) {
      const rto_cargo_ids = rto.cargos.map((c: ProductOrderItemValue) => c.id || '');
      acc.push(...rto_cargo_ids);
    }
    return acc;
  }, disabledCargos);

  const [form] = Form.useForm();
  const client = useApolloClient();
  const isUpdate = Boolean(roadTransportOrder?.id);
  const [createUpdateFunction, { data, loading, error }] = useMutation(
    isUpdate ? UPDATE_ROAD_TRANSPORT_ORDER : CREATE_ROAD_TRANSPORT_ORDER,
    {
      ...(!isUpdate
        ? {
            update(cache, { data: { create_road_transport_order } }) {
              if (create_road_transport_order) {
                const booking_request = cache.readQuery<{ booking_request: BookingRequestValue }>({
                  query: GET_BOOKING_REQUEST,
                  variables: { id: bookingRequestId },
                })?.booking_request;
                if (booking_request)
                  client.writeQuery({
                    query: GET_BOOKING_REQUEST,
                    data: {
                      booking_request: {
                        ...booking_request,
                        road_transport_orders: [
                          ...booking_request.road_transport_orders,
                          create_road_transport_order,
                        ],
                      },
                    },
                  });
              }
            },
          }
        : {}),
    }
  );

  const orderType = rtoType === 'origin' ? 'Pickup' : 'Delivery';

  useEffect(() => {
    const rto = data && data[`${isUpdate ? 'update' : 'create'}_road_transport_order`];
    if (rto) {
      message.success(`${orderType} Order successfully ${isUpdate ? 'updated' : 'created'}!`);
      if (onSuccess) onSuccess(rto);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const vendorCompany =
    defaultCompanyId && roadTransportOrder
      ? roadTransportOrder.getVendorCompany(defaultCompanyId)
      : undefined;

  const closeDrawer = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  return (
    <Drawer
      title={`${isUpdate ? 'Update' : 'Create'} a Pickup Order`}
      width={'60%'}
      open={true}
      onClose={onClose}
      footer={
        <DrawerFooter
          saveText="Submit"
          closeText="Cancel"
          loading={loading}
          onClose={closeDrawer}
          onSave={form.submit}
        />
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: RTOFormValue) => {
          const payload = getRTOPayload(values, roadTransportOrder?.id);
          createUpdateFunction({
            variables: {
              road_transport_order: payload,
              booking_request_id: !isUpdate && bookingRequestId,
            },
          });
        }}
        initialValues={
          roadTransportOrder
            ? {
                load_type: roadTransportOrder.load_type,
                managed_by:
                  roadTransportOrder.order_collaborations.length === 1 ? 'customer' : 'forwarder',
                vendor: vendorCompany
                  ? {
                      party_company: vendorCompany,
                    }
                  : undefined,
                factory_location: roadTransportOrder.factory_location || undefined,
                requested_containers: getContainersGroupedByOTO(
                  roadTransportOrder,
                  oceanTransportOrders
                ),
                link_cargo_ids: roadTransportOrder.cargos?.map((c) => c.id),
              }
            : {
                load_type: rtoLoadType,
                managed_by: 'forwarder',
                type: rtoType,
              }
        }
      >
        {error && errorMessageHandlerGraphQL(error)}

        <Form.Item noStyle name="type">
          <span />
        </Form.Item>
        <RoadTransportOrderForm
          disabledFields={[]}
          requiredFields={['load_type', 'managed_by', 'vendor', 'factory_location']}
          oceanTransportOrders={oceanTransportOrders}
          shipperCompanyId={bookingRequest.shipper?.party_company?.id || ''}
          customerCompanyId={bookingRequest.customerCompany?.id || ''}
          bookingRequestId={bookingRequest.id}
          roadTransportOrderId={roadTransportOrder?.id}
          booking_request_container_requests={bookingRequest.container_requests}
          load_type={rtoLoadType}
          cargos={bookingRequest.cargos}
          disabledCargos={disabledCargos}
        />
      </Form>
    </Drawer>
  );
});

export default CreateUpdateRoadTransportOrder;
