/**
  PURPOSE: Defining a common model for all rate contracts, 
  with all the fields a contract can have.

  TODO: As we have tried to use types.reference here (for first time), 
  check if there are any negative effect of this.

  TODO: Add regex in contract_number

  TODO: bring enumeration in charge_basis

  NOTE: For now we will have two possible values of applicability (customer, pricing_tier),
  however for now, pricing-tier will remain disabled, as it is planned to be part of M2.

  NOTE: pricing_tier will be defined here in M2.

  CONFIRM: created_by will not be null in any case?

  CONFIRM: contract_type to be used only in air-contracts?

  CONFIRM: vehicle_type is not an enum?

  CONFIRM: only terms_and_condition is necessary & not the id?
*/

import { IAnyModelType, Instance, types } from 'mobx-state-tree';

// Constants
import {
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  EXPIRE_CONTRACT_STATUS,
  LOAD_TYPES,
} from '../constants';

// References
import UserAccount from 'common/models/UserAccount';
import Company from 'common/models/Company';
import BranchAccount from 'common/models/BranchAccount';
import { AirContractLineItem } from './AirContractLineItem';
import { OceanContractLineItem } from './OceanContractLineItem';
import RoadContractLineItem from './RoadContractLineItem';
import { AdditionalChargeLineItem } from './AdditionalChargeLineItem';
import { dayjs } from '@shipmnts/pixel-hub';
import Template from 'common/models/Template';

export const Contract = types
  .model({
    id: types.identifier,
    type: types.maybeNull(types.enumeration(CONTRACT_TYPES)),
    rate_type: types.maybeNull(types.enumeration(['buy', 'sell', 'named_account'])),
    trade_type: types.maybeNull(types.enumeration(['import', 'export', 'cross_trade', 'domestic'])),
    load_type: types.maybeNull(types.enumeration(LOAD_TYPES)),
    applicability: types.maybeNull(types.enumeration(['customer', 'pricing_tier'])),
    accessible_by_branches: types.maybe(
      types.maybeNull(types.array(types.maybeNull(BranchAccount)))
    ),
    charge_basis: types.maybeNull(types.string),
    freight_charge: types.maybeNull(types.string),
    // customer_tier -> identifier (M2),
    created_by: types.maybeNull(UserAccount),
    commodity_type: types.maybeNull(types.string),
    container_types: types.maybeNull(types.array(types.string)),
    contract_number: types.string,
    contract_name: types.maybeNull(types.string),
    contract_ref: types.maybeNull(types.string),
    contract_type: types.maybeNull(types.string),
    is_configured_by_weight: false, // from default it will infer boolean type
    remarks: types.maybeNull(types.string),
    status: types.maybeNull(types.enumeration(CONTRACT_STATUSES)),
    terms_and_conditions: types.maybeNull(types.string),
    terms_and_cond: types.maybe(types.maybeNull(Template)),
    tenant_id: types.maybe(types.maybeNull(types.string)),
    valid_from: types.maybeNull(types.number),
    valid_till: types.maybeNull(types.number),
    customer_companies: types.maybe(types.maybeNull(types.array(types.maybeNull(Company)))),
    supplier_company: types.maybe(types.maybeNull(Company)),
    vehicle_type: types.maybeNull(types.string),
    included_surcharges: types.maybeNull(types.array(types.string)),
    subject_to_surcharges: types.maybeNull(
      types.array(
        types.model({
          name: types.maybe(types.maybeNull(types.string)),
          basis: types.maybe(types.maybeNull(types.string)),
          tag: types.maybe(types.maybeNull(types.string)),
        })
      )
    ),
    air_contract_line_items: types.maybeNull(types.array(AirContractLineItem)),
    ocean_contract_line_items: types.maybeNull(types.array(OceanContractLineItem)),
    road_contract_line_items: types.maybeNull(types.array(RoadContractLineItem)),
    additional_charge_line_items: types.maybeNull(types.array(AdditionalChargeLineItem)),
    has_discounts: types.maybeNull(types.boolean),
    amended_to: types.maybeNull(types.late((): IAnyModelType => Contract)),
    amended_from_id: types.maybe(types.maybeNull(types.union(types.string, types.number))),
  })
  .actions((self) => ({
    isExpired() {
      if (self.status === EXPIRE_CONTRACT_STATUS) return true;
      else if (self.valid_till && self.valid_till < dayjs().unix()) return true;
      return false;
    },
  }));

export type ContractValue = Instance<typeof Contract>;
