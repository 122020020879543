import React from 'react';
import { PermissionValue, SessionDataValue } from 'common/models/SessionData';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import {
  CloseCircleOutlined,
  StopOutlined,
  ShareAltOutlined,
  TagsOutlined,
  TrophyOutlined,
  SwapOutlined,
  EditOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  performCreateInquiryAction,
  addCollaboratorsRenderer,
  performCompanyAction,
  performRenameAction,
} from './companyActionRenderer';
import { isCustomer, isLead } from 'common';
import { CustomIcon, hasPermission } from '@shipmnts/pixel-hub';
import {
  PERMISSION_CUSTOMER_CREATE_EDIT,
  PERMISSION_MERGE_RENAME_COMPANY,
  PERMISSION_SUPPLIER_CREATE_EDIT,
} from 'network/permissions';
import {
  ACTIVE_CUSTOMER,
  AGENT,
  CHURNED_CUSTOMER,
  CONTACT,
  CUSTOMER,
  LEAD,
  LEAD_STAGE,
  LOST_LEAD,
  SUPPLIER,
  WON_AGENT,
  WON_CUSTOMER,
  WON_SUPPLIER,
} from 'sales_hub/constants';

export const getCompanyActions = (
  company: any,
  sessionData: SessionDataValue,
  isDetailScreen: boolean
) => {
  const { company_type, company_stage } = company;
  const actions: Action[] = [];
  const { permissions, company_account } = sessionData;
  const isIMPEX = ['manufacturer', 'trader'].includes(company_account?.primary_business || '');
  const PERMISSIONS = {
    supplier: hasPermission(permissions as PermissionValue[], {
      name: PERMISSION_SUPPLIER_CREATE_EDIT,
      docType: 'Network::Company',
    }),
    customer: hasPermission(permissions as PermissionValue[], {
      name: PERMISSION_CUSTOMER_CREATE_EDIT,
      docType: 'Network::Company',
    }),
  };

  const has_agent_permission = PERMISSIONS['supplier'] && PERMISSIONS['customer'];

  const has_won_permission =
    (company_stage === WON_CUSTOMER && PERMISSIONS['customer']) ||
    (company_stage === WON_SUPPLIER && PERMISSIONS['supplier']) ||
    (company_stage === WON_AGENT && has_agent_permission);

  actions.push({
    key: 'lead_won',
    type: PRIMARY_TYPE,
    displayComponent: 'Won Lead',
    icon: <TrophyOutlined />,
    isEnable: isLead(company_type) && [LEAD_STAGE].includes(company_stage) && !company.is_disabled,
    extraProps: { action: 'won', title: 'Mark as Won' },
    performAction: performCompanyAction,
    description: 'win_company',
  });

  actions.push({
    key: 'active_customer',
    type: PRIMARY_TYPE,
    displayComponent: 'Active Customer',
    icon: <CustomIcon height="15" icon="FileLockIcon" width="15" />,
    isEnable:
      isCustomer(company_type) && company_stage === CHURNED_CUSTOMER && !company.is_disabled,
    extraProps: { action: ACTIVE_CUSTOMER, type: 'company_stage' },
    performAction: performCompanyAction,
    description: 'win_company',
  });

  actions.push({
    key: 'back_to_lead',
    type: PRIMARY_TYPE,
    displayComponent: 'Back to Lead',
    icon: <UndoOutlined />,
    // will be enabled, for type LEAD and stage LOST_LEAD, WON_CUSTOMER, WON_SUPPLIER, WON_AGENT with respective permissions
    isEnable:
      false &&
      isLead(company_type) &&
      (company_stage === LOST_LEAD || has_won_permission) &&
      !company.is_disabled,
    extraProps: { action: LEAD_STAGE, type: 'company_stage' },
    performAction: performCompanyAction,
    description: 'Convert Back to Lead Stage',
  });

  actions.push({
    key: 'enable',
    type: PRIMARY_TYPE,
    displayComponent: 'Enable',
    icon: <StopOutlined />,
    isEnable: company.is_disabled && !isDetailScreen,
    extraProps: { action: 'enable', type: 'is_disabled' },
    performAction: performCompanyAction,
  });

  actions.push({
    key: 'convert',
    type: PRIMARY_TYPE,
    displayComponent: 'Convert To',
    icon: <SwapOutlined />,
    isEnable: !company.is_disabled && company?.company_group !== 'Self',
    description: 'convert_company',
    childComponents: [
      ConvertLead(company),
      ConvertCustomer(company, PERMISSIONS['customer']),
      ConvertSupplier(company, PERMISSIONS['supplier']),
      ConvertContact(company),
      ConvertAgent(company, has_agent_permission),
    ],
  });

  actions.push({
    key: 'edit_lead',
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Lead',
    icon: <EditOutlined />,
    isEnable:
      isLead(company_type) &&
      (company_stage === LEAD_STAGE || has_won_permission) &&
      !company.is_disabled,
    extraProps: {
      action: 'edit',
      title: 'Edit Lead',
      type: [WON_CUSTOMER, WON_SUPPLIER, WON_AGENT].includes(company_stage) ? 'won' : 'lead',
    },
    performAction: performCompanyAction,
    description: 'edit_company',
  });

  actions.push({
    key: 'rename_company',
    type: PRIMARY_TYPE,
    displayComponent: 'Rename & Merge Company',
    icon: <EditOutlined />,
    isEnable:
      hasPermission(permissions as PermissionValue[], {
        AND: [
          {
            OR: [
              {
                name: PERMISSION_CUSTOMER_CREATE_EDIT,
                docType: 'Network::Company',
              },
              {
                name: PERMISSION_SUPPLIER_CREATE_EDIT,
                docType: 'Network::Company',
              },
            ],
          },
          { name: PERMISSION_MERGE_RENAME_COMPANY, docType: 'Network::Company' },
        ],
      }) &&
      !company.is_disabled &&
      company?.company_group !== 'Self',
    extraProps: { action: 'edit', title: 'Edit Lead' },
    performAction: performRenameAction,
    description: 'edit_company',
  });

  actions.push({
    key: 'create_inquiry',
    type: PRIMARY_TYPE,
    displayComponent: 'Create Inquiry',
    icon: <TagsOutlined />,
    isEnable:
      (isCustomer(company_type) || isLead(company_type)) && !company.is_disabled && !isIMPEX,
    extraProps: { resource_type: 'company' },
    performAction: performCreateInquiryAction,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    isEnable: !company.is_disabled && !isDetailScreen && company?.company_group !== 'Self',
    displayComponent: 'Add Collaborators',
    performAction: addCollaboratorsRenderer,
  });

  actions.push({
    key: 'lead_lost',
    type: NEGATIVE_TYPE,
    displayComponent: 'Mark as Lost',
    icon: <CloseCircleOutlined />,
    isEnable: isLead(company_type) && company_stage === LEAD_STAGE && !company.is_disabled,
    extraProps: { action: LOST_LEAD, title: 'Lost as Lead', type: 'form_action' },
    performAction: performCompanyAction,
  });

  actions.push({
    key: 'disable',
    type: NEGATIVE_TYPE,
    displayComponent: 'Disable',
    icon: <StopOutlined />,
    isEnable: !company.is_disabled && !isDetailScreen && company?.company_group !== 'Self',
    extraProps: { action: 'disable', type: 'is_disabled' },
    performAction: performCompanyAction,
  });

  actions.push({
    key: 'churned',
    type: NEGATIVE_TYPE,
    displayComponent: 'Mark as Churned',
    icon: <StopOutlined />,
    isEnable:
      isCustomer(company_type) && company_stage !== CHURNED_CUSTOMER && !company.is_disabled,
    extraProps: { action: CHURNED_CUSTOMER, type: 'company_stage' },
    performAction: performCompanyAction,
  });

  return actions;
};

function ConvertLead(company: any): Action {
  return {
    key: 'convert_lead',
    type: PRIMARY_TYPE,
    displayComponent: 'Lead',
    isEnable: company.company_type === CONTACT,
    extraProps: { action: LEAD, type: 'company_type', title: 'Convert To Lead' },
    performAction: performCompanyAction,
  };
}

function ConvertCustomer(company: any, permission: boolean): Action {
  return {
    key: 'convert_customer',
    type: PRIMARY_TYPE,
    displayComponent: 'Customer',
    isEnable:
      (company.company_type === CONTACT ||
        (company.company_type === LEAD &&
          [WON_CUSTOMER, WON_AGENT, WON_SUPPLIER].includes(company.company_stage))) &&
      permission,
    extraProps: { action: CUSTOMER, type: 'company_type', title: 'Convert To Customer' },
    performAction: performCompanyAction,
  };
}

function ConvertSupplier(company: any, permission: boolean): Action {
  return {
    key: 'convert_supplier',
    type: PRIMARY_TYPE,
    displayComponent: 'Supplier / Vendor',
    isEnable:
      (company.company_type === CONTACT ||
        (company.company_type === LEAD &&
          [WON_CUSTOMER, WON_AGENT, WON_SUPPLIER].includes(company.company_stage))) &&
      permission,
    extraProps: { action: SUPPLIER, type: 'company_type', title: 'Convert To Supplier/Vendor' },
    performAction: performCompanyAction,
  };
}

function ConvertContact(company: any): Action {
  return {
    key: 'convert_contact',
    type: PRIMARY_TYPE,
    displayComponent: 'Contact',
    isEnable: company.company_type === LEAD,
    extraProps: { action: CONTACT, type: 'company_type', title: 'Convert To Contact' },
    performAction: performCompanyAction,
  };
}

function ConvertAgent(company: any, permission: boolean): Action {
  return {
    key: 'convert_agent',
    type: PRIMARY_TYPE,
    displayComponent: 'Agent',
    isEnable:
      (company.company_type === CUSTOMER) !== (company.company_type === SUPPLIER) ||
      (company.company_type === LEAD &&
        [WON_CUSTOMER, WON_AGENT, WON_SUPPLIER].includes(company.company_stage) &&
        permission),
    extraProps: { action: AGENT, type: 'company_type', title: 'Convert To Agent' },
    performAction: performCompanyAction,
  };
}
