import React from 'react';
import { Collapse, Typography, Row, Col, Button, Form } from '@shipmnts/pixel-hub';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import { MinusOutlined } from '@shipmnts/pixel-hub';
import { GlobalSearch } from '@shipmnts/pixel-hub';

const { Panel } = Collapse;
const { Text } = Typography;

interface CompanyAccountsTableProps {
  account_type: 'receivable' | 'payable';
  erpnextConfigData: ERPNextConfigData;
}

const ROW_GUTTER = 32;

const CompanyAccountsTable = React.memo(function CompanyAccountsTable(
  props: CompanyAccountsTableProps
): JSX.Element {
  const { account_type, erpnextConfigData } = props;
  return (
    <Collapse accordion>
      <Panel header="Accounting" key="1">
        <Text type="secondary">Mention if non-standard {account_type} account</Text>
        <Form.List name={`${account_type}_accounts`}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Row style={{ alignItems: 'center' }} key={index} gutter={ROW_GUTTER}>
                      <Col span={10}>
                        <Form.Item
                          label={index === 0 ? 'Company' : ''}
                          required
                          rules={[{ required: true }]}
                          key={field.key}
                          name={[field.name, 'company']}
                        >
                          <GlobalSearch doc_type="ERP::Companies" />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label={index === 0 ? 'Account' : ''}
                          required
                          rules={[{ required: true }]}
                          key={field.key}
                          name={[field.name, 'account']}
                          style={{ width: '95%' }}
                        >
                          {account_type === 'receivable' ? (
                            <GlobalSearch doc_type="ERP::ReceivableAccounts" />
                          ) : (
                            <GlobalSearch doc_type="ERP::PayableAccounts" />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={index === 0 ? ' ' : undefined}>
                          <Button
                            danger
                            onClick={() => remove(index)}
                            icon={<MinusOutlined />}
                            size="small"
                            shape="circle"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  disabled={fields.length === erpnextConfigData.companies?.length}
                  size="small"
                  onClick={() =>
                    add({
                      company:
                        erpnextConfigData.companies &&
                        erpnextConfigData.companies[fields.length]?.name,
                    })
                  }
                >
                  Add Row
                </Button>
              </div>
            );
          }}
        </Form.List>
      </Panel>
    </Collapse>
  );
});

export default CompanyAccountsTable;
