import { gql } from '@apollo/client';
import { salesPersonFields } from 'operations/graphql/salesPerson';
import { oceanTransportOrderFields } from 'operations/modules/booking/graphql';
import { cargoFields } from 'operations/modules/booking/graphql/cargo';
import {
  containerRequestFields,
  containerSettingsFields,
} from 'operations/modules/booking/graphql/containerRequest';
import { shipmentContainerFields } from 'operations/modules/booking/graphql/shipmentContainer';

export const otoReportFields = gql`
  fragment otoReportFields on OceanTransportOrderReportObjectType {
    id
    status
    status_time_duration_in_words
    booking_number
    booking_type
    booking_date
    load_type
    remarks
    valid_till_date
    cancelled_by
    cancellation_reason
    cancellation_marked_by {
      id
      first_name
      last_name
    }
    is_amendment_pending
    last_amended_by {
      id
      first_name
      last_name
    }
    global_carrier {
      id
      name
      carrier_type
    }
    cargos {
      ...cargoFields
    }
    container_requests {
      ...containerRequestFields
    }
    shipment_containers {
      ...shipmentContainerFields
    }
    empty_pickup_location {
      id
      name
      unlocode
      city_code
      state_code
      country_code
    }
    port_of_loading {
      id
      name
    }
    port_of_discharge {
      id
      name
    }
    place_of_carrier_receipt {
      id
      name
    }
    place_of_carrier_delivery {
      id
      name
    }
    vessel {
      imo
      name
    }
    estimated_time_of_departure
    voyage_number
    containers_grouped_by_booking_request {
      booking_request_id
      ocean_transport_order_id
      shipment_containers {
        ...shipmentContainerFields
      }
    }
    gate_open_date
    gate_close_date
    created_at
    created_by {
      id
      first_name
      last_name
    }
    sales_person {
      ...salesPersonFields
    }
    vendor_company {
      id
      registered_name
    }
    booking_requests {
      id
      shipment_booking_number
      requested_at
      customer_company {
        id
        registered_name
      }
      sales_person {
        ...salesPersonFields
      }
      created_by {
        id
        first_name
        last_name
      }
      container_requests {
        id
        container_type
        quantity
        quantity_fulfilled
        quantity_unfulfilled
      }
    }
  }
  ${cargoFields}
  ${containerRequestFields}
  ${shipmentContainerFields}
  ${salesPersonFields}
  ${salesPersonFields}
`;

export const OCEAN_TRANSPORT_ORDER_REPORT = gql`
  query ocean_transport_order_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    ocean_transport_order_report(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      total
      data {
        ...otoReportFields
      }
    }
  }
  ${otoReportFields}
`;

export const ALLOCATE_OTO_REPORT = gql`
  query allocate_oto_report($allocate_oto_report: AllocateOtoReportInputType!) {
    allocate_oto_report(allocate_oto_report: $allocate_oto_report) {
      ...oceanTransportOrderFields
    }
  }
  ${oceanTransportOrderFields}
`;
export const OTO_DASHBOARD_REPORT = gql`
  query ocean_transport_order_dashboard_report(
    $limit: Int
    $offset: Int
    $filters: [FilterInputType!]
    $sort: [SortInputTypeNew!]
  ) {
    ocean_transport_order_dashboard_report(
      limit: $limit
      offset: $offset
      filters: $filters
      sort: $sort
    ) {
      total
      data {
        group_key
        id
        booking_number
        booking_date
        empty_pickup_location {
          id
          name
        }
        estimated_time_of_departure
        quantity_requested
        quantity_received
        quantity_confirmed
        quantity_expired
        quantity_allocated
        quantity_allocation_pending
        quantity_picked_up
        quantity_pick_up_pending
        quantity_origin_port_gate_in_pending
        quantity_origin_port_gated_in
        quantity_loading_pending
        quantity_loaded_on_vessel
        quantity_shutout
        quantity_offloaded
        quantity_gate_pass_pending
        quantity_gate_pass_confirmed
        container_type
        container_type_code
        container_settings {
          ...containerSettingsFields
        }
        cargos {
          id
          product_name
        }
        port_of_loading {
          id
          name
        }
        port_of_discharge {
          id
          name
        }
        place_of_carrier_receipt {
          id
          name
        }
        place_of_carrier_delivery {
          id
          name
        }
        vessel {
          imo
          name
        }
        voyage_number
        global_carrier {
          id
          name
        }
        created_by {
          id
          first_name
          last_name
        }
        customer_companies {
          id
          registered_name
        }
      }
    }
  }
  ${containerSettingsFields}
`;
