import React, { useState } from 'react';
import {
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  SearchDocTypeEditor,
  RecordLoaderRender,
  Button,
  Form,
  BaseTable,
} from '@shipmnts/pixel-hub';
import { Column, VehicleLoadSearch } from 'common';
import { DISABLE_CELL_STYLE, RequireHeaderWrapper } from '../../ShipmentForm/helpers';
import {
  DIMENSION_CBM,
  LOAD_TYPE_FCL,
  LOAD_TYPE_FTL_BREAK_BULK,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_MTS,
} from 'operations/baseConstants';
import { GridOptions, NewValueParams, ValueSetterParams } from '@ag-grid-community/core';
import allFields from '../../ShipmentForm/allFields';
import { QtyUomTypeRenderer } from 'common';
import CargoProperties from 'operations/components/CargoProperties';
import {
  CellClassParams,
  EditableCallbackParams,
  RowNode,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { getRoundOffValue } from '../../ShipmentForm/CargoTableForm';
import { calculateVolumetricWeight } from 'sales_hub';
import { CargoPropertyValue } from 'operations/models/Cargo';
import { LRSuggestionType } from '../../ShipmentForm/assignLoadHelpers';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { ERROR_CELL_STYLE } from 'operations/modules/shipment/helpers/FormHelpers';

export interface CargoData extends ProductOrderItemValue {
  link_order_no?: string | null;
  is_single_load?: boolean | null;
  lr_number?: string | null;
  allocated_qty?: number | null;
}

interface CargoSelectionProps {
  cargos: any[];
  gridRef?: React.MutableRefObject<GridOptions<any> | undefined>;
  loadType?: string;
  onlyVehicleInput?: boolean;
  tripOrderNo?: string[];
  freightType?: string;
  isMatchingSelected?: boolean;
  setCargos: React.Dispatch<React.SetStateAction<any[]>>;
  isOrderAssignment?: boolean;
  isAddNewHouse?: boolean;
  lrSuggestions?: LRSuggestionType[];
  isAutoGenerateLR?: boolean;
}

type Props = {
  rowIndex: number;
  freightType: string;
  node?: RowNode<any>;
};

function NewCargoProperties({ rowIndex, freightType, node }: Props) {
  if (node && node.isRowPinned()) return <></>;
  const isDisabled = node?.data?.id;

  return (
    <Form.Item name={['cargos', rowIndex, 'cargo_properties']}>
      <CargoProperties formKey={rowIndex} freightType={freightType} disabled={isDisabled} />
    </Form.Item>
  );
}

export default function CargoSelection(props: CargoSelectionProps) {
  const {
    cargos,
    gridRef,
    loadType,
    onlyVehicleInput,
    tripOrderNo,
    isMatchingSelected,
    setCargos,
    isOrderAssignment = false,
    isAddNewHouse = false,
    lrSuggestions = [],
    isAutoGenerateLR = false,
  } = props;

  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const getDefaultRowData = () => {
    const lastIndex = gridRef?.current?.api?.getLastDisplayedRow();
    if (lastIndex !== undefined) {
      const lastCargo = gridRef?.current?.api?.getDisplayedRowAtIndex(lastIndex)?.data;
      return {
        outer_package_type: lastCargo?.outer_package_type || 'Box',
        weight_unit: lastCargo?.weight_unit || WEIGHT_UNIT_MTS,
        volume_unit: lastCargo?.volume_unit || VOLUME_UNIT_CBM,
        product_name: lastCargo?.product_name || '',
      };
    } else {
      return {
        outer_package_type: 'Box',
        weight_unit: WEIGHT_UNIT_MTS,
        volume_unit: VOLUME_UNIT_CBM,
        product_name: '',
      };
    }
  };

  const isEditable = (params: EditableCallbackParams<any>) => {
    const fieldName = params.colDef?.field || '';
    if (params?.node?.isRowPinned()) return false;
    if (['allocated_qty', 'allocated_gross_weight'].includes(fieldName)) {
      if (isAddNewHouse) {
        if (
          loadType === LOAD_TYPE_FTL_BREAK_BULK &&
          params.data.outer_package_qty === 1 &&
          params.data.is_single_load
        )
          return false;
        if (!params.node.isSelected()) return true; // either select the row or add allocate_qty
      } else return true;
    }
    if (fieldName === 'lr_number') {
      if (isAddNewHouse || isAutoGenerateLR) return false;
      else return true;
    }
    if (['gross_weight', 'net_weight'].includes(fieldName)) {
      if (loadType === LOAD_TYPE_FTL_BREAK_BULK) return true;
      else return false;
    }

    const cargoIndex = params?.node?.rowIndex;
    if (typeof cargoIndex === 'number' && cargoIndex < cargos.length && cargos[cargoIndex].id)
      return false;
    if (
      ['weight_unit', 'gross_volume', 'outer_package_type', 'outer_package_qty'].includes(
        fieldName
      ) &&
      typeof cargoIndex === 'number' &&
      cargoIndex < cargos.length &&
      cargos[cargoIndex].shipment_packages?.length
    )
      return false;

    return true;
  };

  const cellStyle = (params: CellClassParams<any, any>) => {
    if (params?.node?.isRowPinned()) return;
    if (!isEditable(params)) return DISABLE_CELL_STYLE;
    return;
  };

  const valueGetterPackageField = (params: ValueGetterParams<any>) => {
    const cargoIndex = params?.node?.rowIndex;
    const fieldName = params.colDef?.field || '';
    if (typeof cargoIndex === 'number' && cargoIndex < cargos.length && cargos[cargoIndex]) {
      return cargos[cargoIndex][fieldName];
    }
  };

  const isLoose = loadType !== LOAD_TYPE_FCL;

  const columnDefs: Column[] = [];

  if (isMatchingSelected) {
    columnDefs.push(allFields.link_order_no({ cellStyle: DISABLE_CELL_STYLE }));
  }
  if (isOrderAssignment) {
    columnDefs.push(
      allFields.vehicle_load_search({
        headerComponent: () => <RequireHeaderWrapper text="Assign Trip / Vehicle" />,
        pinned: 'left',
        tripOrderNo,
      })
    );
  }
  if ((isAddNewHouse || !isOrderAssignment) && lrSuggestions.length) {
    columnDefs.push(
      allFields.lr_search({
        lrSuggestions: lrSuggestions,
        editable: isEditable,
        cellStyle: cellStyle,
      })
    );
  }
  columnDefs.push(
    loadType === LOAD_TYPE_FTL_BREAK_BULK
      ? allFields.allocated_qty({
          editable: isEditable,
          onCellValueChanged: (params: NewValueParams<any>) => {
            if (!params?.data?.id) {
              params.data.outer_package_qty = params?.newValue;
              if (!params.node) return;
              params.api.refreshCells({
                force: true,
                columns: ['outer_package_qty'],
                rowNodes: [params?.node],
              });
            }
          },
        })
      : allFields.allocated_gross_weight({
          editable: isEditable,
          onCellValueChanged: (params: NewValueParams<any>) => {
            if (!params?.data?.id) {
              params.data.gross_weight = params?.newValue;
              params.data.net_weight = params?.newValue;
              if (!params.node) return;
              params.api.refreshCells({
                force: true,
                columns: ['gross_weight', 'net_weight'],
                rowNodes: [params?.node],
              });
            }
          },
        }),

    loadType === LOAD_TYPE_FTL_BREAK_BULK
      ? allFields.allocation_pending_quantity({ isBreakBulk: true })
      : allFields.allocation_pending_quantity()
  );
  // editable form
  columnDefs.push(
    allFields.invoice_number({
      editable: false,
      cellStyle: DISABLE_CELL_STYLE,
      floatingFilter: true,
    }),
    allFields.invoice_date({
      editable: false,
      cellStyle: DISABLE_CELL_STYLE,
    }),
    allFields.serial_number({
      editable: isEditable,
      cellStyle,
    }),

    allFields.gross_weight({
      headerComponent:
        loadType !== LOAD_TYPE_FTL_BREAK_BULK && loadType !== LOAD_TYPE_FCL
          ? () => <RequireHeaderWrapper text="Gross Wt." />
          : undefined,
      editable: isEditable,
      valueGetter: valueGetterPackageField,
      cellStyle: (params: CellClassParams<any, any>) => {
        const disabledStyle = cellStyle(params);
        if (disabledStyle) return disabledStyle;
        const error = params.data?.errors?.gross_weight;
        if (error) return ERROR_CELL_STYLE;
        return null;
      },
    }),
    allFields.net_weight({
      valueGetter: (params: { data: { net_weight: string } }) => {
        return params.data?.net_weight ? parseFloat(params.data?.net_weight) : 0;
      },
      editable: isEditable,
      cellStyle: cellStyle,
    }),
    allFields.weight_unit({
      headerComponent: () => <RequireHeaderWrapper text="Weight Unit" />,
      editable: isEditable,
      cellStyle: cellStyle,
      valueGetter: (params: ValueGetterParams<any>) => {
        const value = valueGetterPackageField(params);
        if (!value) return WEIGHT_UNIT_MTS;
        return value;
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.weight_unit = params.newValue || WEIGHT_UNIT_MTS;
        return true;
      },
    }),
    allFields.product_name({
      headerComponent: () => <RequireHeaderWrapper text="Cargo Description" />,
      editable: isEditable,
      cellStyle,
    })
  );
  if (loadType === LOAD_TYPE_FTL_BREAK_BULK) {
    columnDefs.push(
      allFields.outer_package_qty({
        editable: isEditable,
        headerComponent:
          loadType === LOAD_TYPE_FTL_BREAK_BULK
            ? () => <RequireHeaderWrapper text="Qty" />
            : undefined,
        valueGetter: valueGetterPackageField,
        cellStyle: (params: CellClassParams<any, any>) => {
          const disabledStyle = cellStyle(params);
          if (disabledStyle) return disabledStyle;
          const error = params.data?.errors?.outer_package_qty;
          if (error) return ERROR_CELL_STYLE;
          return null;
        },
      }),
      allFields.outer_package_type({
        headerComponent:
          loadType === LOAD_TYPE_FTL_BREAK_BULK
            ? () => <RequireHeaderWrapper text="Package Type" />
            : undefined,
        editable: isEditable,
        valueGetter: valueGetterPackageField,
        cellStyle: (params: CellClassParams<any, any>) => {
          const disabledStyle = cellStyle(params);
          if (disabledStyle) return disabledStyle;
          const error = params.data?.errors?.outer_package_type;
          if (error) return ERROR_CELL_STYLE;
          return null;
        },
      })
    );
  }
  columnDefs.push(
    allFields.gross_volume({
      editable: (params: EditableCallbackParams<any>) => {
        if (isLoose) return isEditable(params);
        else return false;
      },
      cellStyle: (params: CellClassParams<any, any>) => {
        if (isLoose) return cellStyle(params);
        else return DISABLE_CELL_STYLE;
      },
      valueGetter: valueGetterPackageField,
    }),
    allFields.volumetric_wt({
      editable: isEditable,
      cellStyle: cellStyle,
      cellRendererSelector: (params: {
        node: { rowIndex: any };
        data: { volume_unit: any; weight_unit: any };
      }) => {
        let volumetricWeight = 0;
        const cargoIndex = params?.node?.rowIndex;
        if (typeof cargoIndex === 'number' && cargoIndex < cargos.length && cargos[cargoIndex])
          volumetricWeight = cargos[cargoIndex]['gross_volume'];
        volumetricWeight = getRoundOffValue(
          calculateVolumetricWeight({
            volume: volumetricWeight,
            volumeUnit: params?.data?.volume_unit || DIMENSION_CBM,
            weightUnit: params?.data?.weight_unit || WEIGHT_UNIT_MTS,
            factor: 6000,
          })
        );
        return {
          component: 'QtyUomTypeRenderer',
          params: {
            qty: volumetricWeight,
            uom: params?.data?.weight_unit,
          },
        };
      },
    }),

    allFields.batch_number({
      editable: isEditable,
      cellStyle: cellStyle,
    }),
    allFields.custom_ref({
      editable: isEditable,
      cellStyle: cellStyle,
    }),
    allFields.eway_bill_no({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.eway_bill_validity({
      editable: (params: { node: { isRowPinned: () => any } }) => !params.node.isRowPinned(),
    }),
    allFields.cargo_properties({
      editable: isEditable,
      cellRendererParams: (params: any) => {
        return {
          freightType: props.freightType,
          onChange: (value: CargoPropertyValue) => {
            const index = params?.node?.rowIndex;
            const updatedCargos = cargos;
            updatedCargos[index] = { ...updatedCargos[index], cargo_properties: value };
            setCargos(updatedCargos);
          },
        };
      },
      valueGetter: (params: { node: { isRowPinned: () => any } }) => {
        if (!params?.node?.isRowPinned()) return false;
        return true;
      },
      cellStyle: cellStyle,
    })

    // allFields?.add_package({
    //   cellStyle: cellStyle,
    //   editable: isEditable,
    //   cellRendererParams: {
    //     cargos: cargos,
    //     setCargos: setCargos,
    //   },
    // })
  );

  if (!onlyVehicleInput) {
    columnDefs.push(
      allFields.driver_details(),
      allFields.lr_number(),
      allFields.lr_date(),
      allFields.vehicle_type(),
      allFields.transporter()
    );
  }

  const components = {
    DateEditor,
    StringEditor,
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    RecordLoaderRender,
    QtyUomTypeRenderer,
    CargoProperties: NewCargoProperties,
    VehicleLoadSearch,
  };

  return (
    <>
      <Form.Item name="cargos">
        <BaseTable
          reportName={`cargo_selection_order_${lrSuggestions.length}_${isOrderAssignment}_${isAddNewHouse}`}
          columns={columnDefs}
          gridRef={gridRef}
          rowData={cargos}
          rowSelection="multiple"
          allowProcessDataFromClipboard={true}
          newRowOnPaste={true}
          onGridReady={(grid: any) => {
            gridRef?.current?.api?.startEditingCell({
              rowIndex: 0,
              colKey: 'assign_vehicle_load',
            });
            grid?.columnApi?.autoSizeAllColumns();
          }}
          reportConfig={{
            floatingFiltersHeight: 35,
            components: components,
            suppressLastEmptyLineOnPaste: true,
            isRowSelectable: (node: any) => {
              if (!node.data?.id) return true;
              return isAddNewHouse && !node.data.is_single_load;
            },
            rowHeight: 40,
            suppressClipboardApi: true,
            defaultColDef: {
              editable: true,
              flex: 1,
              minWidth: 100,
              resizable: true,
            },
            onRowSelected: (event: any) => {
              const rowsSelected = gridRef?.current?.api?.getSelectedRows();
              setSelectedRowsCount(rowsSelected?.length || 0);
              if (isAddNewHouse) {
                if (loadType === LOAD_TYPE_FTL_BREAK_BULK)
                  event.node.data.allocated_qty =
                    event.node.data.allocated_qty || event.node.data?.allocation_pending_quantity;
                else
                  event.node.data.allocated_gross_weight =
                    event.node.data.allocated_gross_weight ||
                    event.node.data?.allocation_pending_quantity;
              }
            },
            onRowDataUpdated: (params: any) => {
              const updatedRows: any[] = [];
              params.api.forEachNode((rowNode: any) => {
                updatedRows.push(rowNode.data);
              });
              if (setCargos && updatedRows.length) setCargos(updatedRows);
            },
            enableRangeSelection: true,
            stopEditingWhenCellsLoseFocus: true,
          }}
          checkbox_always_visible={isAddNewHouse ? true : false}
        />

        {isOrderAssignment && !isMatchingSelected && (
          <div style={{ marginTop: '10px', display: 'flex' }}>
            <Button
              size="small"
              style={{ marginRight: '16px' }}
              onClick={() => {
                gridRef?.current?.api?.applyTransaction({ add: [{ ...getDefaultRowData() }] });
              }}
            >
              {' '}
              Add Cargo
            </Button>
            <Button
              danger
              disabled={selectedRowsCount === 0}
              size="small"
              onClick={() => {
                const sel = gridRef?.current?.api?.getSelectedRows();
                gridRef?.current?.api?.applyTransaction({ remove: sel });
                setSelectedRowsCount(0);
              }}
            >
              {' '}
              Delete Cargo
            </Button>
          </div>
        )}
      </Form.Item>
    </>
  );
}
