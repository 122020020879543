import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { CreateBRFormValue } from 'operations/modules/reports/components/OTOReports/CreateBookingRequestFromOTO';
import { CreateOTOFormValue } from 'operations/modules/reports/components/BookingRequestReports/CreateBookingOrderFromBR';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
import { pick as _pick, get as _get } from 'lodash';
import { BOOKING_TYPE_VENDOR, LOAD_TYPE_FCL } from 'operations/baseConstants';
import { STATUS_CONFIRMED } from '../constants';
import {
  SERVICE_TYPE_PORT_TO_PORT,
  TRADE_TYPE_IMPORT,
} from 'operations/modules/shipment/constants';
import { dayjs } from '@shipmnts/pixel-hub';
import { FREIGHT_TYPE_OCEAN } from 'operations/utils/constants';

export interface ContainerRequestPayload {
  id: string;
  count: number;
}

export interface RowRequestPayload {
  id: string;
  container_requests: Array<ContainerRequestPayload>;
}

export interface AllocationPayload {
  [key: string]: string | Array<RowRequestPayload>;
}

export const validateAllocatedValues = (
  unfulfilled_container_requests_parent: Array<ContainerRequestValue>,
  values: Record<string, Record<string, Record<string, string>>>,
  quantity_field: 'quantity' | 'quantity_unfulfilled' // Used in showing error
): string => {
  // Parent is from which we are doing allocation. Summary of Allocation Report
  const totalAllocatedValuesGroupByParent = unfulfilled_container_requests_parent.reduce(
    (result: { [key: string]: number }, cr: ContainerRequestValue) => {
      if (cr.id) result[cr.id] = 0;
      return result;
    },
    {}
  );
  //row is for which we are doing allocation. Rows of allocation report
  Object.values(values).forEach((row: Record<string, Record<string, string>>) => {
    Object.keys(row).forEach((parent_cr_id: string) => {
      const counter_count = parseInt(Object.values(row[parent_cr_id])[0] || '0');
      totalAllocatedValuesGroupByParent[parent_cr_id] =
        (totalAllocatedValuesGroupByParent[parent_cr_id] || 0) + (counter_count || 0);
    });
  });

  let error = '',
    noAllocation = true;

  unfulfilled_container_requests_parent.forEach((cr: ContainerRequestValue) => {
    if (cr.id && totalAllocatedValuesGroupByParent[cr.id]) noAllocation = false;

    if (cr.id && (cr[quantity_field] || 0) < totalAllocatedValuesGroupByParent[cr.id]) {
      const containerSetting = renderContainerSettings([cr])
        ? `( ${renderContainerSettings([cr])} )`
        : '';
      error += `Allocated total quantity for Container type ${
        cr.container_type
      }${containerSetting} is ${
        totalAllocatedValuesGroupByParent[cr.id]
      } that is higher than available total quantity (${cr[quantity_field]}) for this booking. `;
    }
  });

  if (noAllocation) error = "You haven't allocated any container. Please allocate";

  return error;
};

export const getAllocatePayload = (
  parent_id: string,
  values: Record<string, Record<string, Record<string, string>>>,
  parentKey: string, // 'ocean_transport_order_id' | 'booking_request_id';
  rowsKey: string //  'booking_requests' | 'ocean_transport_orders';
): AllocationPayload => {
  const rows_requests: Array<RowRequestPayload> = [];

  Object.keys(values).forEach((row_request_id: string) => {
    const container_requests = [] as Array<ContainerRequestPayload>;

    Object.values(values[row_request_id]).forEach((cr_object: Record<string, string>) => {
      const container_request_id = Object.keys(cr_object)[0];
      const allocated_quantity = parseInt(Object.values(cr_object)[0]);
      if (allocated_quantity)
        container_requests.push({ id: container_request_id, count: allocated_quantity });
    });

    if (container_requests.length > 0)
      rows_requests.push({ id: row_request_id, container_requests });
  });

  const payload = { [parentKey]: parent_id, [rowsKey]: rows_requests };

  return payload;
};

export const getNewOtoPayload = (
  values: Record<string, any>,
  clearanceShipment?: boolean,
  masterShipmentId?: string | null
) => {
  let createOtoPayload = true;
  if (
    values?.type !== 'update' &&
    !values?.booking_type &&
    values?.trade_type === 'export' &&
    values.load_type === LOAD_TYPE_FCL
  ) {
    createOtoPayload = false;
  }
  const freightForwarding = values.services?.freight_forwarding;
  const nonFreightForwardingShipment = !freightForwarding;

  if (masterShipmentId) createOtoPayload = false;

  if ((clearanceShipment || nonFreightForwardingShipment) && values?.shipment_type) {
    createOtoPayload = values?.freight_type === FREIGHT_TYPE_OCEAN ? true : false;
  }

  if (!createOtoPayload) return;
  const vendor_is_origin_agent =
    values?.load_type === LOAD_TYPE_FCL && values?.trade_type === TRADE_TYPE_IMPORT
      ? _get(values, ['party', 'origin_agent', 'party_company', 'id'], null)
      : undefined;

  const gate_open_date = values?.gate_open_date;
  const gate_close_date = values?.gate_close_date;
  const valid_till_date = values?.valid_till_date;
  const doc_cutoff_date = values?.doc_cutoff_date;
  const vgm_cutoff_date = values?.vgm_cutoff_date;
  const si_cutoff_date = values?.si_cutoff_date;
  const form13_cutoff_date = values?.form13_cutoff_date;
  const booking_date = values?.booking_date;
  const ocean_transport_order = {
    ..._pick(values, ['booking_type', 'booking_number', 'contract_number']),
  } as Record<string, any>;
  ocean_transport_order.booking_type = ocean_transport_order?.booking_type || BOOKING_TYPE_VENDOR;
  ocean_transport_order.global_carrier_id = _get(values, ['carrier', 'id'], null);
  ocean_transport_order.vendor_company_id =
    _get(values, ['vendor', 'party_company', 'id'], null) || vendor_is_origin_agent;
  ocean_transport_order.vendor_address_id = _get(values, ['vendor', 'party_address', 'id'], null);
  ocean_transport_order.surveyor_company_id = _get(
    values,
    ['surveyor', 'party_company', 'id'],
    null
  );
  ocean_transport_order.surveyor_address_id = _get(
    values,
    ['surveyor', 'party_address', 'id'],
    null
  );
  ocean_transport_order.sales_person_id =
    _get(values, ['sales_person', 'id'], null) || _get(values, ['sales_agent', 'id'], null);
  ocean_transport_order.customer_company_id =
    _get(values, ['booking_party', 'party_company', 'id'], null) ||
    _get(values, ['sessionData', 'company_account', 'default_company', 'id']);
  ocean_transport_order.customer_address_id = _get(
    values,
    ['booking_party', 'party_address', 'id'],
    null
  );
  ocean_transport_order.empty_pickup_location_id = _get(
    values,
    ['empty_pickup_location', 'id'],
    null
  );
  ocean_transport_order.empty_return_location_id = _get(
    values,
    ['empty_return_location', 'id'],
    null
  );
  ocean_transport_order.service_type = values?.service_type || SERVICE_TYPE_PORT_TO_PORT;
  ocean_transport_order.load_type = values.load_type ?? 'fcl';
  ocean_transport_order.status = STATUS_CONFIRMED;
  ocean_transport_order.booking_date = (booking_date && booking_date.unix()) || dayjs().unix();
  ocean_transport_order.si_cutoff_date = si_cutoff_date && si_cutoff_date.unix();
  ocean_transport_order.form13_cutoff_date = form13_cutoff_date && form13_cutoff_date.unix();
  ocean_transport_order.vgm_cutoff_date = vgm_cutoff_date && vgm_cutoff_date.unix();
  ocean_transport_order.gate_open_date = gate_open_date && gate_open_date.unix();
  ocean_transport_order.gate_close_date = gate_close_date && gate_close_date.unix();
  ocean_transport_order.valid_till_date =
    valid_till_date && dayjs(valid_till_date).endOf('day').unix();
  ocean_transport_order.doc_cutoff_date = doc_cutoff_date && doc_cutoff_date.unix();
  ocean_transport_order.terms_and_condition_description = values.terms_and_condition_description;
  ocean_transport_order.terms_and_condition_id = values.terms_and_condition?.id;
  ocean_transport_order.origin_demurrage_free_days = _get(
    values,
    ['origin_demurrage_free_days'],
    null
  );
  ocean_transport_order.origin_detention_free_days = _get(
    values,
    ['origin_detention_free_days'],
    null
  );
  ocean_transport_order.destination_demurrage_free_days = _get(
    values,
    ['destination_demurrage_free_days'],
    null
  );
  ocean_transport_order.destination_detention_free_days = _get(
    values,
    ['destination_detention_free_days'],
    null
  );
  ocean_transport_order.voyage_schedule_id = _get(values, ['voyage_schedule_id'], null);
  return ocean_transport_order;
};

export const getAllocationPayloadNew = (
  values: Record<string, Record<string, Record<string, string>>>
) => {
  const rows_requests: Array<RowRequestPayload> = [];
  if (values === undefined) return;
  Object.keys(values)?.forEach((row_request_id: string) => {
    const container_requests = [] as Array<ContainerRequestPayload>;
    Object.values(values[row_request_id]).forEach((cr_object: Record<string, string>) => {
      const container_request_id = Object.keys(cr_object)[0];
      const allocated_quantity = parseInt(Object.values(cr_object)[0]);
      if (allocated_quantity)
        container_requests.push({ id: container_request_id, count: allocated_quantity });
    });

    if (container_requests.length > 0)
      rows_requests.push({ id: row_request_id, container_requests });
  });

  return rows_requests;
};

export const validateContainerRequests = (values: CreateBRFormValue | CreateOTOFormValue) => {
  const container_requests = values?.container_requests;
  let noContainerAllocated = true;

  if (container_requests) {
    Object.keys(container_requests).forEach((key: string) => {
      if (container_requests[key]) noContainerAllocated = false;
    });
  }

  return noContainerAllocated;
};
