import {
  COMMODITY_MAP,
  COMPANY_STAGE_MAP,
  COMPANY_TYPE_MAP,
  CUSTOMER_COMPANY_TYPES,
  LEAD_COMPANY_TYPES,
  VENDOR_COMPANY_TYPES,
} from './baseConstants';
import { erpnextApis } from 'network';

export const shorthandServiceMap = [
  'Freight Forwarding',
  'Origin Clearance',
  'Destination Clearance',
  'Origin Transport',
  'Origin Custom Clearance',
  'Destination Custom Clearance',
  'Destination Transport',
];
export function renderShorthandServices(service: any) {
  if (shorthandServiceMap.includes(service)) {
    return service.match(/[A-Z]/g).join('');
  }
  return service;
}

export const getCommodityType = (
  commodityProperty: Record<string, boolean>,
  commodityType: string[]
) => {
  if (!commodityProperty) return [];

  const keys = Object.keys(commodityProperty);
  keys.forEach((key: string) => {
    if (
      commodityProperty[key] === true &&
      COMMODITY_MAP?.[key] &&
      !commodityType.includes(COMMODITY_MAP[key])
    )
      commodityType.push(COMMODITY_MAP[key]);
  });

  return commodityType;
};

export const getCommodityTypeFromCargo = (commodityProperties: any) => {
  if (!commodityProperties) return [];
  if (commodityProperties.length === 0) return [];
  const commodityType: string[] = [];
  commodityProperties.forEach((ele: Record<string, boolean>) => {
    getCommodityType(ele, commodityType);
  });
  return commodityType;
};

export const getExchangeRate = async (
  currency: string | undefined | null,
  defaultCurrency: string
) => {
  if (currency === defaultCurrency || !currency || !defaultCurrency) {
    return 1;
  } else {
    const data = await erpnextApis.fetchExchangeRate(currency, defaultCurrency);
    return data?.data?.message;
  }
};

export const companyTypeCheck = (company_type: string | string[], types: string[]) => {
  if (!Array.isArray(company_type)) {
    const companyType = company_type.split('::')?.at(-1);
    return types.includes(companyType || '');
  }
  return company_type.some((type) => {
    const companyTypes = type.split('::').at(-1);
    return types.includes(companyTypes || '');
  });
};

export const isVendor = (company_type?: string | string[]) =>
  !!(company_type && companyTypeCheck(company_type, VENDOR_COMPANY_TYPES));

export const isLead = (company_type?: string | string[]) =>
  !!(company_type && companyTypeCheck(company_type, LEAD_COMPANY_TYPES));

export const isCustomer = (company_type?: string | string[]) =>
  !!(company_type && companyTypeCheck(company_type, CUSTOMER_COMPANY_TYPES));

// to get the next type of company (based on changes + previous type)
export const getCompanyType = (
  is_customer?: boolean | null,
  is_vendor?: boolean | null,
  is_lead?: boolean | null,
  prevType?: string | null
) => {
  if (is_customer) is_lead = true;
  if (!!prevType) {
    const prevValues = COMPANY_TYPE_MAP[prevType];
    is_customer = is_customer || (is_customer === false ? is_customer : !!prevValues.is_customer);
    is_lead = is_lead || (is_lead === false ? is_lead : !!prevValues.is_lead);
    is_vendor = is_vendor || (is_vendor === false ? is_vendor : !!prevValues.is_vendor);
  }

  // lead to spupplier
  if (is_lead && !is_customer && is_vendor) is_lead = false;

  const type = Object.keys(COMPANY_TYPE_MAP).filter((type) => {
    const val = COMPANY_TYPE_MAP[type];
    return (
      val.is_customer === !!is_customer &&
      val.is_vendor === !!is_vendor &&
      val.is_lead === !!is_lead
    );
  });

  return type[0];
};

export const getCompanyStage = (oldCompanyType?: string | null, newCompanyType?: string | null) => {
  if (!newCompanyType) return oldCompanyType;
  return COMPANY_STAGE_MAP.find((cs) => cs.to === newCompanyType && cs.from === oldCompanyType)
    ?.stage;
};

export const getCompanyTypes = (filter: {
  is_customer?: boolean;
  is_vendor?: boolean;
  is_lead?: boolean;
}) => {
  const keys = Object.keys(filter) as (keyof typeof filter)[];
  const filterKeys = keys.filter((key) => filter[key] || filter[key] === false);

  const company_types = Object.keys(COMPANY_TYPE_MAP).filter((type) => {
    const val = COMPANY_TYPE_MAP[type];
    return filterKeys.every((key) => filter[key] === val[key]);
  });

  return company_types.map((type) => `Network::CompanyType::${type}`);
};
