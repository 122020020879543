/**
  PURPOSE: Defining a model for Road Contract Line Items.
  
  CONFIRM: We won't access contract from line item? (at least in frontend)
*/

import Location from 'common/models/Location';
import { getSnapshot, Instance, types } from 'mobx-state-tree';
import { ContractCharge } from './ContractCharge';
import { WeightSlabType } from '../types';
import { WEIGHT_UNIT_MTS } from 'common/baseConstants';

const RoadContractLineItem = types
  .model({
    id: types.maybe(types.identifier),
    is_empty: false,
    port_of_loading: types.maybeNull(Location),
    port_of_discharge: types.maybeNull(Location),
    return_location: types.maybeNull(Location),
    currency: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    _destroy: types.optional(types.boolean, false),
    container_type: types.maybe(types.maybeNull(types.string)), // adding for ag grid (not part of Payload)
    weightUnit: types.maybe(types.maybeNull(types.string)), // adding for ag grid (not part of Payload)
  })
  .actions((self) => ({
    setCurrency: (value: string) => (self.currency = value),
    setIsEmpty: (value: boolean) => (self.is_empty = value),
    setDestroy: () => (self._destroy = true),
    updateContainerType: (value: string) =>
      self.contract_charges?.forEach((charge) => charge.setContainerType(value)),
    setLocation: (
      type: 'port_of_loading' | 'port_of_discharge' | 'return_location',
      value: Instance<typeof Location>
    ) => {
      if (!value) {
        self[type] = null;
      } else {
        value.id = String(value.id);
        self[type] = value;
      }
      return true;
    },
    updateChargesFromSlab: (
      value: number,
      weightSlab: WeightSlabType,
      weightUnit: string = WEIGHT_UNIT_MTS
    ) => {
      const contractCharge = self.contract_charges?.find(
        (charge) =>
          charge.start_weight === weightSlab.start_weight &&
          charge.end_weight === weightSlab.end_weight
      );

      if (!contractCharge) {
        return self.contract_charges?.push(
          ContractCharge.create({
            id: `new_${self?.contract_charges?.length}`,
            start_weight: weightSlab.start_weight,
            end_weight: weightSlab.end_weight,
            charge_amount: value,
            container_type: self.container_type,
            weight_unit: weightUnit,
          })
        );
      }

      return contractCharge?.setChargeValue(value);
    },
    updateChargesFromContainer: (value: number, containerType: string) => {
      const contractCharge = self.contract_charges?.find(
        (charge) => charge.container_type === containerType
      );

      if (!contractCharge) {
        return self.contract_charges.push(
          ContractCharge.create({
            id: 'new',
            container_type: containerType,
            charge_amount: value,
            start_weight: 0,
            end_weight: null,
          })
        );
      }

      return contractCharge?.setChargeValue(value);
    },
    setContainerType: (containerType: string | null) => {
      if (self.contract_charges && containerType)
        self.contract_charges?.forEach((charge) => charge.setContainerType(containerType));

      self.container_type = containerType;
      return true;
    },
    setChargeUnits: (weightUnit: string) => {
      self.weightUnit = weightUnit;
      self.contract_charges?.forEach((charge) => charge.setValue('weight_unit', weightUnit));
    },
    createChargeForSlab: (slab: WeightSlabType) => {
      self.contract_charges.push(
        ContractCharge.create({
          id: 'new',
          start_weight: slab.start_weight,
          end_weight: slab.end_weight,
          charge_amount: null,
          container_type: self.container_type,
          weight_unit: self.weightUnit || WEIGHT_UNIT_MTS,
        })
      );
    },
    createChargeForContainerType: (containerType: string) => {
      self.contract_charges.push(
        ContractCharge.create({
          id: 'new',
          container_type: containerType,
          charge_amount: null,
          start_weight: 0,
          end_weight: null,
          weight_unit: self.weightUnit || WEIGHT_UNIT_MTS,
        })
      );
    },
  }))
  .views((self) => ({
    getChargeFromSlab: (slab: WeightSlabType, createIfNotPresent?: false) => {
      const charge = self.contract_charges?.find(
        (charge) =>
          charge.start_weight === slab.start_weight &&
          (charge?.end_weight || null) === slab.end_weight
      );

      if (!charge && createIfNotPresent) {
        self.createChargeForSlab(slab);
        return null;
      }

      return charge?.charge_amount;
    },
    getChargeFromContainer: (containerType: string, createIfNotPresent?: false) => {
      const charge = self.contract_charges?.find(
        (charge) => charge.container_type === containerType
      );

      if (!charge && createIfNotPresent) {
        self.createChargeForContainerType(containerType);
        return null;
      }

      return charge?.charge_amount;
    },
    getContainerType: () => {
      if (!self.container_type && self.contract_charges?.length) {
        self.setContainerType(self.contract_charges[0].container_type);
      }
      return self.container_type;
    },
    getPayload: (weightSlabs: WeightSlabType[], removeIds = false) => {
      const { weightUnit, container_type, ...lineItem } = getSnapshot(self);
      const payload: any = {
        id: lineItem.id === 'new' || removeIds ? null : lineItem.id,
        port_of_discharge_id: lineItem.port_of_discharge?.id,
        port_of_loading_id: lineItem.port_of_loading?.id,
        return_location_id: lineItem.return_location?.id,
        currency: lineItem.currency,
        is_empty: lineItem.is_empty,
        contract_charges: lineItem.contract_charges.map((charge) => {
          // will destroy charge item that is not part of current weightslab state.
          const destroy = !weightSlabs.find(
            (slab) =>
              slab.start_weight === charge.start_weight &&
              slab.end_weight === (charge?.end_weight || null)
          );

          const chargePayload = {
            ...charge,
            id: charge.id?.includes('new') || removeIds ? null : charge.id,
          };

          if (destroy) chargePayload._destroy = true;
          else delete chargePayload._destroy; // removing defaul value (false) of _destroy
          return chargePayload;
        }),
      };

      if (lineItem._destroy) payload._destroy = lineItem._destroy;

      return payload;
    },
    getWeightSlabs: () => {
      const contractCharges = getSnapshot(self.contract_charges);
      return contractCharges
        .map((charge) => ({
          start_weight: charge.start_weight,
          end_weight: (charge?.end_weight || null) as number | null,
        }))
        .sort((a, b) => a.start_weight - b.start_weight);
    },
  }));

export type RoadContractLineItemValue = Instance<typeof RoadContractLineItem>;

export interface RoadContractLineItemPayloadType extends Partial<RoadContractLineItemValue> {
  return_location_id: string | null;
  port_of_loading_id: string | null;
  port_of_discharge_id: string | null;
}

export default RoadContractLineItem;
