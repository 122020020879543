import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from '@shipmnts/pixel-hub';
import {
  containerTypesFlatArray,
  containerTypesHash,
  LOAD_TYPE_FCL,
  VOLUME_UNIT_CBM,
  WEIGHT_UNIT_KGS,
  WEIGHT_UNIT_MTS,
} from 'common/baseConstants';
import { useInquiryForm } from './InquiryForm';
import { isLiveReeferCargo } from 'sales_hub/models/Cargo';
import { ContainerDetails } from 'operations';
import CargoNewComponent from 'operations/modules/shipment/components/DetailLayout/Cargo/CargoNewComponent';

const CargoDetailsByTotal = () => {
  return (
    <Row gutter={{ lg: 16 }}>
      <Col xs={24} sm={12} lg={6}>
        <Form.Item
          name={['cargo', 'total_packages']}
          label="No. of Pieces"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="#Packages" min={0} precision={0} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Form.Item label={'Gross Wt'} style={{ marginBottom: 0 }}>
          <Space.Compact>
            <Form.Item name={['cargo', 'gross_weight']} rules={[{ required: true }]}>
              <InputNumber
                style={{ width: '160px' }}
                min={0}
                precision={2}
                placeholder="Gross Wt."
              />
            </Form.Item>
            <Form.Item name={['cargo', 'weight_unit']} rules={[{ required: true }]}>
              <Select
                style={{ width: '80px' }}
                placeholder={'Unit'}
                options={[WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS].map((opt) => ({
                  value: opt,
                  label: opt,
                }))}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Form.Item label={'Volume'} style={{ marginBottom: 0 }}>
          <Space.Compact>
            <Form.Item name={['cargo', 'gross_volume']} rules={[{ required: true }]}>
              <InputNumber style={{ width: '160px' }} min={0} precision={2} placeholder="Vol" />
            </Form.Item>
            <Form.Item name={['cargo', 'volume_unit']}>
              <Select
                style={{ width: '80px' }}
                defaultValue={VOLUME_UNIT_CBM}
                disabled
                placeholder={'Unit'}
                options={[VOLUME_UNIT_CBM].map((opt) => ({ value: opt, label: opt }))}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} lg={6}>
        <Form.Item label="Volumetric Weight" style={{ marginBottom: 0 }}>
          <Space.Compact>
            <Form.Item name={['cargo', 'volumetric_weight']}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Gross Volume"
                disabled
                min={0}
                precision={2}
              />
            </Form.Item>
            <Form.Item name={['cargo', 'weight_unit']}>
              <Select
                style={{ width: '80px' }}
                placeholder={'Unit'}
                disabled
                options={[WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS].map((opt) => ({
                  value: opt,
                  label: opt,
                }))}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      </Col>
    </Row>
  );
};

const QuickContainerDetails = () => {
  const { isDetailForm, form } = useInquiryForm();
  const [selectedKeys, setSelectedKeys] = useState<Array<number>>([]);
  const containerRequestsRef = useRef<{ runValidation: () => boolean }>();
  const toggleSelectedKey = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) setSelectedKeys([index].concat(selectedKeys));
    else setSelectedKeys(selectedKeys.filter((key) => key !== index));
  };

  if (!isDetailForm) {
    return (
      <Form.List name={'containerRequests'}>
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => {
                return (
                  <Row gutter={{ lg: 16 }} key={index}>
                    {fields.length > 1 && (
                      <Col span={1}>
                        <Form.Item label={index === 0 ? ' ' : undefined}>
                          <Checkbox
                            checked={selectedKeys.includes(index)}
                            onChange={(e) => toggleSelectedKey(e, index)}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        name={[field.name, 'container_type_code']}
                        label={index === 0 ? 'Container Type' : undefined}
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={containerTypesFlatArray}
                          onChange={(val) => {
                            // selection only changes container_type_code,
                            // but we also need container_type (string)
                            const { containerRequests } = form.getFieldsValue();
                            const containerType = containerTypesHash[val];
                            Object.assign(containerRequests[field.key], {
                              container_type: containerType,
                            });
                            form.setFieldsValue({ containerRequests });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={2}>
                      <Form.Item
                        name={[field.name, 'quantity']}
                        label={index === 0 ? 'Qty' : undefined}
                        style={{ marginBottom: 0 }}
                        rules={[{ required: true }]}
                      >
                        <InputNumber style={{ width: '100%' }} precision={0} min={1} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item label={index === 0 ? 'Weight' : undefined}>
                        <Space.Compact>
                          <Form.Item
                            name={[field.name, 'weight_per_container']}
                            style={{ marginBottom: 0 }}
                            rules={[{ required: true }]}
                          >
                            <InputNumber
                              style={{ width: '180px' }}
                              min={0}
                              precision={2}
                              placeholder="Weight per Container"
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'weight_unit']}
                            style={{ marginBottom: 0 }}
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{ width: '80px' }}
                              placeholder={'Unit'}
                              options={[WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS].map((opt) => ({
                                value: opt,
                                label: opt,
                              }))}
                            />
                          </Form.Item>
                        </Space.Compact>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
              <Form.ErrorList errors={errors} />
              <Row gutter={8}>
                <Col>
                  <Button
                    size="small"
                    onClick={() =>
                      add({
                        container_type: '22G0',
                        quantity: 1,
                        weight_unit: WEIGHT_UNIT_KGS,
                      })
                    }
                  >
                    Add Container
                  </Button>
                </Col>
                {fields.length > 1 && (
                  <Col>
                    <Button
                      danger
                      size="small"
                      onClick={() => {
                        remove(selectedKeys);
                        setSelectedKeys([]);
                      }}
                      disabled={!selectedKeys.length}
                    >
                      Delete Container(s)
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          );
        }}
      </Form.List>
    );
  }

  return (
    <div style={{ marginBottom: '1em' }}>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.cargos !== currentValues.cargos}
      >
        {({ getFieldValue }) => {
          const cargos = getFieldValue('cargos');
          const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false; // CONFIRM: no more needed??
          return (
            <Form.Item
              name="containerRequests"
              noStyle
              rules={[
                {
                  validator: (rule, value) => {
                    if (!containerRequestsRef?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <ContainerDetails
                ref={containerRequestsRef}
                required_fields={['container_type_code', 'quantity']}
                isLiveReefer={isLiveReefer}
                required={false}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </div>
  );
};

const InquiryCargoDetails = () => {
  const { freightType, loadType, form, cargoRef } = useInquiryForm();
  const [cargoDetailsBy, setCargoDetailsBy] = useState('by_total'); // need to calculate this for edit mode (based on what data we have)

  // constants
  // const showCargoDetailsBy = useMemo(
  //   () =>
  //     freightType === FREIGHT_TYPE_AIR ||
  //     (freightType === FREIGHT_TYPE_OCEAN && loadType === LOAD_TYPE_LCL),
  //   [freightType, loadType]
  // );
  const showCargoDetailsBy = false; // NOTE: remove this once the component is fixed

  const showContainerDetails = useMemo(() => loadType === LOAD_TYPE_FCL, [loadType]);

  return (
    <>
      <h3 className="inq-form-section-title">{`${
        showContainerDetails ? 'Container & ' : ''
      } Cargo Details`}</h3>

      {showCargoDetailsBy ? (
        <Radio.Group
          defaultValue={'by_total'}
          style={{ marginBottom: '16px' }}
          onChange={(event) => setCargoDetailsBy(event.target.value)}
        >
          <Radio.Button value={'by_total'}>{'By Total'}</Radio.Button>
          <Radio.Button value={'by_packages'}>{'By Packages'}</Radio.Button>
        </Radio.Group>
      ) : (
        <></>
      )}
      {/* {freightType !== FREIGHT_TYPE_ROAD && (
        <Row gutter={{ lg: 16 }}>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item name={['cargo', 'commodity']} label="Commodity" rules={[{ required: true }]}>
              <GlobalSearch doc_type="Global::Commodity" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {freightType === FREIGHT_TYPE_ROAD && (
        <Row gutter={{ lg: 16 }}>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item
              name={['cargo', 'commodity_description']}
              label="Commodity Description"
              rules={[{ required: true }]}
            >
              <Input placeholder="Commodity Description" />
            </Form.Item>
          </Col>
        </Row>
      )} */}

      {showContainerDetails && <QuickContainerDetails />}
      {showCargoDetailsBy ? (
        cargoDetailsBy === 'by_total' ? (
          <CargoDetailsByTotal />
        ) : (
          <>{'Coming Soon'}</>
        )
      ) : (
        <>
          <CargoNewComponent
            loadType={loadType}
            freightType={freightType}
            ref={cargoRef}
            form={form}
          />
        </>
      )}
    </>
  );
};

export default InquiryCargoDetails;
