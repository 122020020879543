import { CopyOutlined, EditOutlined, StopOutlined, message } from '@shipmnts/pixel-hub';
import { NEGATIVE_TYPE, PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import React from 'react';
import { ApolloClient } from '@apollo/client';
import { UPSERT_SHIPMENT_ROUTE } from 'operations/graphql/shipmentRoute';
import { ShipmentRouteValue } from 'operations/models/ShipmentRoute';

export const editShipmentRoute = (shipmentRoute: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Edit Route',
    key: 'edit_shipment_route',
    isEnable: true,
    icon: <EditOutlined />,
    onClick: () => {
      window.open(`/form/route/${shipmentRoute?.id}`, '_self');
    },
  };
};

export const disableShipmentRoute = (
  shipmentRoute: ShipmentRouteValue,
  client: ApolloClient<object>
): Action => {
  const isDisabled = shipmentRoute.is_disabled;
  return {
    type: isDisabled ? PRIMARY_TYPE : NEGATIVE_TYPE,
    displayComponent: `${isDisabled ? 'Enable' : 'Disable'} Route`,
    key: 'disable_shipment_route',
    isEnable: true,
    icon: <StopOutlined />,
    onClick: async (onSuccess: (() => void) | undefined) => {
      const { data, errors } = await client.mutate({
        mutation: UPSERT_SHIPMENT_ROUTE,
        variables: {
          route: {
            id: shipmentRoute.id,
            is_disabled: !shipmentRoute.is_disabled,
          },
        },
      });
      if (errors) {
        console.error(errors);
        message.error(errors[0].message);
      } else if (data) {
        message.success(`Shipment Route ${isDisabled ? 'Enabled' : 'Disabled'}`);
        if (onSuccess) onSuccess();
      }
    },
  };
};

export const duplicateRoute = (shipmentRoute: any): Action => {
  return {
    type: PRIMARY_TYPE,
    displayComponent: 'Duplicate Route',
    key: 'duplicate_shipment_route',
    isEnable: true,
    icon: <CopyOutlined />,
    onClick: () => {
      window.open(`/form/route/duplicate/${shipmentRoute?.id}`, '_self');
    },
  };
};

export const getShipmentRouteActions = (shipmentRoute: any, client: ApolloClient<object>) => {
  return [
    editShipmentRoute(shipmentRoute),
    disableShipmentRoute(shipmentRoute, client),
    duplicateRoute(shipmentRoute),
  ];
};
