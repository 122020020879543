import React from 'react';
import { PRIMARY_TYPE } from '../constants';
import { Action } from '../models';
import { ShareAltOutlined, UploadOutlined } from '@shipmnts/pixel-hub';
import { addCollaboratorsRenderer, retryDataImportRender } from './dataImportActionRenderer';
import { DataImportType } from '@shipmnts/pixel-hub';

export const getDataImportActions = (dataImport: DataImportType) => {
  const actions: Action[] = [];

  actions.push({
    type: PRIMARY_TYPE,
    key: 'retry_skipped_records',
    icon: <UploadOutlined />,
    displayComponent: 'Retry Skipped Records',
    isEnable: dataImport?.error_rows?.length > 0,
    performAction: retryDataImportRender,
  });

  actions.push({
    type: PRIMARY_TYPE,
    key: 'add_collaborators',
    icon: <ShareAltOutlined />,
    isEnable: true,
    displayComponent: 'Add Collaborators',
    performAction: addCollaboratorsRenderer,
  });

  return actions;
};
