import React from 'react';
import { Table } from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';
import { LOAD_TYPE_LCL } from 'operations/baseConstants';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

interface CargosViewProps {
  cargos?: Array<ProductOrderItemValue>;
  load_type?: string;
}

const getColumns = (load_type?: string) => [
  {
    title: 'Cargo Description',
    dataIndex: 'product_name',
  },
  {
    title: 'Packages',
    dataIndex: 'outer_package_qty',
  },
  {
    title: 'Package Type',
    dataIndex: 'outer_package_type',
  },
  {
    title: 'Gross Weight',
    dataIndex: 'gross_weight',
  },
  {
    title: 'Weight unit',
    dataIndex: 'weight_unit',
  },
  ...(load_type === LOAD_TYPE_LCL
    ? [
        {
          title: 'Gross Volume',
          dataIndex: 'gross_volume',
        },
        {
          title: 'Volume unit',
          dataIndex: 'volume_unit',
        },
      ]
    : []),
  {
    title: 'Properties',
    dataIndex: 'cargo_properties',
    // editable: true,
    // renderComponent: RENDER_INPUT,
    render: function render(text: string, record: ProductOrderItemValue, index: number) {
      return <span />;
    },
  },
];

const CargosView = observer(function CargosView(props: CargosViewProps): JSX.Element {
  const { cargos, load_type } = props;
  const columns = getColumns(load_type);
  return (
    <Table
      dataSource={cargos}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.id || ''}
      size="small"
    />
  );
});

export default CargosView;
