import { SessionDataValue } from 'operations/models/SessionData';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import CreateAndAssignLoad from 'operations/modules/shipment/components/ShipmentForm/CreateAndAssignLoad';
import { RowNode } from '@ag-grid-community/core';
import AssignVehicleFormTable from 'operations/modules/shipment/components/ShipmentForm/AssignVehicleFormDrawer';
import AssignLoadWrapper from 'operations/modules/shipment/components/ShipmentForm/AssignLoadWrapper';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

export interface CargoData extends ProductOrderItemValue {
  shipment: ShipmentValue;
}

export interface CargoActionType {
  cargo: CargoData;
  selectedIds: string[] | null;
  setEmailProps: (emailProps: any) => void;
  setVisible: (visible: boolean) => void;
  sessionData: SessionDataValue;
  extraProps?: any;
  navigate: any;
}

export const assignLoadRender = (payload: CargoActionType) => {
  const { cargo, selectedIds } = payload;
  const shipment = Shipment.create(cargo.shipment);

  return {
    actionParams: {
      shipment: shipment,
      selectedIds: selectedIds?.map((id) => id.toString()),
    },
    component: CreateAndAssignLoad,
  };
};

export const assignVehicleRender = (payload: CargoActionType) => {
  const { cargo } = payload;
  const shipment = Shipment.create(cargo.shipment);

  return {
    actionParams: {
      shipment: shipment,
    },
    component: AssignVehicleFormTable,
  };
};

export const bulkCargoActionsRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  onCloseModal: () => void
) => {
  const shipmentIds = selectedNodes
    .map((node) => node.data?.shipment_id)
    .filter((id, ind, self) => id && self.indexOf(id) === ind);
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      onCloseModal: onCloseModal,
      selectedIds: selectedNodes.map((node) => node.data?.id?.toString()),
      shipmentIds: shipmentIds,
    },
    component: AssignLoadWrapper,
  };
};
