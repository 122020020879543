import { useMutation } from '@apollo/client';
import { message, Modal, Form, DatePicker } from '@shipmnts/pixel-hub';
import React, { useEffect } from 'react';
import { dayjs } from '@shipmnts/pixel-hub';
import { ContractValue } from '../models/Contract';
import { UPSERT_CONTRACT } from '../graphql/mutations';

interface ExpireContractValidityModalProps {
  visible: boolean;
  contract: ContractValue;
  onClose: () => void;
  onSuccess: () => void;
}

export default function ExpireContractValidityModal(props: ExpireContractValidityModalProps) {
  const { onClose, contract, onSuccess } = props;
  const [form] = Form.useForm();
  const [upsertContract, { data, loading, error }] = useMutation(UPSERT_CONTRACT);

  const { valid_till } = contract;
  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
    if (data?.upsert_contract?.contract) {
      message.success('Validity Updated Successfully');
      if (onSuccess) onSuccess();
      if (onClose) onClose();
    }
  }, [data, loading, error, onSuccess, onClose]);

  return (
    <Modal
      open={true}
      destroyOnClose
      onOk={form.submit}
      okText="Submit"
      onCancel={onClose}
      title="Contract Expires on"
      width="316px"
    >
      <Form
        form={form}
        onFinish={(values) => {
          const { id } = contract;
          upsertContract({
            variables: {
              contract_id: id,
              contract: {
                valid_till: values.valid_till.endOf('day'),
                type: contract.type,
                rate_type: contract.rate_type,
                freight_charge: contract.freight_charge,
                load_type: contract.load_type,
                trade_type: contract.trade_type,
              },
            },
          });
        }}
        initialValues={{ valid_till: !!valid_till ? dayjs.unix(valid_till) : null }}
        layout="vertical"
      >
        <Form.Item required name="valid_till" label={'Valid Till'}>
          <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
