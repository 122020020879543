import { AIRFLOW_UNIT_CBM_PER_HR } from 'network/baseConstants';
import { TEMPERATURE_UNIT_CELSIUS } from 'operations/baseConstants';

export const handleValuesChange = (changedValues: any, allValues: any, form: any) => {
  const ventilation_requested_fields = {
    air_flow: undefined,
    air_flow_unit: undefined,
  };
  const humidity_control_requested_fields = { relative_humidity_percent: undefined };
  const controlled_atmosphere_requested_fields = {
    oxygen_level_percent: undefined,
    nitrogen_level_percent: undefined,
    carbon_dioxide_level_percent: undefined,
  };
  const is_active_reefer_fields = {
    ventilation_requested: undefined,
    humidity_control_requested: undefined,
    controlled_atmosphere_requested: undefined,
    genset_requested: undefined,
    ...ventilation_requested_fields,
    ...humidity_control_requested_fields,
    ...controlled_atmosphere_requested_fields,
  };
  if (Object.keys(changedValues).includes('is_active_reefer')) {
    if (changedValues.is_active_reefer)
      form.setFieldsValue({ temperature_unit: TEMPERATURE_UNIT_CELSIUS });
    else form.setFieldsValue(is_active_reefer_fields);
  }
  if (Object.keys(changedValues).includes('ventilation_requested')) {
    if (changedValues.ventilation_requested)
      form.setFieldsValue({ air_flow_unit: AIRFLOW_UNIT_CBM_PER_HR });
    else form.setFieldsValue(ventilation_requested_fields);
  }
  if (
    Object.keys(changedValues).includes('humidity_control_requested') &&
    !changedValues.humidity_control_requested
  ) {
    form.setFieldsValue(humidity_control_requested_fields);
  }
  if (
    Object.keys(changedValues).includes('controlled_atmosphere_requested') &&
    !changedValues.controlled_atmosphere_requested
  ) {
    form.setFieldsValue(controlled_atmosphere_requested_fields);
  }
};
