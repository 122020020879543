import React, { lazy } from 'react';

import {
  Col,
  Popover,
  Row,
  Tag,
  Typography,
  CustomerCreditStatusTag,
  TrackerContextProvider,
  Tooltip,
} from '@shipmnts/pixel-hub';
import { CustomIcon, getDateFromUnix } from '@shipmnts/pixel-hub';
import { get, startCase, clone } from 'lodash';

import { getTagColor } from 'sales_hub/components/Inquiry/common';
import { useInquiryDetail } from 'sales_hub/components/Inquiry/InquiryDetail/InquiryDetailLayout';
// import StatusRenderer from 'sales_hub/components/Inquiry/InquiryDetail/StatuseRenderer';
import ErrorBoundary from 'sales_hub/utils/ErrorBoundary';

import { collapsibleCard } from './common';
import { Link } from 'wouter';
import { FREIGHT_TYPE_AIR } from 'sales_hub/utils/constants';

const StatusRenderer = lazy(
  () => import('sales_hub/components/Inquiry/InquiryDetail/StatuseRenderer')
);

const { Paragraph, Text } = Typography;

const getJobLinks = (jobs: any, options = { path: '/view/shipment', job_id_key: 'job_number' }) => {
  if (!!!jobs) return '-';
  const { path, job_id_key } = options;
  jobs = jobs.filter((job: any) => !!job && !!job[job_id_key]);
  return jobs.length > 1 ? (
    <Popover
      placement="top"
      showArrow={false}
      overlayStyle={{ width: '350px' }}
      content={jobs.map((job: any, index: number) => (
        <span key={job.id}>
          {index > 0 && ', '}
          <Link to={`~${path}/${job.id}`}>{job[job_id_key]}</Link>
        </span>
      ))}
    >
      <Link to={`~${path}/${jobs[0].id}`}>{jobs[0][job_id_key]}</Link>
      <b>{' ...'}</b>
    </Popover>
  ) : jobs.length === 1 ? (
    <Link to={`~${path}/${jobs[0].id}`}>{jobs[0][job_id_key]}</Link>
  ) : (
    '-'
  );
};

const InquirySummary = () => {
  const { inquiry, inquiry_global_id } = useInquiryDetail();

  if (!inquiry || !inquiry_global_id) return <></>;
  const customerCompany = inquiry.customer_company;

  const customerDetails: { [key: string]: string | React.ReactNode } = {
    Customer: (
      <>
        <Paragraph
          ellipsis={{ tooltip: true }}
          style={{ marginBottom: '0', marginRight: '1px', fontWeight: 'bold' }}
        >
          {customerCompany?.registered_name}
        </Paragraph>
        <ErrorBoundary fallback={<></>}>
          <CustomerCreditStatusTag
            style={{ cursor: 'pointer', minHeight: '10px' }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            companyInternal={customerCompany}
          />
        </ErrorBoundary>
      </>
    ),
    'Customer Group': customerCompany?.company_group,
    'Customer Address': inquiry.customer_address?.name,
    'Target Rate': inquiry.target_rate,
    'Preferred Carrier': (inquiry.preferred_carriers?.map((i: any) => i.name) || ['']).join(', '),
  };

  const shipmentDetails: { [key: string]: string | React.ReactNode } = {
    Origin: <span style={{ fontWeight: 'bold' }}>{inquiry.origin?.name} </span>,
    Destination: <span style={{ fontWeight: 'bold' }}>{inquiry.destination?.name} </span>,
    Incoterm: inquiry.incoterms,
    'Cargo Details': (
      <>
        <Paragraph
          ellipsis={{
            tooltip: (
              <ol>
                {inquiry.cargos?.map((i) => (
                  <li key={i.id} style={{ marginBottom: '0' }}>
                    {i.product_name}
                    <br />
                  </li>
                ))}
              </ol>
            ),
          }}
          style={{ marginBottom: '0' }}
        >
          {inquiry.cargos?.map((i) => (
            <Text key={i.id} style={{ marginBottom: '0' }}>
              {i.product_name}
            </Text>
          ))}
        </Paragraph>
      </>
    ),
  };

  if (inquiry.freight_type !== FREIGHT_TYPE_AIR) {
    shipmentDetails['Ordered Qty'] = inquiry.containerSummary;
    shipmentDetails['Allocated Qty'] = inquiry.allocatedQty;
    shipmentDetails['Pending Qty'] = inquiry.pendingQty;
  }
  if (inquiry.place_of_carrier_delivery) {
    shipmentDetails['POCD'] = inquiry.place_of_carrier_delivery.name;
  }
  if (inquiry.place_of_carrier_receipt) {
    shipmentDetails['POCR'] = inquiry.place_of_carrier_receipt.name;
  }
  const getName = (data: any, type: string) => {
    return get(data, type, '');
  };

  const teams: any = clone(inquiry.teams) ?? [];

  const teamData = (
    <div>
      {teams.length
        ? teams.map((team: any, index: number) => {
            return (
              <Tooltip title={startCase(team.role)} key={team.id} trigger={['hover', 'click']}>
                <Text>
                  {team?.user_contact?.name || team?.sales_person?.name}
                  {index !== teams.length - 1 && ', '}
                </Text>
              </Tooltip>
            );
          })
        : '-'}
    </div>
  );

  const creationDetails: { [key: string]: string | React.ReactNode } = {
    Branch: `${getName(inquiry?.involved_branch, 'name')}`,
    'Team Member(s)': teamData,
    'Created by': `${getName(inquiry.created_by, 'first_name')} ${getName(
      inquiry.created_by,
      'last_name'
    )}`,
    'Created at': inquiry.created_at ? getDateFromUnix(inquiry.created_at) : '-',
    'Recieved at': inquiry.received_at ? getDateFromUnix(inquiry.received_at) : '-',
  };

  const otherDetails: { [key: string]: string | React.ReactNode } = {
    Description: inquiry.description,
    Status: (
      <StatusRenderer
        status={inquiry.last_action_status || ''}
        trackerProps={{ referenceId: inquiry_global_id, referenceType: 'SalesHub::InquiryGlobal' }}
      ></StatusRenderer>
    ),
    Tags: (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Array.isArray(inquiry.tags) &&
          inquiry.tags.map((tag) => {
            return (
              <Tag color={getTagColor(tag)} style={{ marginBottom: '2px' }} key={tag}>
                {startCase(tag)}
              </Tag>
            );
          })}
      </div>
    ),
    Shipments: inquiry.shipments ? getJobLinks(inquiry.shipments) : '-',
    'Customer Orders': getJobLinks(
      inquiry.shipments?.filter((shipment) => !!!shipment.job_number),
      {
        path: '/view/shipment',
        job_id_key: 'shipment_booking_number',
      }
    ),
  };
  const customerDetailsCard = {
    icon: <CustomIcon icon="User" />,
    header: 'Customer Details',
    data: customerDetails,
  };

  const shipmentDetailsCard = {
    icon: <CustomIcon icon="Info" />,
    header: 'Shipment Details',
    data: shipmentDetails,
  };
  const creationDetailsCard = {
    icon: <CustomIcon icon="Info" />,
    header: 'Creation Details',
    data: creationDetails,
  };
  const otherDetailsCard = {
    icon: <CustomIcon icon="Route" />,
    header: 'Other Details',
    data: otherDetails,
  };
  const servicedRequestedCard = {
    icon: <CustomIcon icon="Info" />,
    header: 'Services Requested',
    extra: () => (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {inquiry.services &&
          inquiry.services.map((d) => {
            return (
              <Tag color={getTagColor(d)} style={{ marginBottom: '4px' }} key={d}>
                {startCase(d)}
              </Tag>
            );
          })}
      </div>
    ),
  };

  return (
    <TrackerContextProvider referenceType={'SalesHub::InquiryGlobal'}>
      <Row wrap={true} className="inquiry-summary">
        {/* Customer Details */}
        <Col sm={24} md={12} lg={6}>
          {collapsibleCard(customerDetailsCard)}
        </Col>
        {/* Shipment Details */}
        <Col sm={24} md={12} lg={6}>
          {collapsibleCard(shipmentDetailsCard)}
        </Col>
        {/* Creation Details */}
        <Col sm={24} md={12} lg={6}>
          {collapsibleCard(creationDetailsCard)}
        </Col>
        {/* Other Details */}
        <Col sm={24} md={12} lg={6}>
          {collapsibleCard(otherDetailsCard)}
        </Col>
        {/* Service Requested Details */}
        <Col sm={24} md={24} lg={24} style={{ padding: '10px 0px' }}>
          {collapsibleCard(servicedRequestedCard)}
        </Col>
      </Row>
    </TrackerContextProvider>
  );
};

export default InquirySummary;
