import React, { useState, useCallback, useEffect } from 'react';
import { Button, Row, Col, Input, InputNumber, Select, Card, Form } from '@shipmnts/pixel-hub';
import {
  WEIGHT_UNIT_KGS,
  VOLUME_UNIT_CBM,
  oceanPackageTypes,
  WEIGHT_UNIT_MTS,
  LOAD_TYPE_FCL,
} from 'operations/baseConstants';
import CargoProperties from './CargoProperties';
import { Checkbox, CheckboxChangeEvent } from '@shipmnts/pixel-hub';
import { CommodityValue } from 'operations/models/Commodity';
import { FormInstance } from '@shipmnts/pixel-hub';
import PackageDetails from './PackageDetails';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const { TextArea } = Input;
const { Option } = Select;

const ROW_GUTTER = 16;

interface CargoDetailsProps {
  form: FormInstance;
  disableCargoAddRemove?: boolean;
  load_type?: string;
  freightType?: string;
  required?: boolean;
  showCommoditySearch?: boolean;
  disabled?: boolean;
  grossVolumeRequired?: boolean;
}

const CargoDetails = function (props: CargoDetailsProps) {
  const {
    form,
    disableCargoAddRemove,
    load_type,
    required = true,
    showCommoditySearch = true,
    disabled = false,
    grossVolumeRequired = true,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState<Array<number>>([]);
  const toggleSelectedKey = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) setSelectedKeys([index].concat(selectedKeys));
    else setSelectedKeys(selectedKeys.filter((key) => key !== index));
  };

  const onCommodityChange = useCallback(
    (index: number, value?: CommodityValue | Array<CommodityValue>) => {
      if (Array.isArray(value)) return;
      const cargo = form.getFieldValue('cargos');
      cargo[index]['cargo_properties'] = {
        ...(cargo[index]['cargo_properties'] || {}),
        is_temp_controlled: value?.live_reefer_cargo ? true : false,
      };
      cargo[index]['commodity_description'] = value?.name;
      form.setFieldsValue({ cargo });
    },
    [form]
  );

  const isLoose = load_type !== LOAD_TYPE_FCL;

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <Form.List
      name="cargos"
      rules={[
        {
          validator: async (_, cargos) => {
            if ((!cargos || cargos.length < 1) && required) {
              return Promise.reject(new Error('At least 1 cargo row is needed'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            <div>
              {fields.map((field, index) => {
                const cargos = form.getFieldValue(['cargos', field.name]) || [];
                return (
                  <Card
                    className="cargo-details"
                    size="small"
                    key={index}
                    style={{ background: '#f9f9f9' }}
                  >
                    <Form.Item noStyle name={[field.name, 'linked_to_id']}></Form.Item>
                    <Row style={{ alignItems: 'center' }} wrap={true} gutter={ROW_GUTTER}>
                      <Col span={1}>
                        <Form.Item label={index === 0 || isMobile ? ' ' : undefined}>
                          <Checkbox
                            disabled={disabled}
                            checked={selectedKeys.includes(index)}
                            onChange={(e) => toggleSelectedKey(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      {showCommoditySearch && (
                        <Col md={12} lg={3}>
                          <Form.Item
                            label={index === 0 || isMobile ? 'Commodity' : undefined}
                            key={field.key}
                            name={[field.name, 'commodity']}
                          >
                            <GlobalSearch
                              doc_type="Global::Commodity"
                              onChange={(value: any) => onCommodityChange(index, value)}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col md={12} lg={3}>
                        <Form.Item
                          label={index === 0 || isMobile ? 'Cargo Description' : ''}
                          key={field.key}
                          name={[field.name, 'product_name']}
                          required
                          rules={[{ required: true }]}
                        >
                          <TextArea disabled={disabled} rows={2} placeholder="Cargo Description" />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={2}>
                        <Form.Item
                          label={index === 0 || isMobile ? 'Packages' : ''}
                          key={field.key}
                          name={[field.name, 'outer_package_qty']}
                        >
                          <InputNumber
                            min={1}
                            precision={0}
                            placeholder="Packages"
                            disabled={cargos.length > 0 || disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={3}>
                        <Form.Item
                          label={index === 0 || isMobile ? 'Package Type' : ''}
                          key={field.key}
                          name={[field.name, 'outer_package_type']}
                        >
                          <Select
                            filterOption={(input, option): boolean => {
                              return (
                                `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              );
                            }}
                            showSearch
                            allowClear
                            popupMatchSelectWidth={300}
                            style={{
                              width: '100%',
                            }}
                            placeholder="Package Type"
                            disabled={cargos.length > 0 || disabled}
                          >
                            {oceanPackageTypes.map((option: { key: string; name: string }) => (
                              <Option key={option.key} value={option.key}>
                                {option.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={5} style={{ minWidth: '225px' }}>
                        <Input.Group compact style={{ width: '100%' }}>
                          <Form.Item
                            label={index === 0 || isMobile ? 'Gross Wt.' : ''}
                            key={`${field.key}_gross_weight`}
                            name={[field.name, 'gross_weight']}
                            style={{ width: '36%', marginRight: '1px' }}
                            required={isLoose}
                            rules={[{ required: isLoose }]}
                          >
                            <InputNumber
                              min={0}
                              precision={2}
                              style={{ width: '100%' }}
                              placeholder="Gross Weight"
                              disabled={cargos.length > 0 || disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            label={index === 0 || isMobile ? 'Net Wt.' : ''}
                            key={`${field.key}_net_weight`}
                            name={[field.name, 'net_weight']}
                            style={{ width: '36%', marginRight: '1px' }}
                          >
                            <InputNumber
                              min={0}
                              precision={2}
                              style={{ width: '100%' }}
                              placeholder="Net Weight"
                              disabled={cargos.length > 0 || disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            key={`${field.key}_weight_unit`}
                            name={[field.name, 'weight_unit']}
                            label={index === 0 || isMobile ? 'Unit' : ''}
                            style={{ width: '27%' }}
                          >
                            <Select disabled={cargos.length > 0 || disabled}>
                              <Option key={WEIGHT_UNIT_KGS} value={WEIGHT_UNIT_KGS}>
                                {WEIGHT_UNIT_KGS}
                              </Option>
                              <Option key={WEIGHT_UNIT_MTS} value={WEIGHT_UNIT_MTS}>
                                {WEIGHT_UNIT_MTS}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Col>
                      {isLoose && (
                        <Col md={12} lg={4}>
                          <Input.Group compact style={{ width: '100%' }}>
                            <Form.Item
                              label={index === 0 || isMobile ? 'Gross Vol.' : ''}
                              key={`${field.key}_gross_volume`}
                              name={[field.name, 'gross_volume']}
                              style={{ width: '60%' }}
                              required={grossVolumeRequired}
                              rules={[{ required: grossVolumeRequired }]}
                            >
                              <InputNumber
                                min={0}
                                precision={3}
                                style={{ width: '100%' }}
                                placeholder="Gross Volume"
                                disabled={cargos.length > 0 || disabled}
                              />
                            </Form.Item>
                            <Form.Item
                              key={`${field.key}_volume_unit`}
                              name={[field.name, 'volume_unit']}
                              label={index === 0 || isMobile ? 'Unit' : ''}
                              style={{ width: '40%' }}
                            >
                              <Select disabled>
                                <Option key={VOLUME_UNIT_CBM} value={VOLUME_UNIT_CBM}>
                                  {VOLUME_UNIT_CBM}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Input.Group>
                        </Col>
                      )}
                      <Col md={12} lg={3}>
                        <Form.Item
                          label={index === 0 || isMobile ? 'Properties' : undefined}
                          key={field.key}
                          name={[field.name, 'cargo_properties']}
                        >
                          <CargoProperties formKey={field.key} freightType={props.freightType} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div style={{ width: '100%', padding: '0px 0px 10px 20px' }}>
                      <PackageDetails form={form} cargoField={field} disabled={disabled} />
                    </div>
                  </Card>
                );
              })}
            </div>
            <Form.ErrorList errors={errors} />
            <Row style={{ margin: '10px' }} gutter={16}>
              <Button
                style={{
                  marginRight: 16,
                }}
                size="small"
                onClick={() =>
                  add({
                    outer_package_type: 'Box',
                    weight_unit: WEIGHT_UNIT_KGS,
                    volume_unit: VOLUME_UNIT_CBM,
                  })
                }
                disabled={!!disableCargoAddRemove || disabled}
              >
                Add cargo
              </Button>
              <Button
                danger
                size="small"
                style={{
                  marginRight: 16,
                }}
                disabled={selectedKeys.length === 0 || !!disableCargoAddRemove || disabled}
                onClick={() => {
                  remove(selectedKeys);
                  setSelectedKeys([]);
                }}
              >
                Delete cargo(s)
              </Button>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

export default CargoDetails;
