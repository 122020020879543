import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Drawer, DrawerFooter, Form, Select, message } from '@shipmnts/pixel-hub';
import {
  containerTypes,
  containerSettingsMap,
  containerTypesFlatArray,
} from 'network/baseConstants';
import { BULK_UPDATE_CONTAINER_FIELDS } from 'operations/modules/shipment/components/DetailLayout/Containers/graphql';
import { FETCH_CONTAINERS_FOR_EDIT } from 'operations/modules/booking/graphql/shipmentContainer';
import { getIsContainerDisabled } from 'operations/modules/shipment/components/DetailLayout/Containers/EditContainer/EditContainerForm';
import ContainerSettingContent from 'operations/components/ContainerSettingContent';
import {
  AIRFLOW_UNIT_CBM_PER_HR,
  DIMENSION_CMS,
  TEMPERATURE_UNIT_CELSIUS,
} from 'operations/baseConstants';
import { handleValuesChange } from 'operations/components/ContainerSettingHelper';
import { omit as _omit } from 'lodash';

type Props = {
  id: string[];
  onClose?: () => void;
  onSuccess?: () => void;
};
const ChangeContainerTypeForm = (props: Props) => {
  const { onClose, onSuccess, id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [bulkUpdateContainerFields, { data, error, loading }] = useMutation(
    BULK_UPDATE_CONTAINER_FIELDS
  );
  const [fetchContainers, { data: containersData }] = useLazyQuery(FETCH_CONTAINERS_FOR_EDIT, {
    fetchPolicy: 'no-cache',
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (id.length > 0) {
      fetchContainers({ variables: { ids: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (containersData?.fetch_basic_containers_details_by_ids) {
      for (const container of containersData.fetch_basic_containers_details_by_ids) {
        const is_disabled = getIsContainerDisabled(container);

        if (is_disabled) {
          let messageStr = '';
          messageStr =
            'Not allowed to update container type as its linked order/shipment is in canceled/closed or document released stage';
          message.error(messageStr);
          setIsOpen(false);
          onClose && onClose();
          return;
        }
      }
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containersData]);

  useEffect(() => {
    if (data) {
      message.success('Container type updated successfully');
      onClose && onClose();
      onSuccess && onSuccess();
      setIsOpen(false);
    }
    if (error) {
      message.error('Failed to update container type');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        onClose && onClose();
      }}
      footer={
        <DrawerFooter
          saveText="Update"
          onClose={() => onClose && onClose()}
          onSave={() => form.submit()}
          loading={loading}
        />
      }
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => {
          handleValuesChange(changedValues, allValues, form);
        }}
        onFinish={(values: any) => {
          const containers = props.id.map((id) => {
            return {
              id: id,
              container_type_code: values.container_type_code,
              container_type: containerTypes[values.container_type_code],
              container_settings: _omit(values, ['container_type_code', 'container_settings']),
            };
          });

          bulkUpdateContainerFields({
            variables: {
              containers: containers,
            },
          });
        }}
      >
        <Form.Item label="Container Type" name={'container_type_code'} required>
          <Select
            showSearch
            optionFilterProp="label"
            placeholder="select container type"
            options={containerTypesFlatArray}
          />
        </Form.Item>
        <Form.Item name="container_settings">
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.container_type_code !== currentValues.container_type_code
            }
          >
            {({ getFieldValue }) => {
              const container_type_code_value = getFieldValue('container_type_code');
              const is_reefer = Object.keys(containerSettingsMap.reefer).includes(
                container_type_code_value || ''
              );
              const is_out_of_dimension = Object.keys(
                containerSettingsMap.out_of_dimension
              ).includes(container_type_code_value || '');

              if (is_reefer && !form.getFieldValue('is_active_reefer'))
                form.setFieldsValue({
                  is_active_reefer: true,
                  ventilation_requested: true,
                  humidity_control_requested: true,
                  temperature_unit: TEMPERATURE_UNIT_CELSIUS,
                  air_flow_unit: AIRFLOW_UNIT_CBM_PER_HR,
                  controlled_atmosphere_requested: false,
                  genset_requested: false,
                });
              else if (is_out_of_dimension && !form.getFieldValue('lbh_unit'))
                form.setFieldsValue({ lbh_unit: DIMENSION_CMS });

              return (
                <ContainerSettingContent
                  is_reefer={is_reefer}
                  is_out_of_dimension={is_out_of_dimension}
                />
              );
            }}
          </Form.Item>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ChangeContainerTypeForm;
