/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';
import UserAccount from './UserAccount';
import DocForm from './DocForm';

const Template = types.model({
  id: types.identifier,
  content: types.string,
  name: types.string,
  created_by: types.maybe(UserAccount),
  template_type: types.string,
  is_standard: types.maybe(types.maybeNull(types.boolean)),
  is_default: types.maybe(types.maybeNull(types.boolean)),
  is_disabled: types.maybe(types.maybeNull(types.boolean)),
  resource_type: types.maybe(types.maybeNull(types.string)),
  print_option: types.maybe(types.maybeNull(DocForm)),
});

export interface TemplateValue extends Instance<typeof Template> {}
export interface TemplateIn extends SnapshotIn<typeof Template> {}
export interface TemplateOut extends SnapshotOut<typeof Template> {}

export default Template;
