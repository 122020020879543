import React from 'react';
import { Route, Switch } from 'wouter';

import CreateContract from './CreateContract';
import DuplicateContract from './DuplicateContract';

const RateManagementLayout = () => {
  return (
    <Switch>
      <Route path="/contract" nest>
        <Route path="/:type/:id">
          <CreateContract />
        </Route>
        <Route path="/:type/duplicate/:id">
          <DuplicateContract />
        </Route>
        <Route path="/:type/amend/:id">
          <DuplicateContract amend />
        </Route>
      </Route>
    </Switch>
  );
};

export default RateManagementLayout;
