import { Col, Form, GlobalSearch, Row } from '@shipmnts/pixel-hub';
import { RichTextEditorWrapper } from 'common';
import { TERMS_AND_COND_TEMPLATE_TYPE } from 'operations/baseConstants';
import React from 'react';

interface TermsSectionProps {
  editable: boolean;
  resource_type: string;
  extraToolbarOptions?: { [key: string]: any };
  searchBoxStyles?: React.CSSProperties;
  editorStyles?: React.CSSProperties;
  formNameForSearch?: string;
  formLabelForSearch?: string;
  formNameForDetails?: string;
  prefill?: boolean;
}

const TermsSection = (props: TermsSectionProps) => {
  const {
    editable,
    resource_type,
    extraToolbarOptions = {},
    searchBoxStyles = {},
    editorStyles = {},
    formNameForSearch = 'terms_and_conditions',
    formLabelForSearch = 'Terms and Conditions',
    formNameForDetails = 'terms_and_conditions_details',
    prefill = false, // based on resource_type, will prefill first template
  } = props;

  return (
    <>
      <Row gutter={16}>
        <Col md={12} lg={6}>
          <Form.Item name={formNameForSearch} label={formLabelForSearch} style={searchBoxStyles}>
            <GlobalSearch
              doc_type="Network::Template"
              searchProps={{
                template_types: [TERMS_AND_COND_TEMPLATE_TYPE],
                resource_type: resource_type,
              }}
              extraProps={{
                prefillDefault: prefill,
              }}
              showCreation={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24} lg={24}>
          <Form.Item
            labelCol={{ span: 24 }}
            name={formNameForDetails}
            style={{ width: '100%', ...editorStyles }}
          >
            <RichTextEditorWrapper
              disabled={!editable}
              height={'200px'}
              toolbarOptions={{
                container: [
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  [{ color: [] }, { background: [] }],
                  ['emoji'],
                  [{ indent: '-1' }, { indent: '+1' }],
                ],
                ...extraToolbarOptions,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TermsSection;
