import { gql } from '@apollo/client';
import { trackingEventFields } from 'operations/graphql/trackingEvent';
import { containerSettingsFields } from './containerRequest';

const containerCargoDetailsFields = gql`
  fragment containerCargoDetailsFields on ContainerCargoDetailObjectType {
    gross_volume
    gross_weight
    id
    net_weight
    package_str
    shipment_container_id
    shipment_id
    total_number_of_packages
    type_of_package
  }
`;

const commonShipmentFragmentFields = gql`
  fragment commonShipmentFragmentFields on ShipmentObject {
    id
    job_number
    accounting_status
    status
    shipment_type
    load_type
    gross_weight
    net_weight
    gross_volume
    total_number_of_packages
    shipment_documents {
      id
      shipment_number
      document_status
    }
  }
`;

const containerSettingsFragmentFields = gql`
  fragment containerSettingsFragmentFields on ContainerSettingsObjectType {
    is_active_reefer
    temperature
    temperature_unit
    ventilation_requested
    air_flow
    air_flow_unit
    humidity_control_requested
    relative_humidity_percent
    vent_setting
    additional_instructions
    genset_requested
    controlled_atmosphere_requested
    oxygen_level_percent
    nitrogen_level_percent
    carbon_dioxide_level_percent
    length
    width
    height
    lbh_unit
  }
`;

const containerMinimumFields = gql`
  fragment containerMinimumFields on ShipmentContainerObject {
    id
    container_type_code
    container_type
    maximum_cargo_weight
    maximum_cargo_volume
    container_number
    is_non_iso_container
    carrier_seal_number
    cargo_gross_weight
    verified_gross_mass
    weight_slip_number
    cargo_net_weight
    commercial_invoice_number
    purchase_order_number
    last_action_status
    shipper_seal_number
    customs_seal_number
    container_cargo_details {
      id
      gross_weight
      net_weight
    }
  }
`;

export const chargeTermFields = gql`
  fragment chargeTermFields on ChargeTermObject {
    destination_charges_terms
    due_agent_charges_terms
    due_carrier_terms
    freight_terms
    origin_charges_terms
    other_charges_terms
  }
`;

export const stockOrderItemFields = gql`
  fragment stockOrderItemFields on StockOrderItemObject {
    awb_number
    id
    next_actions
    shipment_document_id
    status
    stock_order {
      id
      supplier_company {
        id
        registered_name
      }
      billing_branch {
        id
      }
    }
  }
`;

export const shipmentDocumentFields = gql`
  fragment shipmentDocumentFields on ShipmentDocumentObject {
    bl_type
    document_id
    document_status
    eawb_status
    document_type
    shipment_id
    shipment_number
    shipment_date
    house_number_sequence_id
    id
    release_type
    charge_terms {
      ...chargeTermFields
    }
    stock_order_item {
      ...stockOrderItemFields
    }
    shipper_on_document
    consignee_on_document
    is_surrendered
    surrender_location
    si_filing_date
    email_activities {
      id
      purpose
    }
  }
  ${chargeTermFields}
  ${stockOrderItemFields}
`;

export const minimumShipmentField = gql`
  fragment minimumShipmentField on ShipmentObject {
    id
    job_number
    shipment_type
    freight_type
    trade_type
    load_type
    accounting_status
    last_action_status
    customer_company {
      id
      registered_name
    }
    split_from_order_id
    shipment_documents {
      ...shipmentDocumentFields
    }
    allocation_pending_quantity
    shipment_containers {
      ...containerMinimumFields
      container_settings {
        ...containerSettingsFragmentFields
      }
    }
    shipment_booking_number
  }
  ${shipmentDocumentFields}
  ${containerMinimumFields}
  ${containerSettingsFragmentFields}
`;

const containerEditFields = gql`
  fragment containerEditFields on ShipmentContainerObject {
    id
    container_number
    is_non_iso_container
    container_type
    container_type_code
    container_settings {
      is_active_reefer
      temperature
      temperature_unit
      ventilation_requested
      air_flow
      air_flow_unit
      humidity_control_requested
      relative_humidity_percent
      vent_setting
      additional_instructions
      genset_requested
      controlled_atmosphere_requested
      oxygen_level_percent
      nitrogen_level_percent
      carbon_dioxide_level_percent
      length
      width
      height
      lbh_unit
    }
    cargo_gross_volume
    cargo_gross_weight
    cargo_net_weight
    verified_gross_mass
    carrier_seal_number
    acceptance_date
    shipper_seal_number
    customs_seal_number
    remarks
    commercial_invoice_number
    purchase_order_number
    container_tare_weight
    booking_request_id
    booking_number
    job_number
    ocean_transport_order_ids
    container_cargo_details {
      id
      gross_volume
      gross_weight
      net_weight
      package_str
      total_number_of_packages
      type_of_package
      shipment {
        id
        job_number
        shipment_type
        trade_type
        accounting_status
        status
        shipment_documents {
          id
          shipment_number
          document_status
        }
      }
    }
    containers_commercial_invoices {
      id
      shipment_invoice {
        id
        invoice_number
        status
        product_order_items {
          gross_weight
          net_weight
          linked_to {
            product_order {
              purchase_order_number
            }
          }
        }
      }
    }
    master_shipment {
      id
      job_number
      accounting_status
      status
      shipment_type
      load_type
      shipment_documents {
        id
        shipment_number
        document_status
      }
      house_shipments {
        id
        job_number
        accounting_status
        status
        shipment_type
        load_type
        shipment_documents {
          id
          shipment_number
          document_status
        }
      }
    }
  }
`;

export const shipmentContainerFields = gql`
  fragment shipmentContainerFields on ShipmentContainerObject {
    id
    cargo_gross_volume
    cargo_gross_weight
    cargo_net_weight
    maximum_cargo_weight
    carrier_seal_number
    acceptance_date
    container_number
    container_type
    container_type_code
    container_tare_weight
    remarks
    commercial_invoice_number
    purchase_order_number
    container_settings {
      ...containerSettingsFields
    }
    container_cargo_details {
      ...containerCargoDetailsFields
    }
    booking_number
    job_number
    ocean_transport_order_ids
    customs_seal_number
    shipper_seal_number
    is_non_iso_container
    booking_request_id
    road_transport_order_ids
    weight_slip_number
    status
    verified_gross_mass
    tracking_events {
      ...trackingEventFields
    }
    last_action_status
    tracking_statuses {
      ldb_tracker {
        id
        last_event {
          name
          estimated_date
          current_location
          actual_date
        }
        last_tracked_at
        status
      }
    }
    next_actions
    last_comment
    last_status_update
    custom_field_values
    surveyor_company_id
    surveyor_address_id
    eway_bill_no
    eway_bill_validity
  }
  ${containerCargoDetailsFields}
  ${containerSettingsFields}
  ${trackingEventFields}
`;

export const UPDATE_CONTAINER_PICKUP_DETAILS = gql`
  mutation update_container_pickup_details(
    $shipment_containers: [ContainerPickupInputType!]!
    $road_transport_order_id: ID
  ) {
    update_container_pickup_details(
      shipment_containers: $shipment_containers
      road_transport_order_id: $road_transport_order_id
    ) {
      shipment_containers {
        ...shipmentContainerFields
      }
    }
  }
  ${shipmentContainerFields}
`;

export const SHIFT_CONTAINERS = gql`
  mutation shift_containers($ocean_transport_order_id: ID!, $shipment_container_ids: [ID!]!) {
    shift_containers(
      ocean_transport_order_id: $ocean_transport_order_id
      shipment_container_ids: $shipment_container_ids
    ) {
      message
    }
  }
`;

export const VALIDATE_CONTAINERS_OFFLOAD = gql`
  mutation validate_containers_offload($shipment_container_ids: [ID!]!, $customer_company_id: ID!) {
    validate_containers_offload(
      shipment_container_ids: $shipment_container_ids
      customer_company_id: $customer_company_id
    ) {
      messages
      create_shipment_needed
    }
  }
`;

export const VALIDATE_ROLLOVER_SHUTOUT = gql`
  mutation validate_rollover_shutout($shipment_container_ids: [ID!]!) {
    validate_rollover_shutout(shipment_container_ids: $shipment_container_ids) {
      messages
      action_to_be_done
    }
  }
`;

export const FETCH_SHIPMENT_CONTAINERS = gql`
  query fetch_shipment_containers($shipment_ids: [ID!]!) {
    fetch_shipment_containers(shipment_ids: $shipment_ids) {
      ...shipmentContainerFields
      container_cargo_details {
        ...containerCargoDetailsFields
      }
    }
  }
  ${shipmentContainerFields}
  ${containerCargoDetailsFields}
`;

export const FETCH_CONTAINER = gql`
  query fetch_container($id: ID!) {
    fetch_container(id: $id) {
      id
      customer_company {
        id
        registered_name
        logo
      }
      customer_address {
        id
        name
        print_address
        company_id
      }
      next_actions
      last_action_status
      container_number
      remarks
      commercial_invoice_number
      purchase_order_number
      container_cargo_details {
        shipment_id
        shipment {
          ...minimumShipmentField
        }
      }
      carrier_seal_number
      acceptance_date
      shipper_seal_number
      cargo_gross_weight
      cargo_net_weight
      verified_gross_mass
      origin_detention_free_days
      ocean_transport_order_id
      status
    }
  }
  ${minimumShipmentField}
`;

export const FETCH_CONTAINER_FOR_EDIT = gql`
  query fetch_basic_container_details_by_id($id: ID!) {
    fetch_basic_container_details_by_id(id: $id) {
      id
      surveyor_company {
        id
        registered_name
      }
      surveyor_address {
        id
        print_address
      }
      container_number
      is_non_iso_container
      ocean_transport_orders {
        id
      }
      container_type
      container_type_code
      container_settings {
        is_active_reefer
        temperature
        temperature_unit
        ventilation_requested
        air_flow
        air_flow_unit
        humidity_control_requested
        relative_humidity_percent
        vent_setting
        additional_instructions
        genset_requested
        controlled_atmosphere_requested
        oxygen_level_percent
        nitrogen_level_percent
        carbon_dioxide_level_percent
        length
        width
        height
        lbh_unit
      }
      cargo_gross_volume
      cargo_gross_weight
      cargo_net_weight
      verified_gross_mass
      carrier_seal_number
      acceptance_date
      shipper_seal_number
      customs_seal_number
      remarks
      commercial_invoice_number
      purchase_order_number
      container_tare_weight
      container_cargo_details {
        id
        gross_volume
        gross_weight
        net_weight
        package_str
        total_number_of_packages
        type_of_package
        shipment {
          id
          job_number
          shipment_type
          trade_type
          freight_type
          accounting_status
          status
          shipment_documents {
            id
            shipment_number
            document_status
          }
        }
      }
      containers_commercial_invoices {
        id
        shipment_invoice {
          id
          invoice_number
          status
          product_order_items {
            gross_weight
            net_weight
            linked_to {
              product_order {
                purchase_order_number
              }
            }
          }
        }
      }
      master_shipment {
        ...commonShipmentFragmentFields
        house_shipments {
          ...commonShipmentFragmentFields
        }
        trade_type
        services {
          destination {
            clearance
            transport
          }
          freight_forwarding
          cargo_insurance
          empty_container_insurance
          origin {
            clearance
            fumigation
            palletization
            transport
          }
        }
      }
      status
    },
    ${commonShipmentFragmentFields}
  }
`;

export const FETCH_CONTAINERS_FOR_EDIT = gql`
  query fetch_basic_containers_details_by_ids($ids: [ID!]!) {
    fetch_basic_containers_details_by_ids(ids: $ids) {
      ...containerEditFields
    }
  }
  ${containerEditFields}
`;
