import React from 'react';
import { Column } from 'common';
import { DISABLE_CELL_STYLE, RequireHeaderWrapper } from './helpers';
import {
  CellClassParams,
  EditableCallbackParams,
  GridOptions,
  RowNode,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { QtyUomTypeRenderer } from 'common';
import { disableFutureDate } from '@shipmnts/pixel-hub';
import {
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  BaseTable,
  SearchDocTypeEditor,
  RecordLoaderRender,
} from '@shipmnts/pixel-hub';
import ContainerSettings from 'operations/components/ContainerSettings';
import { WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS } from 'operations/baseConstants';
import { ShipmentContainerValue } from 'operations/models/ShipmentContainer';
import allFields from './allFields';
import { LRSuggestionType } from './assignLoadHelpers';

export interface ShipmentContainerData extends ShipmentContainerValue {
  link_order_no?: string | null;
  is_single_load?: boolean | null;
}

type Props = {
  containers: ShipmentContainerData[];
  tripOrderNo: string[];
  gridRef: React.MutableRefObject<GridOptions<any> | undefined>;
  setContainers: React.Dispatch<React.SetStateAction<any[]>>;
  isMatchingSelected?: boolean;
  isOrderAssignment?: boolean;
  isAddNewHouse?: boolean;
  lrSuggestions?: LRSuggestionType[];
  isAutoGenerateLR?: boolean;
};

type ContainerSettingsRendererProps = {
  rowIndex: number;
  containers: ShipmentContainerData[];
  setContainers: React.Dispatch<React.SetStateAction<any[]>>;
  node?: RowNode<any>;
};
function ContainerSettingsRenderer({
  rowIndex,
  containers,
  setContainers,
  node,
}: ContainerSettingsRendererProps) {
  if (node && node.isRowPinned()) return <></>;
  return (
    <ContainerSettings
      value={containers[rowIndex]?.container_settings}
      container_type_code={containers[rowIndex]?.container_type_code || ''}
      onChange={(value) => {
        setContainers((containers: any[]) => {
          const updatedContainers = containers.map((container, index: number) => {
            if (index === rowIndex) {
              return { ...container, container_settings: value };
            }
            return container;
          });
          return updatedContainers;
        });
      }}
    />
  );
}

function CreateAndAssignContainerLoad(props: Props) {
  const {
    containers,
    tripOrderNo,
    gridRef,
    setContainers,
    isMatchingSelected,
    isOrderAssignment = false,
    isAddNewHouse = false,
    lrSuggestions = [],
    isAutoGenerateLR = false,
  } = props;
  const components = {
    DateEditor,
    StringEditor,
    FloatEditor,
    EnumEditor,
    QtyUomTypeRenderer: QtyUomTypeRenderer,
    SearchDocTypeEditor,
    RecordLoaderRender,
    ContainerSettingsRenderer,
  };

  const columnDefs: Column[] = [];
  if (isMatchingSelected) {
    columnDefs.push(allFields.link_order_no({ cellStyle: DISABLE_CELL_STYLE }));
  }
  if (isOrderAssignment) {
    columnDefs.push(
      allFields.vehicle_load_search({
        tripOrderNo,
        headerComponent: () => <RequireHeaderWrapper text="Assign Trip / Vehicle" />,
      })
    );
  }

  if ((isAddNewHouse || !isOrderAssignment) && lrSuggestions.length) {
    columnDefs.push(
      allFields.lr_search({
        lrSuggestions: lrSuggestions,
        editable: (params: EditableCallbackParams<any>) => {
          if (params.node.isRowPinned() || isAddNewHouse || isAutoGenerateLR) return false;
          return true;
        },
        cellStyle: (params: CellClassParams<any, any>) => {
          if (params?.node?.isRowPinned()) return;
          const isEditable = params.colDef?.editable;
          if (!isEditable) return DISABLE_CELL_STYLE;
          return;
        },
      })
    );
  }

  columnDefs.push(
    allFields.is_non_iso_container(),
    allFields.container_number({
      editable: (o: { node: { isRowPinned: () => any } }) =>
        !o.node.isRowPinned() || !isAddNewHouse,
    }),
    allFields.invoice_number({
      editable: false,
      cellStyle: isAddNewHouse && DISABLE_CELL_STYLE,
      floatingFilter: true,
      field: 'commercial_invoice_number',
    }),
    allFields.invoice_date({
      editable: false,
      cellStyle: isAddNewHouse && DISABLE_CELL_STYLE,
    }),
    allFields.carrier_seal_number(),
    allFields.weight_unit({
      editable: (params: { node: { isRowPinned: () => any } }) =>
        !params.node.isRowPinned() || !isAddNewHouse,
      valueGetter: (params: { data: { weight_unit: any } }) => {
        const value = params.data.weight_unit;
        if (!value) return WEIGHT_UNIT_KGS;
        return value;
      },
      cellStyle: (params: CellClassParams<any>) => {
        const isEditable = params.colDef?.editable;
        if (!isEditable) return DISABLE_CELL_STYLE;
        return null;
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data.weight_unit = params.newValue || WEIGHT_UNIT_MTS;
        return true;
      },
    }),
    allFields.cargo_gross_weight({
      editable: (o: { node: { isRowPinned: () => any } }) =>
        !o.node.isRowPinned() || !isAddNewHouse,
    }),

    allFields.container_type(),
    allFields.pickup_date({ disableFutureDate }),
    allFields.eway_bill_no({
      editable: (o: { node: { isRowPinned: () => any } }) =>
        !o.node.isRowPinned() || !isAddNewHouse,
    }),
    allFields.eway_bill_validity({
      editable: (o: { node: { isRowPinned: () => any } }) =>
        !o.node.isRowPinned() || !isAddNewHouse,
    }),
    allFields.container_settings({ containers, setContainers })
  );

  return (
    <>
      <BaseTable
        reportName={`container_selection_${lrSuggestions.length}_${isOrderAssignment}_${isAddNewHouse}`}
        columns={columnDefs}
        onGridReady={(grid: any) => {
          gridRef?.current?.api?.startEditingCell({
            rowIndex: 0,
            colKey: 'assign_vehicle_load',
          });
          grid?.columnApi?.autoSizeAllColumns();
        }}
        rowSelection={!isOrderAssignment ? 'multiple' : undefined}
        allowProcessDataFromClipboard={true}
        gridRef={gridRef}
        rowData={containers || []}
        reportConfig={{
          floatingFiltersHeight: 35,
          isRowSelectable: (node: any) => !(isAddNewHouse && node.data.is_single_load),
          components: components,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          suppressClipboardApi: true,
          defaultColDef: {
            flex: 1,
            minWidth: 100,
            resizable: true,
            suppressMenu: true,
          },
          enableRangeSelection: true,
        }}
        checkbox_always_visible={isAddNewHouse ? true : false}
      />
    </>
  );
}

export default CreateAndAssignContainerLoad;
