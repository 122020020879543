import React, { useEffect } from 'react';
import { Button, message } from '@shipmnts/pixel-hub';
import { UPSERT_COMPANY } from '../graphql/company';
import { useMutation } from '@apollo/client';
import { CompanyValue, CompanyAccountsData } from 'network/models/Company';

const DisableAddress = React.memo(function DisableAddress(props: {
  company_id?: string;
  address_id: string;
  flag: boolean;
  onSave: (upsert_company: {
    company?: CompanyValue;
    company_accounts_data?: CompanyAccountsData;
  }) => void;
  children: React.ReactNode;
}): JSX.Element {
  const { company_id, address_id, flag, children, onSave } = props;

  const [upsertCompany, { data: upsertCompanyData, error: upsertCompanyError, loading }] =
    useMutation(UPSERT_COMPANY);

  useEffect(() => {
    if (upsertCompanyError) {
      message.error({
        content: upsertCompanyError?.graphQLErrors.map((e) => e.message),
        key: 'upsert_company',
      });
    }
    if (!upsertCompanyError && upsertCompanyData?.upsert_company) {
      message.success({
        content: `Address ${flag ? 'Disabled' : 'Enabled'}`,
        key: 'upsert_company',
        duration: 2,
      });
      onSave(upsertCompanyData?.upsert_company);
    }
  }, [flag, upsertCompanyData, upsertCompanyError, onSave]);

  const setAddressDisable = () => {
    const payload = {
      id: company_id,
      addresses: [
        {
          id: address_id,
          is_disabled: flag,
        },
      ],
    };
    upsertCompany({ variables: { company: payload } });
  };

  return (
    <Button onClick={setAddressDisable} danger={flag} loading={loading}>
      {children}
    </Button>
  );
});

export default DisableAddress;
