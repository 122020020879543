import React, { useState, useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
// import { TrackingReferenceType } from '../shipment/components/Tracking/types'
import { DOC_TYPE_METHODS } from 'common/baseConstants';
import { RenderEventsForm, useSession } from 'common';
import { CustomIcon } from '@shipmnts/pixel-hub';

const BulkEventActionsHandler = React.memo(function BulkEventActionsHandler(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  action_name: string | undefined;
  doctype_id: string;
}): JSX.Element {
  const { selectedNodes, onSuccess, selectedNodeCounts, action_name, onClose, doctype_id } = props;
  const [activeAction, setActiveAction] = useState<string | undefined>(undefined);
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>();
  const session = useSession();

  let referenceType: any = 'Shipment::ShipmentContainer';
  if (DOC_TYPE_METHODS[doctype_id].show_tracker_drawer) {
    referenceType = doctype_id as any;
  }

  useEffect(() => {
    if (selectedNodeCounts > 0) {
      const ids = selectedNodes.map((i) => i.data.id.toString());
      setSelectedDocIds(ids);
      setActiveAction(action_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes, selectedNodeCounts]);

  return (
    <>
      {selectedDocIds && activeAction && referenceType && (
        <RenderEventsForm
          type="UPDATE"
          referenceType={referenceType}
          id={selectedDocIds}
          onSuccess={onSuccess}
          onClose={onClose}
          eventName={activeAction}
          session={session}
        />
      )}
    </>
  );
});

export const bulkEventActionsRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: any,
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      action_name: extraProps.action_name,
      doctype_id: extraProps.doctype_id,
    },
    component: BulkEventActionsHandler,
  };
};

export const getNextPossibleAction = (selectedNodes: RowNode[]): string[] => {
  const selectedData = selectedNodes.map((node) => node.data);
  let nextActions: string[] = selectedData[0].next_actions || [];
  const hasEmptyContainerNumber = selectedData.some((row) => !row.container_number);
  selectedData.forEach((row) => {
    nextActions = (nextActions || []).filter((action: string) =>
      (row.next_actions || []).includes(action)
    );
  });

  if (hasEmptyContainerNumber)
    nextActions = nextActions.filter((action) => action !== 'Empty Picked Up');

  return nextActions;
};

//TODO: move to communication > events and milestones
//TODO: Icons should be based on event/action
export const GetIconForAction = (props: { eventName: string }) => {
  return <CustomIcon icon={'FileLockIcon'} width={15} height={15} />;
};
