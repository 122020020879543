import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form } from '@shipmnts/pixel-hub';
import {
  AIRFLOW_UNIT_CBM_PER_HR,
  containerSettingsMap,
  DIMENSION_CMS,
  TEMPERATURE_UNIT_CELSIUS,
} from 'operations/baseConstants';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import ContainerSettingContent from './ContainerSettingContent';
import { handleValuesChange } from './ContainerSettingHelper';

interface ContainerSettingsProps {
  value?: ContainerSettingValue;
  onChange?: (value: ContainerSettingValue) => void;
  container_type_code?: string;
  disabled?: boolean;
}

const ContainerSettings = React.memo(function ContainerSettings(
  props: ContainerSettingsProps
): JSX.Element {
  const { value } = props;
  const { disabled } = props;
  const [form] = Form.useForm();
  const didMountRef = useRef(false);
  const { onChange, container_type_code } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const is_reefer = Object.keys(containerSettingsMap.reefer).includes(container_type_code || '');
  const is_out_of_dimension = Object.keys(containerSettingsMap.out_of_dimension).includes(
    container_type_code || ''
  );

  let title = 'Add Container Settings';
  if (is_reefer) {
    if (value && value.is_active_reefer) {
      title = `Active ${
        value.temperature !== undefined && value.temperature !== null ? value.temperature : ''
      } ${value.temperature_unit || ''}`;
    } else {
      title = 'Add Reefer Settings';
    }
  } else if (is_out_of_dimension) {
    if (value && (value.length || value.width || value.height)) {
      title = `${value.length || ''}x${value.width || ''}x${value.height || ''} ${
        value.lbh_unit || ''
      }`;
    } else {
      title = 'Add Out Of Dimension Settings';
    }
  }

  const closeDrawer = () => {
    form.resetFields();
    setOpenDrawer(false);
  };

  useEffect(() => {
    // only reset on update... not on initial mount
    if (didMountRef.current && onChange) {
      if (is_reefer)
        onChange({
          is_active_reefer: true,
          ventilation_requested: true,
          humidity_control_requested: true,
          temperature_unit: TEMPERATURE_UNIT_CELSIUS,
          air_flow_unit: AIRFLOW_UNIT_CBM_PER_HR,
          controlled_atmosphere_requested: false,
          genset_requested: false,
        } as ContainerSettingValue);
      else if (is_out_of_dimension) onChange({ lbh_unit: DIMENSION_CMS } as ContainerSettingValue);
      else onChange({} as ContainerSettingValue);
    }
    didMountRef.current = true;
    // onChange changes every render... to avoid infinite
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_out_of_dimension, is_reefer]);

  return (
    <div>
      <Button
        disabled={(!is_reefer && !is_out_of_dimension) || disabled}
        size="small"
        style={{ width: '164px', whiteSpace: 'nowrap', overflow: 'hidden' }}
        onClick={() => setOpenDrawer(true)}
      >
        {title}
      </Button>
      {openDrawer && (
        <Form
          name="container_settings"
          form={form}
          scrollToFirstError
          onFinish={(values) => {
            if (onChange) onChange(values as ContainerSettingValue);
            closeDrawer();
          }}
          layout="vertical"
          initialValues={value}
          onValuesChange={(changedValues, allValues) => {
            handleValuesChange(changedValues, allValues, form);
          }}
        >
          <Drawer
            title={`Container Settings`}
            width="40%"
            onClose={closeDrawer}
            open={openDrawer}
            footer={
              <DrawerFooter saveText="Save Settings" onClose={closeDrawer} onSave={form.submit} />
            }
          >
            {(is_reefer || is_out_of_dimension) && (
              <ContainerSettingContent
                value={value}
                is_reefer={is_reefer}
                is_out_of_dimension={is_out_of_dimension}
                onChange={onChange}
              />
            )}
          </Drawer>
        </Form>
      )}
    </div>
  );
});

export default ContainerSettings;
