import {
  Checkbox,
  Form,
  FormInstance,
  FormTable,
  EnumEditor,
  FloatEditor,
  SearchDocTypeEditor,
} from '@shipmnts/pixel-hub';
import { CompanySearch } from 'common';
import React, { useEffect, useRef } from 'react';
import { GridOptions } from '@ag-grid-community/core';
import { SERVICES_NAME } from '../constants';
import { Column } from 'operations/models/Report';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface ShipmentServiceDetailsProps {
  onChange?: (data: any[]) => void;
  value?: any[];
  editable: boolean;
  form: FormInstance;
}

export default function ShipmentServiceDetails(props: ShipmentServiceDetailsProps) {
  const gridRef = useRef<GridOptions>();
  const { value, onChange, editable, form } = props;
  const tradeType = Form.useWatch('trade_type', form);
  useEffect(() => {
    const data: any[] = [];
    gridRef.current?.api?.forEachNode((node) => {
      if (
        node?.data?.name === 'origin_clearance_agent' ||
        node?.data?.name === 'destination_clearance_agent'
      )
        data.push({
          ...node.data,
          is_required:
            node?.data?.name === 'origin_clearance_agent'
              ? tradeType === 'export'
                ? true
                : false
              : tradeType === 'import'
              ? true
              : false,
        });
      else data.push(node.data);
    });
    gridRef.current?.api?.setRowData(data);
  }, [tradeType]);

  const columnDefs: Column[] = [
    {
      headerName: 'Service Name',
      field: 'name',
      colId: 'name',
      editable: true,
      valueFormatter: (params: any) => {
        let value = '';
        SERVICES_NAME.forEach((service) => {
          if (service.value === params.data.name) value = service.label;
        });
        return value;
      },
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: (params: any) => {
        return {
          options: SERVICES_NAME.filter(
            (service: any) => !value?.map((val) => val.name).includes(service.value)
          ),
        };
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.name = params.newValue;
        params.api.refreshCells({
          force: true,
          columns: ['party_company', 'party_address'],
          rowNodes: [params.node],
        });
        return true;
      },
      lockVisible: true,
      lockPosition: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 150,
      maxWidth: 350,
    },
    {
      headerName: 'Required',
      field: 'is_required',
      colId: 'is_required',
      cellRenderer: function checkboxRenderer(params: any) {
        if (params.node.isRowPinned()) return <></>;
        return (
          <Checkbox
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            checked={params.data.is_required}
            onChange={(e) => {
              params.data.is_required = e.target.checked;
              params.api.refreshCells({
                force: true,
                columns: ['is_required'],
                rowNodes: [params.node],
              });
            }}
          />
        );
      },
      editable: false,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Service Provider Name',
      field: 'party_company',
      columnType: 'String',
      valueFormatter: (params: any) => params.value?.registered_name,
      minWidth: 200,
      maxWidth: 200,
      editable: (params: any) => {
        return editable && params.data.is_required;
      },
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: CompanySearch,
        componentProps: {
          selectMode: 'single',
        },
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.party_company = params.newValue;
        params.data.party_address =
          params?.newValue?.addresses?.length === 1 ? params.newValue.addresses[0] : undefined;
        return true;
      },
      keyCreator: (params: any) => params?.party_company?.id,
      lockVisible: true,
      cellStyle: (params: any) => {
        if (['to_order', 'to_order_bank'].includes(params.data.name))
          return { 'background-color': '#f2f2f2' };
        return;
      },
    },
    {
      headerName: 'Service Provider Address',
      field: 'party_address',
      columnType: 'String',
      valueFormatter: (params: any) => {
        return params.value?.print_address || '';
      },
      editable: (params: any) => {
        return editable && params.data.is_required;
      },
      suppressKeyboardEvent: (params: any) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: (params: any) => {
        return {
          CustomComponent: GlobalSearch,
          componentProps: {
            doc_type: 'Network::Address',
            selectMode: 'single',
            searchProps: {
              company_id: params?.data?.party_company?.id,
            },
          },
        };
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.party_address = params.newValue;
        return true;
      },
      cellStyle: (params: any) => {
        if (['to_order', 'to_order_bank'].includes(params.data.name))
          return { 'background-color': '#f2f2f2' };
        return;
      },
      keyCreator: (params: any) => params?.party_address?.id,
      lockVisible: true,
      minWidth: 450,
      maxWidth: 450,
    },
  ];

  const components = {
    FloatEditor: FloatEditor,
    EnumEditor: EnumEditor,
    SearchDocTypeEditor: SearchDocTypeEditor,
  };

  const onCellValueChanged = (cell: any) => {
    const data = value || [];
    data[cell.rowIndex] = {
      ...cell.data,
    };
    onChange && onChange(data);
  };

  return (
    <div>
      <FormTable
        reportName="services"
        columns={columnDefs}
        gridRef={gridRef}
        rowData={value || []}
        height={'55vh'}
        // rowSelection={'multiple'}
        checkbox_always_visible={false}
        reportConfig={{
          components,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 50,
          stopEditingWhenCellsLoseFocus: true,
          suppressClipboardApi: true,
          // isRowSelectable: isRowSelectable,
          defaultColDef: {
            flex: 1,
            minWidth: 100,
            resizable: true,
          },
          enableRangeSelection: true,
          singleClickEdit: true,
          enableCellChangeFlash: true,
          animateRows: true,
          onCellValueChanged: onCellValueChanged,
        }}
      />
    </div>
  );
}
