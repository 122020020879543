import React, { useEffect, useRef, useState } from 'react';
import { Drawer, message, BaseTable, DrawerFooter } from '@shipmnts/pixel-hub';
import { GridOptions } from '@ag-grid-community/core';
import { Column } from 'operations/models/Report';
import { useMutation, useQuery } from '@apollo/client';
import { pick as _pick, omit as _omit } from 'lodash';
import { getHeight } from './CommercialInvoice/CommercialInvoiceListTable';
import { ShipmentValue } from 'operations/models/Shipment';
import { FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT } from './graphql/productOrder';
import { UPDATE_SHIPMENT_NEW } from '../shipment/components/ShipmentForm/graphql';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { ActionRendererDetailReport } from 'operations';

interface UpdateProductsQtyFromShipmentProps {
  shipment: ShipmentValue;
  onSuccess?: () => void;
  onClose: () => void;
}

export default function UpdateProductsQtyFromShipment(
  props: UpdateProductsQtyFromShipmentProps
): JSX.Element {
  const { shipment, onSuccess, onClose } = props;

  const [rowData, setRowData] = useState<any>([]);
  const { data: spoiData } = useQuery(FETCH_PRODUCT_ORDER_ITEMS_FROM_SHIPMENT, {
    variables: { id: shipment.id },
  });

  useEffect(() => {
    if (spoiData?.shipment?.cargos.length) {
      setRowData(
        spoiData?.shipment?.cargos.map((spoi: ProductOrderItemValue) => {
          return {
            ..._pick(spoi, [
              'product_name',
              'id',
              'qty',
              'shipment_planned_qty',
              'prioritized_qty',
              'ready_qty',
              'dispatched_qty',
              'shipped_qty',
              'delivered_qty',
            ]),
            order_number:
              shipment?.trade_type === 'export'
                ? spoi?.sales_order_number || spoi.linked_to.product_order.sales_order_number
                : spoi?.purchase_order_number || spoi.linked_to.product_order.purchase_order_number,
            shipment_planned_qty_on_order: spoi?.linked_to?.shipment_planned_qty || 0,
          };
        })
      );
      // setRowData(spoiData?.shipment?.cargos);
    }
  }, [shipment, spoiData]);
  const gridRef = useRef<GridOptions>();
  const [updateShipment, { data, error }] = useMutation(UPDATE_SHIPMENT_NEW);

  useEffect(() => {
    if (data && data?.update_shipment_new?.id) {
      if (onSuccess) onSuccess();
      message.success('Successfully Updated Products Qty !');
    }
    if (error) {
      message.error(error?.message);
    }
  }, [data, error, onSuccess]);

  const onSaveDrawer = () => {
    updateShipment({
      variables: {
        id: shipment?.id,
        shipment: {
          cargos: rowData.map((item: any) =>
            _omit(item, ['order_number', 'shipment_planned_qty_on_order', 'custom_field_values'])
          ),
        },
      },
    });
  };

  const columns: Column[] = [
    {
      headerName: 'Order #',
      field: 'order_number',
      colId: 'order_number',
      columnType: 'String',
      pinned: 'left',
      editable: false,
    },
    {
      headerName: 'Product',
      field: 'product_name',
      colId: 'product_name',
      columnType: 'String',
      editable: false,
    },
    {
      headerName: 'Order Qty',
      field: 'qty',
      colId: 'qty',
      columnType: 'Float',
      editable: false,
    },
    {
      headerName: 'Shipment Planned Qty',
      field: 'shipment_planned_qty',
      colId: 'shipment_planned_qty',
      columnType: 'Float',
      editable: false,
      // valueSetter: (params: any) => {
      //   if (params.newValue <= params.data.qty - params.data.shipment_planned_qty_on_order) {
      //     params.data.shipment_planned_qty = Number(params.newValue);
      //   }
      //   return true;
      // },
    },
    {
      headerName: 'Prioritized Qty',
      field: 'prioritized_qty',
      colId: 'prioritized_qty',
      columnType: 'Float',
      editable: true,
      valueSetter: (params: any) => {
        params.data.prioritized_qty = Number(params.newValue);
        return true;
      },
    },
    {
      headerName: 'Ready Qty',
      field: 'ready_qty',
      colId: 'ready_qty',
      columnType: 'Float',
      editable: true,
      valueSetter: (params: any) => {
        params.data.ready_qty = Number(params.newValue);
        return true;
      },
    },
    {
      headerName: 'Dispatched Qty',
      field: 'dispatched_qty',
      colId: 'dispatched_qty',
      columnType: 'Float',
      editable: true,
      valueSetter: (params: any) => {
        params.data.dispatched_qty = Number(params.newValue);
        return true;
      },
    },
    {
      headerName: 'Shipped Qty',
      field: 'shipped_qty',
      colId: 'shipped_qty',
      columnType: 'Float',
      editable: true,
      valueSetter: (params: any) => {
        params.data.shipped_qty = Number(params.newValue);
        return true;
      },
    },
    {
      headerName: 'Delivered Qty',
      field: 'delivered_qty',
      colId: 'delivered_qty',
      columnType: 'Float',
      editable: true,
      valueSetter: (params: any) => {
        params.data.delivered_qty = Number(params.newValue);
        return true;
      },
    },
  ];

  return (
    <>
      <Drawer
        open={true}
        onClose={onClose}
        title={`Update Products Qty of ${shipment?.job_number}`}
        width={'70%'}
        footer={<DrawerFooter saveText="Update Qty" onSave={onSaveDrawer} onClose={onClose} />}
      >
        <BaseTable
          reportName="update_products_qty"
          columns={columns}
          rowData={rowData}
          gridRef={gridRef}
          reportConfig={{
            rowHeight: 40,
            components: { ActionRendererDetailReport },
          }}
          height={getHeight(rowData) || '200px'}
        />
      </Drawer>
    </>
  );
}
