import React, { useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Table,
  dayjsGenerateConfig,
  DatePicker,
  message,
  Drawer,
  Select,
  Form,
  DrawerFooter,
  disableFutureDate,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { errorMessageHandlerGraphQL } from 'common';
import { OFFLOAD_BY, OFFLOAD_REASONS, STATUS_ACTIVE } from 'operations/modules/reports/constants';
import { LOCATION_TYPE_ICD, LOCATION_TYPE_CFS } from 'operations/models/Location';
import { OFFLOAD_CONTAINERS } from 'operations/modules/reports/graphql/containerReport';
import { COMPANY_STATUS_ARRAY } from 'operations/models/Company';
import { ShipmentValue } from 'operations/models/Shipment';
import { startCase as _startCase } from 'lodash';
import { TRADE_TYPE_IMPORT } from '../constants';

const ROW_GUTTER = 16;

const containerColumns = [
  {
    title: 'Container Number',
    dataIndex: 'container_number',
  },
  {
    title: 'Container Type',
    dataIndex: 'container_type',
  },
];

const OffloadContainers = React.memo(function OffloadContainers(props: {
  shipment: ShipmentValue;
  onClose: () => void;
  onSuccess?: (container_ids: string[], sendEmail: boolean) => void;
}): JSX.Element {
  const { shipment, onClose, onSuccess } = props;

  const [createShipment] = useState<boolean>(false);
  const containers = useMemo(
    () => shipment?.shipment_containers?.filter((container) => container.status === STATUS_ACTIVE),
    [shipment.shipment_containers]
  );
  const [selectedContainerIds, setSelectedContainerIds] = useState(
    containers?.map((container) => container.id)
  );
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const [offloadContainer, { data, loading, error }] = useMutation(OFFLOAD_CONTAINERS);

  useEffect(() => {
    if (!error && data?.offload_containers) {
      message.success('Offloaded Containers Successfully!');
      onClose();
      const events = (data.offload_containers || [])
        .map((c: any) => {
          return c.tracking_events
            .sort(
              (a: { sequence_number: number }, b: { sequence_number: number }) =>
                b.sequence_number - a.sequence_number
            )
            .find((event: any) => event.name === 'Empty Offloaded');
        })
        .filter((event: any) => event != null);
      if (onSuccess) {
        onSuccess(
          events.map((e: any) => e.id),
          sendEmail
        );
      }
    }
  }, [data, error, onSuccess, onClose, sendEmail, selectedContainerIds, containers]);

  const [form] = Form.useForm();

  const saveText = createShipment
    ? 'Mark Offload And Create New Shipment'
    : 'Mark Offload And Add To Existing Shipment';

  const isDetailReqired =
    shipment.trade_type !== TRADE_TYPE_IMPORT && !!shipment.services?.freight_forwarding;

  return (
    <Drawer
      title={'Offload / Cancel'}
      footer={
        <DrawerFooter
          saveText={saveText}
          loading={loading}
          onSave={form.submit}
          showSendEmail
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sendEmailText="Send Offload Confirmation"
          onClose={onClose}
        />
      }
      width={'60%'}
      open={true}
      onClose={onClose}
    >
      <Form
        name="container_offload"
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          const payload = {
            shipment_container_ids: selectedContainerIds,
            offloaded_at: values.offloaded_at.unix(),
            cancelled_by: values.offload_reason.split('----')[0],
            cancellation_reason: values.offload_reason.split('----')[1],
            return_location_id: values.empty_return_location?.id,
            return_expected_at: values.empty_return_by?.unix(),
            customer_company_id: values.billing_party?.id,
            ...(createShipment ? { job_date: values.job_date.unix() } : {}),
          };
          offloadContainer({ variables: payload });
        }}
        initialValues={{
          billing_party: shipment.customer_company,
          offloaded_at: dayjsGenerateConfig.getNow(),
          job_date: dayjsGenerateConfig.getNow(),
        }}
        onValuesChange={(changedValues, allValues) => {
          // TODO: check here
          //   if (changedValues?.billing_party) {
          //     triggerValidate();
          //   }
        }}
      >
        {/* <BookingRequestBasicInfo shipment={shipment} /> */}
        {error && errorMessageHandlerGraphQL(error)}
        <div className="ant-descriptions-title" style={{ padding: '10px' }}>
          Select Containers to offload
        </div>
        <Table
          rowSelection={{
            selectedRowKeys: selectedContainerIds,
            onChange: (selectedRowKeys: any) => setSelectedContainerIds(selectedRowKeys),
          }}
          dataSource={containers?.slice()}
          columns={containerColumns}
          pagination={false}
          rowKey={(record) => record.id || ''}
          size="small"
        />
        <div className="ant-descriptions-title" style={{ padding: '10px' }}>
          Offload Details
        </div>
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="offloaded_at"
              label="Offload Date"
            >
              <DatePicker style={{ width: '100%' }} disabledDate={disableFutureDate} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              label="Select reason to offload"
              name="offload_reason"
            >
              <Select showSearch allowClear={false} placeholder="Select offload reason">
                {OFFLOAD_BY.map((cancelled_by: string) => (
                  <Select.OptGroup key={cancelled_by} label={_startCase(cancelled_by)}>
                    {OFFLOAD_REASONS[cancelled_by].map((option, index) => (
                      <Select.Option
                        key={`${cancelled_by}_${index}`}
                        value={`${cancelled_by}----${option}`}
                      >
                        {_startCase(option)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-descriptions-title" style={{ padding: '10px' }}>
          Empty Return Details
        </div>
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Form.Item
              required={isDetailReqired}
              rules={[{ required: isDetailReqired }]}
              name="empty_return_by"
              label="Empty Return By"
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required={isDetailReqired}
              rules={[{ required: isDetailReqired }]}
              label="Empty Return Location"
              name="empty_return_location"
            >
              <GlobalSearch
                doc_type="Global::Location"
                searchProps={{ type: [LOCATION_TYPE_ICD, LOCATION_TYPE_CFS] }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-descriptions-title" style={{ padding: '10px' }}>
          New Shipment Details
        </div>
        <Row gutter={ROW_GUTTER}>
          <Col span={12} className="custom-margin-form-item">
            <Form.Item
              name="billing_party"
              required={isDetailReqired}
              rules={[{ required: isDetailReqired }]}
              label="Billing Party"
            >
              <GlobalSearch
                doc_type="Network::Company"
                searchProps={{
                  status: COMPANY_STATUS_ARRAY,
                  is_customer: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {createShipment && (
              <Form.Item required rules={[{ required: true }]} name="job_date" label="Job Date">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
});

export default OffloadContainers;
