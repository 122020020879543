import { GridOptions, SelectionChangedEvent } from '@ag-grid-community/core';
import {
  BaseTable,
  Button,
  Checkbox,
  Col,
  DataImportModal,
  DateEditor,
  DeleteOutlined,
  EnumEditor,
  FloatEditor,
  Form,
  FormInstance,
  message,
  PlusOutlined,
  Row,
  SearchDocTypeEditor,
  Tabs,
  TabsProps,
  GlobalSearch,
} from '@shipmnts/pixel-hub';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';
import { compact, pick } from 'lodash';
import { oceanPackageTypes } from 'operations/baseConstants';
import CargoProperties from 'operations/components/CargoProperties';
import { CargoPropertyValue } from 'operations/models/Cargo';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { Column } from 'operations/models/Report';
import React, { forwardRef, MutableRefObject, useImperativeHandle, useRef, useState } from 'react';

export const getInitialValueForCargoProperties = (cargos: ProductOrderItemValue[] | undefined) => {
  if (!cargos || cargos.length === 0) return undefined;
  const initialProperties = { ...cargos[0].cargo_properties };

  cargos.forEach((cargo) => {
    initialProperties.is_hazardous &&= cargo.cargo_properties?.is_hazardous ?? false;
    initialProperties.is_temp_controlled &&= cargo.cargo_properties?.is_temp_controlled ?? false;
    initialProperties.is_battery &&= cargo.cargo_properties?.is_battery ?? false;
    initialProperties.is_perishable &&= cargo.cargo_properties?.is_perishable ?? false;
  });

  return initialProperties;
};

const convertToKgs = (uom: string, value: number) => {
  if (uom === 'lbs') return value * 0.453;
  else if (uom === 'mts') return value * 1000;
  return value;
};

const convertToMtr = (uom: string, value: number) => {
  if (uom === 'meter') return value;
  else if (uom === 'inches') return value * 0.0254;
  return value * 0.01;
};

const recalculateTotal = (data: any) => {
  const length = convertToMtr(data.dimension_unit, data.length || 0);
  const width = convertToMtr(data.dimension_unit, data.width || 0);
  const height = convertToMtr(data.dimension_unit, data.height || 0);
  const gross_volume = length * width * height * (data.outer_package_qty || 1);
  const volumetric_weight_kgs = (Math.pow(10, 6) * gross_volume) / 6000;
  const gross_weight_kgs =
    convertToKgs(data.weight_unit, data.outer_per_packet_wt || 0) * (data.outer_package_qty || 1);
  const gross_weight = (data.outer_per_packet_wt || 0) * (data.outer_package_qty || 1);
  const chargeable_weight_kgs = Math.max(gross_weight_kgs, volumetric_weight_kgs);
  return {
    gross_weight,
    gross_volume,
    volumetric_weight: volumetric_weight_kgs,
    chargeable_weight: chargeable_weight_kgs,
  };
};

function getFinalOptions(listOfValue: { name: string }[]) {
  const newList: { value: string; label: string }[] = [];

  listOfValue.forEach((option) => {
    newList.push({
      label: option.name,
      value: option.name,
    });
  });
  return newList;
}

const weight_unit = [
  { value: 'kgs', label: 'kgs' },
  { value: 'mts', label: 'mts' },
];

const dimension_unit = [
  { value: 'cms', label: 'cms' },
  { value: 'inches', label: 'inches' },
  { value: 'meter', label: 'meter' },
];

const commodity_type = [
  { value: 'General Cargo', label: 'General Cargo' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Perishable', label: 'Perishable' },
  { value: 'Dangerous Cargo', label: 'Dangerous Cargo' },
  { value: 'Human Remains', label: 'Human Remains' },
  { value: 'Live Animal', label: 'Live Animal' },
  { value: 'Valuable', label: 'Valuable' },
  { value: 'Others', label: 'Others' },
];
const RequireHeaderWrapper = ({ text }: { text: string }) => {
  return <span style={{ whiteSpace: 'pre-line', fontSize: '14px', color: '#c23934' }}>{text}</span>;
};
const getRequiredFields = (cargoTableType: string, loadType: string, freightType: string) => {
  const requiredFieldMap: Array<{ [key: string]: string }> = [];
  if (cargoTableType === '1') {
    requiredFieldMap.push({ key: 'product_name', value: 'Commodity Description' });
    if (freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) {
      requiredFieldMap.push({ key: 'gross_weight', value: 'Gross Weight' });
    }
    if (freightType === 'air') {
      requiredFieldMap.push({ key: 'volumetric_weight', value: 'Volumetric Weight' });
    }
    if (freightType === 'ocean' && loadType === 'lcl')
      requiredFieldMap.push({ key: 'gross_volume', value: 'Gross Volume' });
  } else {
    requiredFieldMap.push({ key: 'product_name', value: 'Commodity Description' });
    requiredFieldMap.push({ key: 'outer_package_type', value: 'Package Type' });
    requiredFieldMap.push({ key: 'length', value: 'Length' });
    requiredFieldMap.push({ key: 'width', value: 'Width' });
    requiredFieldMap.push({ key: 'height', value: 'Height' });
    if (freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) {
      requiredFieldMap.push({ key: 'gross_weight', value: 'Gross Weight' });
    }
  }
  return requiredFieldMap;
};

const getHeight = (value: any) => {
  if (!value) {
    return undefined;
  }
  let detailPanelHeight = 5 * 39 + 160;

  if ((value || []).length < 6) {
    detailPanelHeight = (value || []).length * 39 + 160;
  }

  return detailPanelHeight;
};

const setCargosProperty = (form: FormInstance) => {
  const cargo_properties = form.getFieldValue('cargo_properties');
  const cargos = form?.getFieldValue('cargos');
  form?.setFieldsValue({
    cargos: cargos.map((crg: any) => {
      return {
        ...crg,
        cargo_properties: cargo_properties,
      };
    }),
  });
};

function createCargoPayload(cargo: any[]) {
  const remove_prefix = ['cargos_'];
  const rename = {};
  function removePrefixesAndRenameKeys(
    obj: any,
    prefixes: string[],
    renameMap: Record<string, string>
  ) {
    const result: any = {};

    for (const key in obj) {
      let newKey = key;

      // Remove prefixes
      prefixes.forEach((prefix) => {
        if (newKey.startsWith(prefix)) {
          newKey = newKey.slice(prefix.length);
        }
      });

      // Rename keys
      if (renameMap[key]) {
        newKey = renameMap[key];
      }

      const value = obj[key];

      // Process nested arrays or objects, but avoid processing dayjs objects
      if (Array.isArray(value)) {
        result[newKey] = value.map((item) =>
          removePrefixesAndRenameKeys(item, prefixes, renameMap)
        );
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !value?.isValid() // Avoid processing dayjs objects
      ) {
        result[newKey] = removePrefixesAndRenameKeys(value, prefixes, renameMap);
      } else {
        result[newKey] = value;
      }
    }

    return result;
  }

  let cargos = cargo.map((item) => removePrefixesAndRenameKeys(item, remove_prefix, rename));

  cargos = cargos.map((c: any) => {
    if (c?.invoice_date) c['invoice_date'] = c?.['invoice_date']?.unix();
    if (c?.eway_bill_validity) c['eway_bill_validity'] = c?.['eway_bill_validity']?.unix();
    return c;
  });
  return cargos;
}

interface CargoTableProps {
  form?: FormInstance;
  value?: Array<any>;
  onChange?: (value: Array<any>) => void;
  disabled?: boolean;
  loadType?: string;
  freightType?: string;
  gridRef: MutableRefObject<GridOptions | undefined>;
  showAddDeleteButton?: boolean;
  setOpen?: (value: boolean) => void;
}
const CargoByTotalTableForm = forwardRef(function CargoByTotalTableForm(
  props: CargoTableProps,
  ref
): JSX.Element {
  const {
    value,
    onChange,
    disabled,
    loadType,
    freightType,
    gridRef,
    showAddDeleteButton = true,
    form,
    setOpen,
  } = props;
  const [rowSelected, setRowSelected] = useState(false);
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes();
    setRowSelected(selectedRows.length > 0);
  };

  const addNewRow = () => {
    const newRow = {};
    onChange && onChange([...(value || []), newRow]);
    setTimeout(() => {
      allColumnsDefs[0]?.colId &&
        gridRef?.current?.api?.startEditingCell({
          rowIndex: value?.length || 0,
          colKey: allColumnsDefs[0]?.colId,
        });
    }, 500);
  };

  const newDeleteRow = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedNodes();
    const selectedIndexes = (selectedRows || []).map((element: any) => element.rowIndex);
    const newData: any = [];
    gridRef?.current?.api?.forEachNode((node: { rowIndex: any; data: any }) => {
      if (!selectedIndexes.includes(node.rowIndex)) {
        newData.push(node.data);
      }
    });
    onChange && onChange(newData);
    setRowSelected(false);
  };

  const components = {
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    DateEditor,
  };

  const allColumnsDefs: Column[] = compact([
    {
      headerName: 'Commodity',
      field: 'commodity',
      colId: 'commodity',
      maxWidth: 150,
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: GlobalSearch,
        componentProps: {
          doc_type: 'Global::Commodity',
        },
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.commodity = params?.newValue;
        params.data.product_name = params?.newValue?.name;
        return true;
      },
      valueFormatter: (params) => {
        return params?.value?.name;
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Commodity Description" />,
      headerName: 'Commodity Description',
      field: 'product_name',
      colId: 'product_name',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (!params.newValue) return false;
        params.data.product_name = params.newValue;
        return true;
      },
      maxWidth: 180,
    },
    freightType === 'air' && {
      headerName: 'Commodity Type',
      field: 'commodity_type',
      colId: 'commodity_type',
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: commodity_type,
      },
      valueSetter: (params) => {
        params.data.commodity_type = params.newValue;
        return true;
      },
    },
    {
      headerName: 'Package Type',
      field: 'outer_package_type',
      colId: 'outer_package_type',
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: getFinalOptions(oceanPackageTypes),
      },
      valueSetter: (params) => {
        params.data.outer_package_type = params.newValue;
        return true;
      },
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Qty" />,
      }),
      headerName: 'Qty',
      field: 'outer_package_qty',
      colId: 'outer_package_qty',
      editable: (o) => !o.node.isRowPinned(),
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      cellEditorParams: {
        min: 0.00001,
        step: 0.00001,
      },
      valueSetter: (params: any) => {
        params.data.outer_package_qty = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.outer_package_qty || 1;
      },
      width: 150,
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Weight Unit" />,
      }),
      headerName: 'Weight Unit',
      field: 'weight_unit',
      colId: 'weight_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: weight_unit,
      },
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.weight_unit || 'kgs';
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.weight_unit = params.newValue || 'kgs';
        return true;
      },
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          force: true,
          columns: ['gross_weight'],
          rowNodes: [params.node],
        });
      },
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Gross Weight" />,
      }),
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_weight || 0;
      },
      valueSetter: (params) => {
        params.data.gross_weight = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
    },
    freightType === 'air' && {
      headerComponent: () => <RequireHeaderWrapper text="Vol. Weight" />,
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.volumetric_weight || 0;
      },
      valueSetter: (params) => {
        params.data.volumetric_weight = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    freightType === 'air' && {
      headerComponent: () => <RequireHeaderWrapper text="Ch. Weight" />,
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      valueGetter: (params: any) => {
        return params.data.chargeable_weight || 0;
      },
      valueSetter: (params) => {
        params.data.chargeable_weight = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.chargeable_weight} />;
      },
    },
    {
      ...(freightType === 'ocean' &&
        loadType === 'lcl' && {
          headerComponent: () => <RequireHeaderWrapper text="Gross Vol" />,
        }),
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_volume || 0;
      },
      valueSetter: (params) => {
        params.data.gross_volume = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
    },
    {
      headerName: 'Cargo Properties',
      field: 'cargo_properties',
      colId: 'cargo_properties',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <CargoProperties
            freightType={freightType}
            formKey={params.rowIndex}
            value={params?.value}
            onChange={(value: CargoPropertyValue) => {
              params?.setValue(value);
              form?.setFieldsValue({ cargo_properties: undefined });
            }}
          />
        );
      },
      editable: false,
    },
    freightType === 'road' && {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.serial_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'invoice_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.invoice_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.invoice_date = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.invoice_date;
      },
      cellEditorPopup: true,
    },
    freightType === 'road' && {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.batch_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Customs Ref#',
      field: 'custom_ref',
      colId: 'custom_ref',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.custom_ref = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'E-way #',
      field: 'eway_bill_no',
      colId: 'eway_bill_no',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.eway_bill_no = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'E-way Validity',
      field: 'eway_bill_validity',
      colId: 'eway_bill_validity',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.eway_bill_validity = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.eway_bill_validity;
      },
      cellEditorPopup: true,
    },
  ]);
  return (
    <>
      <BaseTable
        rowData={value || []}
        reportName={'CargoByTotals'}
        height={getHeight(value) || '200px'}
        columns={allColumnsDefs}
        gridRef={gridRef}
        rowSelection={'multiple'}
        checkbox_always_visible={false}
        allMapping={true}
        onSelectionChanged={onSelectionChanged}
        reportConfig={{
          components: components,
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          onCellValueChanged: (params: any) => {
            const data = value || [];
            data[params.rowIndex] = {
              ...params.data,
            };
            onChange && onChange(data);
          },
          defaultColDef: {
            editable: true,
            resizable: true,
          },
          enableCellChangeFlash: true,
          animateRows: true,
          stopEditingWhenCellsLoseFocus: true,
          enableRangeSelection: true,
          tabToNextCell: (params: any) => {
            const col = gridRef?.current?.columnApi?.getColumn(allColumnsDefs[0]?.colId);
            if (params.editing && !params.backwards && !params.nextCellPosition && col) {
              addNewRow();
              return {
                rowIndex: -1,
                column: col,
                rowPinned: null,
              };
            } else return params.nextCellPosition;
          },
        }}
      />
      {showAddDeleteButton && (
        <div style={{ marginTop: '10px', display: 'flex' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addNewRow()}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
          >
            Add Cargo
          </Button>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            ghost
            type="primary"
            danger
            style={{ marginLeft: '15px' }}
            disabled={!rowSelected || disabled}
            onClick={() => {
              newDeleteRow();
            }}
          >
            Delete
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setOpen && setOpen(true)}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
            style={{ marginLeft: '15px' }}
          >
            Import Cargo
          </Button>
        </div>
      )}
    </>
  );
});

const CargoByPackageTableForm = forwardRef(function CargoByPackageTableForm(
  props: CargoTableProps,
  ref
): JSX.Element {
  const {
    value,
    onChange,
    disabled,
    loadType,
    freightType,
    gridRef,
    showAddDeleteButton = true,
    form,
    setOpen,
  } = props;

  const [rowSelected, setRowSelected] = useState(false);

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedNodes();
    setRowSelected(selectedRows.length > 0);
  };

  const addNewRow = () => {
    const newRow = {};
    onChange && onChange([...(value || []), newRow]);
    setTimeout(() => {
      allColumnsDefs[0]?.colId &&
        gridRef?.current?.api?.startEditingCell({
          rowIndex: value?.length || 0,
          colKey: allColumnsDefs[0]?.colId,
        });
    }, 500);
  };

  const newDeleteRow = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedNodes();
    const selectedIndexes = (selectedRows || []).map((element: any) => element.rowIndex);
    const newData: any = [];
    gridRef?.current?.api?.forEachNode((node: { rowIndex: any; data: any }) => {
      if (!selectedIndexes.includes(node.rowIndex)) {
        newData.push(node.data);
      }
    });
    onChange && onChange(newData);
    setRowSelected(false);
  };

  const components = {
    FloatEditor,
    EnumEditor,
    SearchDocTypeEditor,
    DateEditor,
  };

  const allColumnsDefs: Column[] = compact([
    {
      headerName: 'Commodity',
      field: 'commodity',
      colId: 'commodity',
      maxWidth: 150,
      pinned: 'left',
      lockPosition: true,
      suppressMovable: true,
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'SearchDocTypeEditor',
      cellEditorParams: {
        CustomComponent: GlobalSearch,
        componentProps: {
          doc_type: 'Global::Commodity',
        },
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.commodity = params?.newValue;
        params.data.product_name = params?.newValue?.name;
        return true;
      },
      valueFormatter: (params) => {
        return params?.value?.name;
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Commodity Description" />,
      headerName: 'Commodity Description',
      field: 'product_name',
      colId: 'product_name',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (!params.newValue) return false;
        params.data.product_name = params.newValue;
        return true;
      },
      maxWidth: 180,
    },
    freightType === 'air' && {
      headerName: 'Commodity Type',
      field: 'commodity_type',
      colId: 'commodity_type',
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: commodity_type,
      },
      valueSetter: (params) => {
        params.data.commodity_type = params.newValue;
        return true;
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Package Type" />,
      headerName: 'Package Type',
      field: 'outer_package_type',
      colId: 'outer_package_type',
      editable: (o) => !o.node.isRowPinned(),
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: getFinalOptions(oceanPackageTypes),
      },
      valueSetter: (params) => {
        params.data.outer_package_type = params.newValue;
        return true;
      },
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Qty" />,
      }),
      headerName: 'Qty',
      field: 'outer_package_qty',
      colId: 'outer_package_qty',
      editable: (o) => !o.node.isRowPinned(),
      cellEditor: 'FloatEditor',
      columnType: 'Float',
      cellEditorParams: {
        min: 0.00001,
        step: 0.00001,
      },
      valueSetter: (params: any) => {
        params.data.outer_package_qty = Number(Number(params.newValue).toFixed(3));
        const { gross_weight, gross_volume, volumetric_weight, chargeable_weight } =
          recalculateTotal(params.data);

        params.data.gross_weight = gross_weight;
        params.data.gross_volume = gross_volume;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      valueGetter: (params: any) => {
        return params.data.outer_package_qty || 1;
      },
      width: 150,
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Weight Unit" />,
      }),
      headerName: 'Weight Unit',
      field: 'weight_unit',
      colId: 'weight_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: weight_unit,
      },
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.weight_unit || 'kgs';
      },
      valueSetter: (params: any) => {
        if (!params.newValue) return false;
        params.data.weight_unit = params.newValue;
        const { volumetric_weight, chargeable_weight } = recalculateTotal(params.data);
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          force: true,
          columns: ['gross_weight', 'outer_per_packet_wt'],
          rowNodes: [params.node],
        });
      },
    },
    {
      headerName: 'Per Pack Weight',
      field: 'outer_per_packet_wt',
      colId: 'outer_per_packet_wt',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.outer_per_packet_wt || 0;
      },
      valueSetter: (params: any) => {
        params.data.outer_per_packet_wt = Number(Number(params.newValue).toFixed(3));
        const { gross_weight, volumetric_weight, chargeable_weight } = recalculateTotal(
          params.data
        );
        params.data.gross_weight = gross_weight;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.outer_per_packet_wt}
          />
        );
      },
    },
    {
      ...((freightType === 'air' || (freightType === 'ocean' && loadType === 'lcl')) && {
        headerComponent: () => <RequireHeaderWrapper text="Gross Weight" />,
      }),
      headerName: 'Gross Weight',
      field: 'gross_weight',
      colId: 'gross_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_weight || 0;
      },
      valueSetter: (params) => {
        params.data.gross_weight = Number(Number(params.newValue).toFixed(3));
        const { volumetric_weight, chargeable_weight } = recalculateTotal(params.data);
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.weight_unit || 'kgs'}
            qty={params?.data?.gross_weight}
          />
        );
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Dimension Unit" />,
      suppressKeyboardEvent: (params) => {
        return params.event.key === 'Enter' && params.editing;
      },
      headerName: 'Dimension Unit',
      field: 'dimension_unit',
      colId: 'dimension_unit',
      columnType: 'String',
      cellEditor: 'EnumEditor',
      cellEditorParams: {
        options: dimension_unit,
      },
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return '';
        return params.data.dimension_unit || 'cms';
      },
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.dimension_unit = params?.newValue;
        const { gross_volume, volumetric_weight, chargeable_weight } = recalculateTotal(
          params.data
        );
        params.data.gross_volume = gross_volume;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      editable: (o) => !o.node.isRowPinned(),
      onCellValueChanged: (params: any) => {
        params.api.refreshCells({
          force: true,
          columns: ['length', 'width', 'height'],
          rowNodes: [params.node],
        });
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Length" />,
      headerName: 'Length',
      field: 'length',
      colId: 'length',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.length || 0;
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.length = params.newValue;
        const { gross_volume, volumetric_weight, chargeable_weight } = recalculateTotal(
          params.data
        );
        params.data.gross_volume = gross_volume;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.length}
          />
        );
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Width" />,
      headerName: 'Width',
      field: 'width',
      colId: 'width',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.width || 0;
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.width = params.newValue;
        const { gross_volume, volumetric_weight, chargeable_weight } = recalculateTotal(
          params.data
        );
        params.data.gross_volume = gross_volume;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.width}
          />
        );
      },
    },
    {
      headerComponent: () => <RequireHeaderWrapper text="Height" />,
      headerName: 'Height',
      field: 'height',
      colId: 'height',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        if (params.node.isRowPinned()) return undefined;
        return params.data.height || 0;
      },
      valueSetter: (params) => {
        if (!params?.newValue) return false;
        params.data.height = params.newValue;
        const { gross_volume, volumetric_weight, chargeable_weight } = recalculateTotal(
          params.data
        );
        params.data.gross_volume = gross_volume;
        params.data.volumetric_weight = volumetric_weight;
        params.data.chargeable_weight = chargeable_weight;
        return true;
      },
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <QtyUomTypeRenderer
            uom={params?.data?.dimension_unit || 'cms'}
            qty={params?.data?.height}
          />
        );
      },
    },
    {
      headerName: 'Vol. Weight',
      field: 'volumetric_weight',
      colId: 'volumetric_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      valueGetter: (params: any) => {
        return params.data.volumetric_weight || 0;
      },
      valueSetter: (params) => {
        params.data.volumetric_weight = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.volumetric_weight} />;
      },
    },
    {
      headerName: 'Ch. Weight',
      field: 'chargeable_weight',
      colId: 'chargeable_weight',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: false,
      valueGetter: (params: any) => {
        return params.data.chargeable_weight || 0;
      },
      valueSetter: (params) => {
        params.data.chargeable_weight = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'kgs'} qty={params?.data?.chargeable_weight} />;
      },
    },
    {
      headerName: 'Gross Vol',
      field: 'gross_volume',
      colId: 'gross_volume',
      columnType: 'Float',
      cellEditor: 'FloatEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueGetter: (params: any) => {
        return params.data.gross_volume || 0;
      },
      valueSetter: (params) => {
        params.data.gross_volume = Number(Number(params.newValue).toFixed(3));
        return true;
      },
      cellRenderer: (params: any) => {
        return <QtyUomTypeRenderer uom={'CBM'} qty={params?.data?.gross_volume} />;
      },
    },
    {
      headerName: 'Cargo Properties',
      field: 'cargo_properties',
      colId: 'cargo_properties',
      cellRenderer: (params: any) => {
        if (params.node.isRowPinned()) return <></>;
        return (
          <CargoProperties
            freightType={freightType}
            formKey={params.rowIndex}
            value={params?.value}
            onChange={(value: CargoPropertyValue) => {
              params?.setValue(value);
              form?.setFieldsValue({ cargo_properties: undefined });
            }}
          />
        );
      },
      editable: false,
    },
    freightType === 'road' && {
      headerName: 'Serial #',
      field: 'serial_number',
      colId: 'serial_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.serial_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Invoice #',
      field: 'invoice_number',
      colId: 'invoice_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.invoice_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      colId: 'invoice_date',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.invoice_date = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.invoice_date;
      },
      cellEditorPopup: true,
    },
    freightType === 'road' && {
      headerName: 'Batch #',
      field: 'batch_number',
      colId: 'batch_number',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.batch_number = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'Customs Ref#',
      field: 'custom_ref',
      colId: 'custom_ref',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.custom_ref = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'E-way #',
      field: 'eway_bill_no',
      colId: 'eway_bill_no',
      columnType: 'String',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params: any) => {
        if (!params?.newValue) return false;
        params.data.eway_bill_no = params.newValue;
        return true;
      },
    },
    freightType === 'road' && {
      headerName: 'E-way Validity',
      field: 'eway_bill_validity',
      colId: 'eway_bill_validity',
      columnType: 'Date',
      cellEditor: 'DateEditor',
      editable: (o) => !o.node.isRowPinned(),
      valueSetter: (params) => {
        if (typeof params.newValue !== 'object') return false;
        params.data.eway_bill_validity = params.newValue?.unix();
        return true;
      },
      valueGetter: (params) => {
        if (params.node?.isRowPinned()) return '';
        return params.data.eway_bill_validity;
      },
      cellEditorPopup: true,
    },
  ]);
  return (
    <>
      <BaseTable
        rowData={value || []}
        reportName={'CargoByPackages'}
        height={getHeight(value) || '200px'}
        columns={allColumnsDefs}
        gridRef={gridRef}
        rowSelection={'multiple'}
        checkbox_always_visible={false}
        allMapping={true}
        onSelectionChanged={onSelectionChanged}
        reportConfig={{
          components: components,
          suppressCellFocus: false,
          suppressLastEmptyLineOnPaste: true,
          rowHeight: 40,
          onCellValueChanged: (params: any) => {
            const data = value || [];
            data[params.rowIndex] = {
              ...params.data,
            };
            onChange && onChange(data);
          },
          defaultColDef: {
            resizable: true,
            editable: true,
          },
          enableRangeSelection: true,
          enableCellChangeFlash: true,
          animateRows: true,
          stopEditingWhenCellsLoseFocus: true,
          tabToNextCell: (params: any) => {
            const col = gridRef?.current?.columnApi?.getColumn(allColumnsDefs[0]?.colId);
            if (params.editing && !params.backwards && !params.nextCellPosition && col) {
              addNewRow();
              return {
                rowIndex: -1,
                column: col,
                rowPinned: null,
              };
            } else return params.nextCellPosition;
          },
        }}
      />
      {showAddDeleteButton && (
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addNewRow()}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
          >
            Add Cargo
          </Button>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            ghost
            type="primary"
            danger
            style={{ marginLeft: '15px' }}
            disabled={!rowSelected || disabled}
            onClick={() => {
              newDeleteRow();
            }}
          >
            Delete
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setOpen && setOpen(true)}
            size="small"
            ghost
            type="primary"
            disabled={disabled}
            style={{ marginLeft: '15px' }}
          >
            Import Cargo
          </Button>
        </div>
      )}
    </>
  );
});

interface CargoNewComponentProps {
  form: FormInstance;
  loadType?: string;
  freightType?: string;
  disabled?: boolean;
  showAddDeleteButton?: boolean;
}
const CargoNewComponent = forwardRef(function CargoNewComponent(
  props: CargoNewComponentProps,
  ref
): JSX.Element {
  const [activeKey, setActiveKey] = useState<string>('1');
  const { form } = props;
  const [open, setOpen] = useState(false);
  const loadType = Form.useWatch('load_type', form) || props.loadType;
  const freightType = Form.useWatch('freight_type', form) || props.freightType;
  const gridRefTotal = useRef<GridOptions>();
  const gridRefPackage = useRef<GridOptions>();

  useImperativeHandle(ref, () => ({
    runValidation() {
      const cargos: ProductOrderItemValue[] = [];
      if (activeKey === '1') {
        gridRefTotal?.current?.api?.forEachNode((node: any) => {
          cargos.push(node.data);
        });
      } else {
        gridRefPackage?.current?.api?.forEachNode((node: any) => {
          cargos.push(node.data);
        });
      }
      const missingFieldsMessages: string[] = [];
      const requiredFieldMap = getRequiredFields(activeKey, loadType, freightType);
      cargos.forEach((cargo: any) => {
        const missingFields = requiredFieldMap.filter((field) => {
          return !(cargo.hasOwnProperty(field['key']) && cargo[field['key']]);
        });

        if (missingFields.length > 0) {
          const formattedFields = missingFields.map((field) => {
            return field['value'];
          });
          missingFieldsMessages.push(formattedFields.join(', ') + ' is required.');
        }
      });
      if (missingFieldsMessages.length > 0) {
        missingFieldsMessages.forEach((errMsg) => message.error(errMsg));
        return true;
      }
      return false;
    },
    getPayload() {
      const cargos: ProductOrderItemValue[] = [];
      if (activeKey === '1') {
        gridRefTotal?.current?.api?.forEachNode((node: any) => {
          cargos.push(node.data);
        });
        return cargos.map((cargo) => {
          return {
            ...pick(cargo, [
              'id',
              'commodity',
              'product_name',
              'commodity_type',
              'outer_package_type',
              'outer_package_qty',
              'weight_unit',
              'gross_weight',
              'volumetric_weight',
              'chargeable_weight',
              'gross_volume',
              'cargo_properties',
              'serial_number',
              'invoice_number',
              'invoice_date',
              'batch_number',
              'custom_ref',
              'eway_bill_no',
              'eway_bill_validity',
            ]),
            outer_package_qty: cargo?.outer_package_qty || 1,
            weight_unit: cargo?.weight_unit || 'kgs',
          };
        });
      } else {
        gridRefPackage?.current?.api?.forEachNode((node: any) => {
          cargos.push(node.data);
        });
        return cargos.map((cargo) => {
          return {
            ...pick(cargo, [
              'id',
              'commodity',
              'product_name',
              'commodity_type',
              'outer_package_type',
              'outer_package_qty',
              'weight_unit',
              'outer_per_packet_wt',
              'gross_weight',
              'dimension_unit',
              'length',
              'width',
              'height',
              'volumetric_weight',
              'gross_volume',
              'cargo_properties',
              'serial_number',
              'invoice_number',
              'invoice_date',
              'batch_number',
              'custom_ref',
              'eway_bill_no',
              'eway_bill_validity',
            ]),
            outer_package_qty: cargo?.outer_package_qty || 1,
            weight_unit: cargo?.weight_unit || 'kgs',
            dimension_unit: cargo?.dimension_unit || 'cms',
          };
        });
      }
    },
  }));
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <>
          By Totals
          <div style={{ fontSize: '12px' }}>Dont Have Pakage Details</div>
        </>
      ),
      children: (
        <Form.Item name={'cargos'} noStyle>
          <CargoByTotalTableForm
            {...props}
            setOpen={setOpen}
            loadType={loadType}
            freightType={freightType}
            gridRef={gridRefTotal}
          />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: (
        <>
          By Packages
          <div style={{ fontSize: '12px' }}>Have Package Details</div>
        </>
      ),
      children: (
        <Form.Item name={'cargos'} noStyle>
          <CargoByPackageTableForm
            {...props}
            setOpen={setOpen}
            loadType={loadType}
            freightType={freightType}
            gridRef={gridRefPackage}
          />
        </Form.Item>
      ),
    },
  ];
  return (
    <>
      {open && (
        <DataImportModal
          open={open}
          setOpen={setOpen}
          onSuccess={(data: any) => form.setFieldsValue({ cargos: createCargoPayload(data) })}
          intialValue={{
            selectedDocType: 'Shipment::Cargo',
          }}
        />
      )}
      <Row>
        <Col span={6}>
          <Form.Item name={['cargo_properties', 'is_hazardous']} valuePropName="checked">
            <Checkbox onChange={() => setCargosProperty(form)}>Hazardous Cargo</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['cargo_properties', 'is_temp_controlled']} valuePropName="checked">
            <Checkbox onChange={() => setCargosProperty(form)}>Temperature Controlled</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['cargo_properties', 'is_battery']} valuePropName="checked">
            <Checkbox onChange={() => setCargosProperty(form)}>Battery Inside</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['cargo_properties', 'is_perishable']} valuePropName="checked">
            <Checkbox onChange={() => setCargosProperty(form)}>Perishable</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Tabs
        onChange={(params) => {
          setActiveKey(params);
        }}
        destroyInactiveTabPane
        defaultActiveKey={activeKey}
        items={items}
      />
    </>
  );
});

export default CargoNewComponent;
