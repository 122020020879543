import { getSnapshot, cast } from 'mobx-state-tree';
import { omit as _omit, reduce as _reduce, pick as _pick, isMatch as _isMatch } from 'lodash';
import { BookingRequestValue } from 'operations/models/BookingRequest';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { RoutingLegValue } from 'operations/models/RoutingLeg';
import {
  RoutingNodesHashValue,
  RoutingDetailsValue,
} from 'operations/components/RoutingDetails/RoutingDetails';
import { CarrierValue } from 'operations/models/Carrier';
import { LocationValue } from 'operations/models/Location';
import { PartyValue } from 'operations/modules/reports/components/CreateShipmentFormContent';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
// import { SalesPersonValue } from 'common';
import { ShipmentPartyValue } from 'operations/models/ShipmentParty';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { STATUS_CONFIRMED } from 'operations/modules/reports/constants';
import {
  LOAD_TYPE_FCL,
  BOOKING_TYPE_VENDOR,
  COMPANY_STATUS_LEGAL_DISPUTE,
} from 'operations/baseConstants';
import { convertValuesToDayJs, Dayjs } from '@shipmnts/pixel-hub';
import { CompanyValue } from 'operations/models/Company';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { isCustomer } from 'common';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { getRoutingValues } from 'operations/modules/shipment/components/NewShipmentForm/getInitialValueHelper';

export interface DuplicateBookingOrderValue {
  booking_type: string;
  load_type: string;
  customer: PartyValue;
  vendor?: PartyValue;
  carrier: CarrierValue;
  routing_details: RoutingDetailsValue;
  cargos: ProductOrderItemValue[];
  container_requests?: ContainerRequestValue[];
  confirmed_booking: boolean;
  sales_person: SalesPersonValue;
  empty_pickup_location: LocationValue;
  empty_return_location: LocationValue;
  origin_detention_free_days: number;
  origin_demurrage_free_days: number;
  destination_detention_free_days: number;
  destination_demurrage_free_days: number;
  valid_till_date: Dayjs;
  gate_open_date: Dayjs;
  si_cutoff_date: Dayjs;
  gate_close_date: Dayjs;
  doc_cutoff_date: Dayjs;
  voyage_schedule_id?: string;
}

export const getRoutingDetailsFormValue = (
  routingLegs: RoutingLegValue[],
  noCopyLeg?: boolean
): RoutingDetailsValue => {
  const routing_nodes: RoutingNodesHashValue = {};
  let routing_legs = routingLegs ? getSnapshot<RoutingLegValue[]>(cast(routingLegs)).slice() : [];
  routing_legs = routing_legs.map((rl) => {
    if (rl?.origin?.id)
      routing_nodes[rl.origin.id] = { ...rl.origin, _id: rl.origin.id, id: undefined };
    if (rl?.destination?.id)
      routing_nodes[rl.destination.id] = {
        ...rl.destination,
        _id: rl.destination.id,
        id: undefined,
      };
    return {
      ...{ ...rl, _id: rl.id, id: undefined },
      origin: undefined,
      destination: undefined,
      origin_id: rl?.origin?.id,
      destination_id: rl?.destination?.id,
    };
  });
  const omitFields = [
    'estimated_time_of_arrival',
    'actual_time_of_arrival',
    'estimated_time_of_departure',
    'actual_time_of_departure',
    'vessel',
    'voyage_number',
    'wagon_number',
  ];
  return {
    routing_legs: noCopyLeg
      ? (routing_legs.map((rl) => _omit<RoutingLegValue>(rl, omitFields)) as RoutingLegValue[])
      : routing_legs,
    routing_nodes: routing_nodes,
  };
};

export const getCargosFormValue = (cargos: ProductOrderItemValue[]) =>
  cargos.map((cargo: ProductOrderItemValue, index: number) => ({
    _id: `c_${index}`,
    commodity: cargo.commodity,
    product_name: cargo.product_name,
    outer_package_type: cargo.outer_package_type,
    volume_unit: cargo.volume_unit,
    weight_unit: cargo.weight_unit,
    cargo_properties: cargo.cargo_properties,
  }));

export const getContainerRequestFormValue = (containerRequests: ContainerRequestValue[]) =>
  containerRequests.map((cr: ContainerRequestValue, index: number) => ({
    _id: `cr_${index}`,
    container_type: cr.container_type,
    container_type_code: cr.container_type_code,
    quantity: cr.quantity,
    weight_unit: cr.weight_unit,
    container_settings: cr.container_settings,
  }));

export const duplicateBookingRequest = (bookingRequest: BookingRequestValue, navigate: any) => {
  const load_type = bookingRequest?.load_type || LOAD_TYPE_FCL;
  const formValue = {
    load_type,
    stuffing_location_type: bookingRequest?.stuffing_location_type,
    incoterm: bookingRequest?.incoterm,
    ...(bookingRequest.preferred_carriers
      ? {
          preferred_carriers: bookingRequest.preferred_carriers.map((cr: CarrierValue) => ({
            id: cr.id,
            name: cr.name,
            carrier_type: cr.carrier_type,
          })),
        }
      : {}),
    party: _reduce(
      bookingRequest.booking_request_parties,
      (new_parties: { [key: string]: ShipmentPartyValue }, br_party: ShipmentPartyValue) => {
        if (br_party.name) {
          new_parties[br_party?.name] = { ...br_party, id: undefined };
        }
        return new_parties;
      },
      {}
    ),
    ocean_transport_order: {
      customer: {
        party_company: bookingRequest.customerCompany,
        party_address: bookingRequest.sell_collaborations[0].customer_address,
      },
      vendor: {
        party_company: bookingRequest.sell_collaborations[0].vendor_company,
        party_address: bookingRequest.sell_collaborations[0].vendor_address,
      },
      sales_person: bookingRequest.sell_collaborations[0].sales_person,
      routing_details: getRoutingDetailsFormValue(
        bookingRequest.ocean_transport_orders[0]?.routing_legs
      ),
    },
    cargos: getCargosFormValue(bookingRequest.cargos),
    ...(load_type === LOAD_TYPE_FCL
      ? {
          container_requests: getContainerRequestFormValue(bookingRequest.container_requests),
        }
      : {}),
    pickupRequested: bookingRequest.road_transport_orders.length !== 0,
  };
  navigate(`~/form/booking_request/new?load_type=${load_type}`, {
    state: {
      booking_request: JSON.stringify(formValue),
    },
  });
};

export const duplicateBookingOrder = (
  oceanTransportOrder: OceanTransportOrderValue,
  navigate: any
) => {
  const booking_type = oceanTransportOrder.booking_type;
  const load_type = oceanTransportOrder?.load_type || LOAD_TYPE_FCL;
  const date_fields = ['gate_open_date', 'si_cutoff_date', 'gate_close_date', 'doc_cutoff_date'];
  const formValue = {
    booking_type,
    load_type,
    customer: {
      party_company: oceanTransportOrder.customer_company,
      party_address: oceanTransportOrder.customer_address,
    },
    ...(booking_type === BOOKING_TYPE_VENDOR
      ? {
          vendor: {
            party_company: oceanTransportOrder.vendor_company,
            party_address: oceanTransportOrder.vendor_address,
          },
        }
      : {}),
    carrier: oceanTransportOrder.global_carrier,
    routing_details: getRoutingDetailsFormValue(oceanTransportOrder.routing_legs),
    cargos: getCargosFormValue(oceanTransportOrder.cargos),
    ...(load_type === LOAD_TYPE_FCL
      ? {
          container_requests: getContainerRequestFormValue(
            oceanTransportOrder?.container_requests || []
          ),
        }
      : {}),
    confirmed_booking: oceanTransportOrder.status === STATUS_CONFIRMED,
    ...convertValuesToDayJs(oceanTransportOrder, date_fields),
    sales_person: oceanTransportOrder.sales_person,
    empty_pickup_location: oceanTransportOrder.empty_pickup_location,
    empty_return_location: oceanTransportOrder.empty_return_location,
    origin_detention_free_days: oceanTransportOrder.origin_detention_free_days,
    origin_demurrage_free_days: oceanTransportOrder.origin_demurrage_free_days,
    destination_detention_free_days: oceanTransportOrder.destination_detention_free_days,
    destination_demurrage_free_days: oceanTransportOrder.destination_demurrage_free_days,
    voyage_schedule_id: oceanTransportOrder.voyage_schedule_id,
    routing_legs: oceanTransportOrder.routing_legs,
    ...getRoutingValues(undefined, oceanTransportOrder),
  };
  navigate(`~/form/booking_order/new?booking_type=${booking_type}&load_type=${load_type}`, {
    state: {
      ocean_transport_order: JSON.stringify(formValue),
    },
  });
};

export const getContainerRequestsForSplitContainers = (
  source_container_requests: ContainerRequestValue[],
  shipment_containers: ContainerReportData[]
) => {
  const new_container_requests = source_container_requests.reduce(
    (new_crs: ContainerRequestValue[], old_cr: ContainerRequestValue) => {
      const new_cr = {
        ..._pick(old_cr, [
          'id',
          'container_type',
          'container_type_code',
          'is_shipper_owned',
          'weight_per_container',
          'weight_unit',
          'container_settings',
        ]),
        quantity: 0,
      } as ContainerRequestValue;
      new_crs.push(new_cr);
      return new_crs;
    },
    []
  );

  shipment_containers.forEach((sc) => {
    new_container_requests.forEach((new_cr) => {
      if (
        new_cr.container_settings &&
        _isMatch(new_cr.container_settings, _omit(sc.container_settings, '__typename'))
      ) {
        new_cr['quantity'] = (new_cr['quantity'] || 0) + 1;
        return;
      }
    });
  });
  return new_container_requests.filter((new_cr) => new_cr.quantity !== 0);
};

export const splitAndCreateNewBooking = (
  oceanTransportOrder: OceanTransportOrderValue,
  containers: ContainerReportData[],
  navigate: any,
  rolloverOrShutout?: string
) => {
  const booking_type = oceanTransportOrder.booking_type;
  const load_type = oceanTransportOrder?.load_type || LOAD_TYPE_FCL;
  const date_fields = [
    'valid_till_date',
    'gate_open_date',
    'si_cutoff_date',
    'gate_close_date',
    'doc_cutoff_date',
  ];
  const formValue = {
    ...getRoutingValues(undefined, oceanTransportOrder),
    booking_type,
    load_type,
    customer: {
      party_company: oceanTransportOrder.customer_company,
      party_address: oceanTransportOrder.customer_address,
    },
    ...(booking_type === BOOKING_TYPE_VENDOR
      ? {
          vendor: {
            party_company: oceanTransportOrder.vendor_company,
            party_address: oceanTransportOrder.vendor_address,
          },
        }
      : {}),
    carrier: oceanTransportOrder.global_carrier,
    routing_details: getRoutingDetailsFormValue(oceanTransportOrder.routing_legs, false),
    container_requests:
      !rolloverOrShutout && oceanTransportOrder?.container_requests
        ? getContainerRequestsForSplitContainers(oceanTransportOrder.container_requests, containers)
        : [],
    cargos: getCargosFormValue(oceanTransportOrder.cargos),
    ...convertValuesToDayJs(oceanTransportOrder, date_fields),
    sales_person: oceanTransportOrder.sales_person,
    empty_pickup_location: oceanTransportOrder.empty_pickup_location,
    empty_return_location: oceanTransportOrder.empty_pickup_location,
    origin_detention_free_days: oceanTransportOrder.origin_detention_free_days,
    origin_demurrage_free_days: oceanTransportOrder.origin_demurrage_free_days,
    destination_detention_free_days: oceanTransportOrder.destination_detention_free_days,
    destination_demurrage_free_days: oceanTransportOrder.destination_demurrage_free_days,
    voyage_schedule_id: oceanTransportOrder.voyage_schedule_id,
  };
  navigate(`~/form/split_and_create_booking`, {
    state: {
      ocean_transport_order: formValue,
      containers: containers,
      rolloverOrShutout,
    },
  });
};

export const checkCustomerValid = (customer: Partial<CompanyValue>) => {
  if (!isCustomer(customer.company_type)) return false;
  if (customer.is_disabled) return false;
  if (customer.status === COMPANY_STATUS_LEGAL_DISPUTE) return false;
  return true;
};
