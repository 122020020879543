import React, { useCallback } from 'react';
import { Radio, Row, Col, Select, InputNumber, Table, Form } from '@shipmnts/pixel-hub';
import { observer } from 'mobx-react-lite';
import { CompanySearch } from 'common';
import { OceanTransportOrderValue } from 'operations/models/OceanTransportOrder';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import { renderContainerSettings } from 'operations/modules/reports/oldCellRenderers';
import {
  showContainerAllocation,
  areContainerSettingsSame,
} from 'operations/models/ShipmentContainer';
import { min as _min, find as _find } from 'lodash';
import { LOAD_TYPE_FCL, RTO_TYPE_LOOSE } from 'operations/baseConstants';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { GlobalSearch } from '@shipmnts/pixel-hub';
const { Option } = Select;

const cargosColumns = [
  {
    title: 'Cargo Description',
    dataIndex: 'product_name',
  },
  {
    title: 'Packages',
    dataIndex: 'outer_package_qty',
  },
  {
    title: 'Package Type',
    dataIndex: 'outer_package_type',
  },
  {
    title: 'Gross Weight',
    dataIndex: 'gross_weight',
  },
  {
    title: 'Weight unit',
    dataIndex: 'weight_unit',
  },
];

interface CargosTableProps {
  cargos?: Array<ProductOrderItemValue>;
  value?: Array<React.Key>;
  onChange?: (value: Array<React.Key> | undefined) => void;
  disabledCargos: Array<string>;
}

const CargosTable = React.memo(function cargosTable(props: CargosTableProps) {
  const { cargos, onChange, value, disabledCargos } = props;
  return (
    <Row style={{ margin: '16px 0 44px' }}>
      <Table
        rowSelection={{
          onChange: (selectedRowKeys: React.Key[]) => {
            onChange && onChange(selectedRowKeys);
          },
          getCheckboxProps: (record: ProductOrderItemValue) => ({
            disabled: disabledCargos.includes(record.id || ''),
          }),
          selectedRowKeys: value,
        }}
        dataSource={cargos}
        columns={cargosColumns}
        pagination={false}
        rowKey={(record) => record.id || ''}
        size="small"
        style={{ width: '100%' }}
      />
    </Row>
  );
});

interface RoadTransportOrderFormProps {
  fieldName?: Array<string | number>;
  oceanTransportOrders?: Array<OceanTransportOrderValue>;
  customerCompanyId?: string;
  shipperCompanyId?: string;
  disabledFields?: Array<'load_type' | 'managed_by'>;
  requiredFields?: Array<
    'load_type' | 'managed_by' | 'vendor' | 'factory_location' | 'cfs_location'
  >;
  bookingRequestId?: string;
  roadTransportOrderId?: string;
  booking_request_container_requests?: ContainerRequestValue[];
  load_type?: string;
  cargos?: Array<ProductOrderItemValue>;
  disabledCargos?: Array<string>;
}

const RoadTransportOrderForm = observer(function RoadTransportOrderForm(
  props: RoadTransportOrderFormProps
): JSX.Element {
  const {
    fieldName = [],
    oceanTransportOrders,
    customerCompanyId,
    shipperCompanyId,
    disabledFields,
    requiredFields,
    bookingRequestId,
    roadTransportOrderId,
    booking_request_container_requests,
    load_type,
    cargos,
    disabledCargos,
  } = props;
  const getColumns = useCallback(() => {
    const cols = [
      {
        title: 'Booking #',
        dataIndex: 'booking_number',
      },
      {
        title: 'Shipping line/Vendor',
        dataIndex: 'vendorName',
      },
      {
        title: 'Allocation Pending Qty',
        dataIndex: 'allocation_pending_quantity',
        render: function render(text: string, record: OceanTransportOrderValue, index: number) {
          const allocation_pending_quantity = record.pickupAllocationPendingContainersCountByType(
            bookingRequestId || ''
          );
          return (
            <span>
              {Object.keys(allocation_pending_quantity)
                .map((container_type) =>
                  showContainerAllocation(allocation_pending_quantity[container_type])
                )
                .join(', ')}
            </span>
          );
        },
      },
    ];

    (booking_request_container_requests || [])
      .filter((cr) => cr.quantity_fulfilled !== 0)
      .forEach((booking_request_cr) => {
        if (booking_request_cr.container_type_code) {
          const containerSetting = renderContainerSettings([booking_request_cr]);
          const title = containerSetting
            ? `${booking_request_cr.container_type} (${containerSetting})`
            : booking_request_cr.container_type || '';
          cols.push({
            title,
            dataIndex: title,
            render: function renderContainerColumn(text: string, record: OceanTransportOrderValue) {
              const matched_container_request_of_oto = _find(
                record.container_requests,
                (cr: ContainerRequestValue) =>
                  cr.container_type === booking_request_cr.container_type &&
                  areContainerSettingsSame(
                    cr.container_settings,
                    booking_request_cr?.container_settings
                  )
              );
              const oto_containers_linked_with_br =
                record.parentBRShipmentContainers(bookingRequestId);
              let containers_picked_up = 0,
                containers_allocated = 0,
                oto_containers_without_rto = 0;

              oto_containers_linked_with_br.forEach((sc) => {
                if (
                  sc.container_type === booking_request_cr.container_type &&
                  areContainerSettingsSame(
                    sc.container_settings,
                    booking_request_cr?.container_settings
                  )
                ) {
                  containers_allocated++;
                  if (
                    sc.container_number &&
                    roadTransportOrderId &&
                    sc.road_transport_order_ids?.includes(roadTransportOrderId)
                  )
                    containers_picked_up++;
                  if (
                    !sc.road_transport_order_ids ||
                    sc.road_transport_order_ids.length === 0 ||
                    (roadTransportOrderId &&
                      sc.road_transport_order_ids.includes(roadTransportOrderId))
                  )
                    oto_containers_without_rto++;
                }
              });

              return (
                <Form.Item
                  style={{ margin: 0 }}
                  name={[
                    ...fieldName,
                    'requested_containers',
                    record.id,
                    matched_container_request_of_oto?.id || '',
                    'count',
                  ]}
                >
                  <InputNumber
                    disabled={containers_allocated === 0}
                    min={containers_picked_up}
                    max={_min([containers_allocated, oto_containers_without_rto])} // TODO: this should be number of containers in that oto without any rto... will handle when multiple rto are there
                    precision={0}
                  />
                </Form.Item>
              );
            },
          });
        }
      });

    return cols;
  }, [bookingRequestId, booking_request_container_requests, fieldName, roadTransportOrderId]);

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            required={requiredFields?.includes('load_type')}
            rules={requiredFields?.includes('load_type') ? [{ required: true }] : []}
            label="Load Type"
            name={[...fieldName, 'load_type']}
          >
            <Radio.Group disabled>
              <Radio value="fcl">FCL</Radio>
              <Radio value="loose">Loose</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            required={requiredFields?.includes('vendor')}
            rules={requiredFields?.includes('vendor') ? [{ required: true }] : []}
            label="Transporter"
            name={[...fieldName, 'vendor', 'party_company']}
          >
            <CompanySearch searchProps={{ is_vendor: true }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            required={requiredFields?.includes('managed_by')}
            rules={requiredFields?.includes('managed_by') ? [{ required: true }] : []}
            label="Managed By"
            name={[...fieldName, 'managed_by']}
          >
            <Select disabled={disabledFields?.includes('managed_by')}>
              <Option key="customer" value="customer">
                Customer
              </Option>
              <Option key="forwarder" value="forwarder">
                Your Company
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            required={requiredFields?.includes('factory_location')}
            rules={requiredFields?.includes('factory_location') ? [{ required: true }] : []}
            label="Factory Address"
            name={[...fieldName, 'factory_location']}
          >
            <GlobalSearch
              doc_type="Network::Address"
              searchProps={{
                company_id: shipperCompanyId || customerCompanyId,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {load_type === LOAD_TYPE_FCL && oceanTransportOrders && oceanTransportOrders.length > 0 && (
        <Row style={{ margin: '16px 0 44px' }}>
          <Table
            dataSource={oceanTransportOrders}
            columns={getColumns()}
            pagination={false}
            rowKey={(record) => record.id || ''}
            size="small"
            style={{ width: '100%' }}
          />
        </Row>
      )}
      {load_type === RTO_TYPE_LOOSE && cargos && cargos.length > 0 && (
        <Form.Item
          label={'Allocate Cargo'}
          name={[...fieldName, 'link_cargo_ids']}
          rules={[{ required: true, message: 'Please allocate at-least one cargo' }]}
        >
          <CargosTable cargos={cargos} disabledCargos={disabledCargos || []} />
        </Form.Item>
      )}
    </div>
  );
});

export default RoadTransportOrderForm;
