import { AddCollaborators, RetryDataImportModel, DataImportType } from '@shipmnts/pixel-hub';

interface Props {
  dataImport: DataImportType;
}

export const retryDataImportRender = (payload: Props) => {
  const { dataImport } = payload;

  return {
    actionParams: {
      dataImport: dataImport,
    },
    component: RetryDataImportModel,
  };
};

export const addCollaboratorsRenderer = (payload: Props) => {
  const { dataImport } = payload;

  return {
    actionParams: {
      referenceId: dataImport?.id,
      referenceType: 'Blueprint::DataImport',
      id: dataImport?.name,
    },
    component: AddCollaborators,
  };
};
