import React, { useCallback, useState } from 'react';
import { Row, Col, Select, Input, Button, List, Alert, Form, Checkbox } from '@shipmnts/pixel-hub';
import {
  countries,
  axiosInstance as axios,
  CompanyListItem,
  useSession,
  CompanySearch,
} from 'common';
import { CompanyValue } from 'network/models/Company';
import { LoadingOutlined } from '@shipmnts/pixel-hub';
import { FormInstance } from '@shipmnts/pixel-hub';
import { useLocation } from 'wouter';
import {
  COMPANY_TYPE_INDIVIDUAL,
  COMPANY_TYPE_COMPANY,
  COMPANY_TYPE_PRIVATE,
  COMPANY_TYPE_PUBLIC,
  COMPANY_TYPE_INC,
  COMPANY_TYPE_LLP,
  COMPANY_TYPE_PARTNERSHIP,
} from 'network/constants';

import { INVOICE_TYPE_REGULAR, INVOICE_TYPE_EXPORT_IMPORT } from 'network/regional/IN/constants';
import { fetchCompanyInfoFromMCA } from 'network/externalApis';

import { COMPANY_SEARCH_URL } from 'network/baseConstants';
import { GlobalSearch } from '@shipmnts/pixel-hub';

type highlightType = { registered_name?: string };

const ROW_GUTTER = 32;
const colProps = {
  span: 12,
  xs: { span: 24 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
};

const fetchCompanyRegulatoryData = async (form: FormInstance, registered_name?: string) => {
  const res = await fetchCompanyInfoFromMCA(registered_name);
  const cin =
    (res?.response || []).length === 1
      ? res?.response[0].attributes?.company_identification_number
      : null;
  cin && form.setFieldsValue({ company_identification_number: cin });
};

const CompanyFormBasicDetails = React.memo(function CompanyFormBasicDetails(props: {
  company_id: string;
  form: FormInstance;
  company?: CompanyValue;
  is_lead?: boolean;
  is_customer?: boolean;
}): JSX.Element {
  const { company_id, form, company, is_lead, is_customer } = props;
  const [duplicateCompanies, setDuplicateCompanies] = useState<any[]>([]);
  const [fetchingDuplicates, setFetchingDuplicates] = useState(false);
  const [fetchDuplicatesError, setFetchDuplicatesError] = useState<boolean | string>(false);
  const session = useSession();
  const tenant_country_code = session.company_account?.default_company?.country_of_incorporation;

  const { 1: navigate } = useLocation();

  const fetchDuplicates = useCallback(async (company_name?: string) => {
    let dupCompanies = [];
    if (!company_name) {
      setFetchingDuplicates(false);
      setDuplicateCompanies([]);
      setFetchDuplicatesError(false);
      return;
    }
    setFetchingDuplicates(true);
    setFetchDuplicatesError(false);
    try {
      const response = await axios.get(COMPANY_SEARCH_URL, {
        params: {
          registered_name: company_name,
          limit: 3,
          min_score: 15,
        },
      });
      const { data } = response;
      dupCompanies = data.map(
        (searchRow: { _source: CompanyValue; highlight?: highlightType }) => ({
          company: searchRow._source,
          highlight: searchRow.highlight,
        })
      );
    } catch (e) {
      if (e instanceof Error) setFetchDuplicatesError(e.message);
    }
    setFetchingDuplicates(false);
    setDuplicateCompanies(dupCompanies);
  }, []);

  let helpText = undefined;
  if (fetchingDuplicates) {
    helpText = (
      <span>
        <LoadingOutlined /> Checking for duplicates
      </span>
    );
  }
  if (fetchDuplicatesError) {
    helpText = (
      <span>
        Error while checking for duplicates.{' '}
        <Button
          onClick={() => fetchDuplicates(form.getFieldValue('registered_name'))}
          type="link"
          size="small"
        >
          Retry
        </Button>
      </span>
    );
  }
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item
            required
            rules={[{ required: true }, { max: 140, message: 'Max 140 characters allowed' }]}
            name="registered_name"
            label="Company Name"
            help={helpText}
            validateStatus={fetchDuplicatesError ? 'warning' : undefined}
          >
            <Input
              disabled={company_id !== 'new'}
              onBlur={(e) => {
                fetchDuplicates(e.target.value);
                if (form.getFieldValue('country_of_incorporation') === 'IN')
                  fetchCompanyRegulatoryData(form, e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="country_of_incorporation"
            label="Country"
          >
            <Select
              filterOption={(input, option): boolean => {
                return `${option?.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
              onSelect={(value: any) => {
                if (tenant_country_code === 'IN' && form.getFieldValue('address')?.is_billing) {
                  if (value === 'IN') {
                    form.setFieldsValue({
                      address: {
                        ...form.getFieldValue('address'),
                        preferred_invoice_type: INVOICE_TYPE_REGULAR,
                      },
                    });
                  } else {
                    form.setFieldsValue({
                      address: {
                        ...form.getFieldValue('address'),
                        preferred_invoice_type: INVOICE_TYPE_EXPORT_IMPORT,
                      },
                    });
                  }
                }
              }}
            >
              {countries.map(
                (country: {
                  code: React.Key | null | undefined;
                  name:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => {
                  return (
                    <Select.Option key={country.code} value={country.code}>
                      {country.name}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {!fetchingDuplicates && duplicateCompanies && duplicateCompanies.length > 0 && (
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <Alert
              type="warning"
              banner
              closable
              onClose={() => {
                setDuplicateCompanies([]);
              }}
              message="Looks like a duplicate. Are you sure it isn't any one of the following companies?"
            />
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={duplicateCompanies}
              renderItem={(dupComp) => (
                <List.Item
                  onClick={() => {
                    navigate(`~/view/company/${dupComp.company.id}`);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CompanyListItem
                    key={dupComp.company.id}
                    company={dupComp.company}
                    highlight={dupComp.highlight}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="company_group"
            label="Company Group"
          >
            <GlobalSearch
              doc_type="Network::CompanyGroup"
              extraProps={{ include_disabled: false }}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name="company_identification_number"
            label={`Company Identification Number ${
              tenant_country_code === 'IN' ? '(CIN)' : ''
            } / LLP`}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item
            name="tax_deduction_id"
            label={`Tax Deduction ID ${tenant_country_code === 'IN' ? '(TAN)' : ''}`}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            noStyle
            dependencies={['is_customer', 'is_vendor', 'country_of_incorporation']}
          >
            {() => {
              return (
                <Form.Item
                  name="tax_registration_number"
                  label={`Tax ID ${tenant_country_code === 'IN' ? '(PAN)' : ''}`}
                  rules={[
                    {
                      required:
                        tenant_country_code === 'IN' &&
                        form.getFieldValue('country_of_incorporation') === 'IN' &&
                        (form.getFieldValue('is_customer') || form.getFieldValue('is_vendor')),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item required rules={[{ required: true }]} name="entity_type" label="Company Type">
            <Select>
              {[
                COMPANY_TYPE_COMPANY,
                COMPANY_TYPE_INDIVIDUAL,
                COMPANY_TYPE_PRIVATE,
                COMPANY_TYPE_PUBLIC,
                COMPANY_TYPE_INC,
                COMPANY_TYPE_LLP,
                COMPANY_TYPE_PARTNERSHIP,
              ].map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name="global_company_account" label="Global Company">
            <GlobalSearch doc_type="Network::CompanyAccount" selectMode={undefined} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item name="domain" label="Domain URL">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item noStyle dependencies={['is_customer', 'is_lead']}>
            {() => (
              <Form.Item
                name="sales_partner"
                label="Sales Partner"
                hidden={!form.getFieldValue('is_customer') && !form.getFieldValue('is_lead')}
              >
                <CompanySearch searchProps={{}} hideCompanies={[company_id]} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
      {company && is_lead && !is_customer && (
        <Row gutter={ROW_GUTTER}>
          <Col {...colProps}>
            <Form.Item label="Sales Person" name="sales_person">
              <GlobalSearch doc_type="Network::SalesPerson" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={ROW_GUTTER}>
        <Col {...colProps}>
          <Form.Item noStyle dependencies={['is_customer', 'is_vendor']}>
            {() => (
              <Form.Item name="is_lead" valuePropName="checked">
                <Checkbox
                  disabled={form.getFieldValue('is_customer') || form.getFieldValue('is_vendor')}
                >
                  Is Lead
                </Checkbox>
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});

export default CompanyFormBasicDetails;
