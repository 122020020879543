import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge, ContractChargeValue } from './ContractCharge';
import Carrier, { CarrierValue } from 'common/models/Carrier';
import { isArray as _isArray } from 'lodash';

export const AdditionalChargeLineItem = types
  .model({
    id: types.identifier,
    charge_name: types.maybeNull(types.string),
    charge_basis: types.maybeNull(types.string),
    charge_terms: types.maybeNull(types.enumeration(['prepaid', 'collect'])),
    locations: types.optional(types.array(Location), []),
    terminals: types.optional(types.array(Location), []),
    currency: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    commodity_type: types.maybeNull(types.string),
    carriers: types.optional(types.array(Carrier), []),
    _destroy: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setChargeName: (value: string) => (self.charge_name = value),
    setChargeBasis: (value: string) => {
      self.charge_basis = value;
      if (value === 'shipment') {
        self.contract_charges?.forEach((charge) => charge.setDestroy(true));
      } else {
        self.contract_charges?.forEach((charge) => charge.setDestroy(false));
      }
    },
    setChargeTerms: (value: 'prepaid' | 'collect') => (self.charge_terms = value),
    setCurrency: (value: string) => (self.currency = value),
    setFixedCharge: (value: number) => {
      const charge = self.contract_charges?.[0];

      if (!charge) {
        const newCharge = ContractCharge.create({
          id: 'new',
          charge_amount: value,
          end_weight: null,
        });

        self.contract_charges?.push(newCharge);
        return;
      }

      // when we consider that there is a fixed rate,
      // we assume there will be only one contract charge
      // not more or not less.
      if (!!charge && !charge.isDestroyed()) {
        // just to be safe we put a check here
        charge.setChargeAmount(value);
      }
    },
    setRemarks: (value: string) => (self.remarks = value),
    setCommodityType: (value: string) => (self.commodity_type = value),
    addLocation: (value: LocationValue) => {
      value.id = String(value.id);
      self.locations.push(value);
    },
    addContractCharge: (charge: ContractChargeValue) => {
      self.contract_charges.push(charge);
    },
    setChargeByContainerType: (type: string, amount: number) => {
      const charge = self.contract_charges.find((charge) => charge.container_type === type);
      if (charge && !charge.isDestroyed()) {
        charge.setChargeAmount(amount);
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          container_type: type,
          charge_amount: amount,
          end_weight: null,
        });
        self.contract_charges.push(charge);
      }
    },
    setFixedContainerType: (type: string) => {
      // each surcharge must have one contract charge, and hence one container type
      if (self.contract_charges.length > 1) return;
      self.contract_charges?.[0]?.setContainerType(type);
    },
    setDestroy: (value: boolean) => (self._destroy = value),
    updateCarriers: (value: CarrierValue[]) => {
      const carriers = value.map((carrier) => ({
        ...carrier,
        id: String(carrier.id),
      }));
      self.carriers?.replace(carriers);
    },
    updateTerminals: (value: LocationValue[]) => {
      const terminals = value.map((terminal) => ({
        ...terminal,
        id: String(terminal.id),
        parent_id: String(terminal.parent_id),
      }));
      self.terminals?.replace(terminals);
    },
    updateLocations: (value: LocationValue | LocationValue[]) => {
      if (!_isArray(value)) value = [value];
      const locations = value
        .filter((location) => !!location)
        .map((location) => ({
          ...location,
          id: String(location.id),
        }));
      self.locations.replace(locations);
    },
    isDestroy: () => self._destroy,
  }))
  .views((self) => ({
    getChargeName: () => self.charge_name,
    getChargeBasis: () => self.charge_basis,
    getChargeTerms: () => self.charge_terms,
    getCurrency: () => self.currency,
    getCommodityType: () => self.commodity_type,
    getCarriers: () => self.carriers,
    getFixedCharge: () =>
      self.contract_charges?.length === 1 ? self.contract_charges?.[0].charge_amount : null,
    getFixedContainerType: () =>
      self.contract_charges?.length === 1 ? self.contract_charges?.[0]?.container_type : null,
    getRemarks: () => self.remarks,
    getAllLocations: () => self.locations,
    getTerminals: () => self.terminals,
    getChargeByContainerType: (type: string) => {
      const charge = self.contract_charges.find(
        (charge) => charge.container_type === type && !charge.isDestroyed()
      );
      return charge ? charge.charge_amount : null;
    },
    getPayload: (removeIds = false) => {
      const surcharge = getSnapshot(self);
      const { contract_charges, locations, terminals, carriers, ...rest } = surcharge;
      return {
        ...rest,
        contract_charges: self.contract_charges
          .filter((charge) => (self.charge_basis === 'container' ? !!charge.container_type : true))
          .map((charge) => charge.getPayload()),
        location_ids: self.locations.map((location) => location.id),
        terminal_ids: terminals.map((terminal) => terminal.id),
        carrier_ids: carriers?.map((c) => c.id),
        id: self.id === 'new' || removeIds ? null : self.id,
        _destroy: surcharge._destroy ? true : undefined,
      };
    },
  }));

export type AdditionalChargeLineItemValue = Instance<typeof AdditionalChargeLineItem>;

export default AdditionalChargeLineItem;
