/**
  PURPOSE: Defining a model for Air Contract Line Items.
  CONFIRM: We won't access contract from line item? (at least in frontend)
*/

import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge } from './ContractCharge';
import Carrier, { CarrierValue } from 'common/models/Carrier';
import { DISCOUNT_TYPE_PERCENTAGE } from '../constants';
import { isNil as _isNil } from 'lodash';

export const AirContractLineItem = types
  .model({
    id: types.identifier,
    port_of_loading: types.maybeNull(Location),
    ports_of_discharge: types.optional(types.array(Location), []),
    flight_number: types.maybeNull(types.string),
    flight_date: types.maybeNull(types.number),
    min_charge: types.maybeNull(types.number),
    normal_charge: types.maybeNull(types.number),
    commodity_type: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    carrier: types.maybeNull(types.maybeNull(Carrier)),
    carrier_product: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    charge_terms: types.maybeNull(types.string),
    _destroy: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setAirportOfDischarge(locations: LocationValue[]) {
      const newValues = locations
        .filter((location) => !!location)
        .map((location) => ({
          ...location,
          id: String(location.id),
        }));
      self.ports_of_discharge.replace(newValues);
    },
    setCarrier(carrier: CarrierValue) {
      self.carrier = carrier;
    },
    setValue(
      key: 'flight_number' | 'carrier_product' | 'currency' | 'charge_terms' | 'commodity_type',
      value: string
    ) {
      self[key] = value;
    },
    setFlightDate(date: number) {
      self.flight_date = date;
    },
    setMinCharge(charge: number) {
      self.min_charge = charge;
    },
    setNormalCharge(charge: number) {
      self.normal_charge = charge;
    },
    setChargeByWeight(amount: number, start_weight: number, end_weight?: number | null) {
      const charge = self.contract_charges.find((charge) => charge.start_weight === start_weight);
      if (charge) {
        charge.charge_amount = amount;
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          start_weight: start_weight,
          end_weight: end_weight || null,
          charge_amount: amount,
        });
        self.contract_charges.push(charge);
      }
    },
    setChargeBySurcharge(
      charge_name: string,
      charge_amount: number | null,
      charge_tag?: string | null,
      min_charge_amount?: number
    ) {
      const charge = self.contract_charges.find((charge) => charge.charge_name === charge_name);
      if (charge) {
        if (!_isNil(charge_amount)) charge.charge_amount = charge_amount;
        if (!_isNil(min_charge_amount)) charge.min_charge_amount = min_charge_amount;
        charge.charge_tag = charge_tag || null;
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          min_charge_amount,
          charge_name,
          charge_amount,
          charge_tag: charge_tag || null,
          end_weight: null,
        });
        self.contract_charges.push(charge);
      }
    },
    // Function that sets discount for a specific weight slab, and creates new charge if it doesn't exist.
    setDiscountByWeight(
      start_weight: number,
      end_weight: number | null,
      discount: number | null,
      discount_type: string | null
    ) {
      const charge = self.contract_charges.find((charge) => charge.start_weight === start_weight);
      // if charge is already present, update the discount.
      if (charge) {
        if (discount) charge.setDiscount(discount);
        charge.setDiscountType(discount_type || DISCOUNT_TYPE_PERCENTAGE);
      } else {
        const charge = ContractCharge.create({
          id: 'new',
          start_weight: start_weight,
          end_weight: end_weight,
          discount,
          discount_type: discount_type || DISCOUNT_TYPE_PERCENTAGE,
        });
        self.contract_charges.push(charge);
      }
    },
    setDestroy: (value: boolean) => (self._destroy = value),
    isDestroy: () => self._destroy,
  }))
  .actions((self) => ({
    // Function that sets discounts for all weight slabs.
    setDiscountsForAllCharges(
      discounts: { [key: string]: number | undefined | string },
      weightSlabs: number[]
    ) {
      if (!discounts) return;
      weightSlabs.forEach((weight) => {
        const discountKey = `${weight}_discount`;
        const discount = discounts[discountKey];
        const end_weight =
          weightSlabs.findIndex((w) => w === weight) + 1 < weightSlabs.length
            ? weightSlabs[weightSlabs.findIndex((w) => w === weight) + 1] - 1
            : null;
        if (discount !== undefined) {
          // Set discounts and discount type for each weight slab.
          self.setDiscountByWeight(
            weight,
            end_weight,
            discount as number,
            discounts.basis ? `${discounts.basis}` : DISCOUNT_TYPE_PERCENTAGE
          );
        } else if (discounts.basis) {
          //if only Discount Basis is set
          self.setDiscountByWeight(weight, end_weight, null, `${discounts.basis}`);
        }
      });
    },
  }))
  .views((self) => ({
    getID() {
      return self.id;
    },
    getDiscountByStartWeight(weight: number) {
      const charge = self.contract_charges.find((charge) => charge.start_weight === weight);
      if (!charge) return '';
      return charge.getDiscountString();
    },
    getAirportOfDischarge() {
      return self.ports_of_discharge;
    },
    getCarrier() {
      return self.carrier;
    },
    getCarrierName() {
      return self.carrier ? self.carrier.name : '';
    },
    getCarrierIataCode() {
      return self.carrier ? self.carrier.iata_carrier_code : '';
    },
    getValue(
      key:
        | 'flight_number'
        | 'carrier_product'
        | 'currency'
        | 'flight_date'
        | 'min_charge'
        | 'normal_charge'
        | 'charge_terms'
        | 'commodity_type'
    ) {
      return self[key];
    },
    getChargeByStartWeight(weight: number) {
      const charge = self.contract_charges.find((charge) => charge.start_weight === weight);
      return charge ? charge.charge_amount : null;
    },
    getChargeBySurcharge(title: string) {
      const charge = self.contract_charges.find((charge) => charge.charge_name === title);
      return charge ? charge.charge_amount : null;
    },
    getMinChargeBySurcharge(title: string) {
      const charge = self.contract_charges.find((charge) => charge.charge_name === title);
      return charge ? charge.min_charge_amount : null;
    },
    getPayload: (aod: LocationValue, has_discounts = false, removeIds = false) => {
      const lineItem = getSnapshot(self);
      const { ports_of_discharge, port_of_loading, carrier, ...rest } = lineItem;
      if (!has_discounts) {
        // Remove discounts from charges if not required.
        self.contract_charges?.forEach((charge) => charge.removeDiscount());
      }
      return {
        ...rest,
        port_of_discharge_ids: ports_of_discharge.map((aod) => aod.id),
        port_of_loading_id: aod.id,
        carrier_id: carrier?.id,
        id: lineItem.id === 'new' || removeIds ? null : lineItem.id,
        contract_charges: self.contract_charges?.map((charge) => charge.getPayload(removeIds)),
        _destroy: lineItem._destroy ? true : undefined,
      };
    },
  }));

export type AirContractLineItemValue = Instance<typeof AirContractLineItem>;

export default AirContractLineItem;
