import { BaseTable, MultiLinkRender, TagsRender } from '@shipmnts/pixel-hub';
import { LOAD_TYPE_FCL } from 'operations/baseConstants';
import { Column } from 'operations/models/Report';
import React from 'react';
import allFields from '../../ShipmentForm/allFields';

import {
  DateEditor,
  StringEditor,
  FloatEditor,
  EnumEditor,
  SearchDocTypeEditor,
  RecordLoaderRender,
} from '@shipmnts/pixel-hub';
import { QtyUomTypeRenderer } from 'common/report_manager/components/Renderer/cellRenderer';
import { ShipmentValue } from 'operations/models/Shipment';
import { DocumentName } from '../AttachedHouse/AttachedHousesTable';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

type Props = {
  cargos: ProductOrderItemValue[];
  loadType: string;
  shipment?: ShipmentValue;
};

function CargoDetails({ cargos, loadType, shipment }: Props) {
  const components = {
    DateEditor,
    StringEditor,
    FloatEditor,
    EnumEditor,
    QtyUomTypeRenderer,
    SearchDocTypeEditor,
    RecordLoaderRender,
    MultiLinkRender,
    TagsRender,
  };

  const isLoose = loadType !== LOAD_TYPE_FCL;
  const columnDefs: Column[] = [allFields.product_name()];
  if (shipment?.isRoadCustomerOrder())
    columnDefs.push(
      allFields.job_number(),
      allFields.shipment_number({
        headerName: `${DocumentName[shipment?.freight_type || 'ocean']} #`,
      }),
      allFields.shipment_date({
        headerName: `${DocumentName[shipment?.freight_type || 'ocean']} Date`,
      })
    );
  columnDefs.push(allFields.serial_number(), allFields.invoice_number(), allFields.invoice_date());

  if (isLoose) {
    allFields.gross_volume();
  }

  columnDefs.push(
    allFields.gross_weight(),
    allFields.net_weight(),
    allFields.outer_package_type(),
    allFields.outer_package_qty(),

    allFields.batch_number(),
    allFields.custom_ref(),
    allFields.eway_bill_no(),
    allFields.eway_bill_validity()
  );

  return (
    <BaseTable
      skipCacheColumns={[]}
      onGridReady={(grid: any) => grid.columnApi.autoSizeAllColumns()}
      reportName={'shipment_form_cargo_details'}
      columns={columnDefs}
      rowData={cargos}
      reportConfig={{
        groupDefaultExpanded: 0,
        rowHeight: 40,
        components,
      }}
      checkbox_always_visible={false}
    />
  );
}

export default CargoDetails;
