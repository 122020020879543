import React, { forwardRef, useState } from 'react';
import RoutingDetails, {
  RoutingDetailsValue,
  getNewRoutingNode,
  getInitialNodes,
  RoutingNodesHashValue,
} from 'operations/components/RoutingDetails/';
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  GlobalSearch,
  Radio,
  Row,
} from '@shipmnts/pixel-hub';
import { AddressCompanySearch } from 'common';
import {
  set as _set,
  get as _get,
  difference as _difference,
  omit as _omit,
  range as _range,
} from 'lodash';
import { RoutingLegValue, ROUTING_TYPE_MAIN_CARRIAGE } from 'operations/models/RoutingLeg';
import { RoutingNodeValue } from 'operations/models/RoutingNode';
import { get_legs_by_routing_type } from 'operations/modules/helpers';
import { FreightType } from 'operations/modules/shipment/constants';
import {
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
  STUFFING_LOCATION_TYPE_CFS,
  STUFFING_LOCATION_TYPE_FACTORY,
  STUFFING_LOCATION_TYPE_ICD,
} from 'operations/baseConstants';
import { LOCATION_TYPE_CFS } from 'operations/models/Location';
import {
  STUFFING_TYPE_CFS,
  STUFFING_TYPE_FACTORY,
  STUFFING_TYPE_ICD,
} from 'operations/modules/reports/constants';
const { Panel } = Collapse;

interface BookingRoutingDetailsProps {
  value?: RoutingDetailsValue;
  onChange?: (value: RoutingDetailsValue) => void;
  disableNodes?: boolean;
  disableVoyageUpdate?: boolean;
  validateVesselVoyage?: boolean;
  globalCarrierId?: string;
  bookingType?: string | null;
  isReeferContainer?: boolean;
  allowVoyageScheduleSearch?: boolean;
  onlyTranshipmentHopEditable?: boolean;
  showPickupSection?: boolean;
  showStuffingSection?: boolean;
  showDeStuffingSection?: boolean;
  showMovementSection?: boolean;
  showPodBufferSection?: boolean;
  showPolBufferSection?: boolean;
  showDeliverySection?: boolean;
  freightType?: FreightType;
  loadType?: string;
  form: FormInstance;
  showDpdOnMainCarriage?: boolean;
}

export function stuffing_location_types(loadType: string, disbledOptions: Array<string>) {
  return [
    {
      value: STUFFING_LOCATION_TYPE_FACTORY,
      label: 'Factory',
      disabled:
        loadType === LOAD_TYPE_LCL || disbledOptions?.includes(STUFFING_LOCATION_TYPE_FACTORY),
    },
    {
      value: STUFFING_LOCATION_TYPE_CFS,
      label: 'CFS',
      disabled: disbledOptions?.includes(STUFFING_LOCATION_TYPE_CFS),
    },
    {
      value: STUFFING_LOCATION_TYPE_ICD,
      label: 'ICD',
      disabled: disbledOptions?.includes(STUFFING_LOCATION_TYPE_ICD),
    },
  ];
}

const getInitialDisabledRoutingTypes = (value: any) => {
  const disabled_routes: string[] = [];
  const routing_legs = value?.routing_legs;
  const routing_nodes = value?.routing_nodes;
  if (routing_legs) {
    if (routing_legs?.some((leg: any) => leg.routing_type === 'pol_buffer')) {
      disabled_routes.push('pre_carriage');
    }
    if (routing_legs?.some((leg: any) => leg.routing_type === 'pre_carriage')) {
      disabled_routes.push('pol_buffer');
    }
    if (routing_legs?.some((leg: any) => leg.routing_type === 'pod_buffer')) {
      disabled_routes.push('on_carriage');
    }
    if (routing_legs?.some((leg: any) => leg.routing_type === 'on_carriage')) {
      disabled_routes.push('pod_buffer');
    }
  }
  if (routing_nodes) {
    const hasDestuffingTypeIcd = Object.values(routing_nodes)?.some(
      (node: any) =>
        node.tags.includes('destuffing_location') && node.tags.includes('place_of_carrier_delivery')
    );
    const hasStuffingTypeIcd = Object.values(routing_nodes)?.some(
      (node: any) =>
        node.tags.includes('stuffing_location') && node.tags.includes('place_of_carrier_receipt')
    );
    if (hasStuffingTypeIcd) {
      disabled_routes.push('pre_carriage');
    }
    if (hasDestuffingTypeIcd) {
      disabled_routes.push('on_carriage');
    }
  }
  return disabled_routes;
};

const BookingRoutingDetailsComp = forwardRef(function BookingRoutingDetails(
  props: BookingRoutingDetailsProps,
  ref
) {
  const {
    value,
    onChange,
    disableNodes,
    disableVoyageUpdate,
    globalCarrierId,
    isReeferContainer,
    bookingType,
    allowVoyageScheduleSearch = true,
    onlyTranshipmentHopEditable,
    showStuffingSection = true,
    showDeStuffingSection = true,
    showPolBufferSection = true,
    showPodBufferSection = true,
    freightType = 'ocean',
    loadType,
    form,
    validateVesselVoyage,
    showDpdOnMainCarriage,
  } = props;

  const destuffing_node = Object.entries(value?.routing_nodes || []).find(([key, value]) =>
    value?.tags?.includes('destuffing_location')
  );

  const showStuffing = get_legs_by_routing_type(value?.routing_legs || [], 'pickup').length > 0;
  const showDeStuffing =
    get_legs_by_routing_type(value?.routing_legs || [], 'delivery').length > 0 ||
    !!destuffing_node ||
    false;
  const showPodBuffer =
    get_legs_by_routing_type(value?.routing_legs || [], 'pod_buffer').length > 0;
  const showPolBuffer =
    get_legs_by_routing_type(value?.routing_legs || [], 'pol_buffer').length > 0;
  const showPreCarriage =
    get_legs_by_routing_type(value?.routing_legs || [], 'pre_carriage').length > 0;
  const showOnCarriage =
    get_legs_by_routing_type(value?.routing_legs || [], 'on_carriage').length > 0;

  const initialNodes = getInitialNodes(ROUTING_TYPE_MAIN_CARRIAGE);

  const [disabledRoutingPaths, setDisabledRoutingPaths] = useState<Array<string>>(
    getInitialDisabledRoutingTypes(value)
  );

  const [stuffingOptions, setStuffingOptions] = useState<any>(
    stuffing_location_types(loadType as string, [])
  );

  const [destuffingOptions, setDestuffingOptions] = useState<any>(
    stuffing_location_types(loadType as string, [])
  );

  const all_routing_legs: Array<RoutingLegValue> = value?.routing_legs || [
    {
      routing_type: 'main_carriage',
      origin_id: initialNodes.origin._id,
      destination_id: initialNodes.destination._id,
      mode_of_transit: freightType === 'ocean' ? 'sea' : freightType,
      sequence_number: 2.1,
    } as RoutingLegValue,
  ];
  const all_routing_nodes = value?.routing_nodes || {
    [initialNodes.origin._id || '']: initialNodes.origin,
    [initialNodes.destination._id || '']: initialNodes.destination,
  };
  const updatePreCarriageDestination = (
    preLegs: Array<RoutingLegValue>,
    mainLegs: Array<RoutingLegValue>
  ) => {
    const newPreCarriageLegs = [
      ..._set(preLegs, `${preLegs.length - 1}.destination_id`, _get(mainLegs, '0.origin_id')),
    ];
    return newPreCarriageLegs;
  };

  const updateOnCarriageOrigin = (
    onLegs: Array<RoutingLegValue>,
    mainLegs: Array<RoutingLegValue>
  ) => {
    const newOnCarriageLegs = [
      ..._set(onLegs, '0.origin_id', _get(mainLegs, `${mainLegs.length - 1}.destination_id`)),
    ];
    return newOnCarriageLegs;
  };

  const getRoutingNodesFromLegs = (
    routing_legs: Array<RoutingLegValue>,
    routing_nodes: RoutingNodesHashValue
  ) => {
    const used_nodes_from_legs: Array<string> = [];
    routing_legs.forEach((rl) => {
      if (rl?.origin_id && !used_nodes_from_legs.includes(rl.origin_id))
        used_nodes_from_legs.push(rl.origin_id);
      if (rl.destination_id && !used_nodes_from_legs.includes(rl.destination_id))
        used_nodes_from_legs.push(rl.destination_id);
    });
    const unused_nodes = _difference(Object.keys(routing_nodes), used_nodes_from_legs);
    const new_routing_nodes = _omit(routing_nodes, unused_nodes);
    return new_routing_nodes;
  };

  const onStuffingChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onPreCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onMainCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange)
      onChange({
        routing_legs: [
          ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
          ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
          ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
          ...routingLegs,
          ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
          ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
          ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
        ],
        routing_nodes: {
          ...all_routing_nodes,
          ...routingNodes,
        },
      });
  };

  const onOnCarriageChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onDeStuffingChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...routingLegs,
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onPodBufferChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pol_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const onPolBufferChange = ({
    routing_legs: routingLegs,
    routing_nodes: routingNodes,
  }: {
    routing_legs: Array<RoutingLegValue>;
    routing_nodes: RoutingNodesHashValue;
  }) => {
    if (onChange) {
      const routing_legs = [
        ...get_legs_by_routing_type(all_routing_legs, 'pickup'),
        ...get_legs_by_routing_type(all_routing_legs, 'pre_carriage'),
        ...routingLegs,
        ...get_legs_by_routing_type(all_routing_legs, 'main_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'pod_buffer'),
        ...get_legs_by_routing_type(all_routing_legs, 'on_carriage'),
        ...get_legs_by_routing_type(all_routing_legs, 'delivery'),
      ];
      onChange({
        routing_legs,
        routing_nodes: getRoutingNodesFromLegs(routing_legs, {
          ...all_routing_nodes,
          ...routingNodes,
        }),
      });
    }
  };

  const removeRoutesFromDisabled = (array: Array<string>, itemsToRemove: Array<string>) => {
    return array.filter((item) => !itemsToRemove.includes(item));
  };

  const preCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'pre_carriage');
  const mainCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'main_carriage');
  const onCarriageRoutingLegs = get_legs_by_routing_type(all_routing_legs, 'on_carriage');
  return (
    <Collapse
      activeKey={[
        showStuffing ? 'stuffing_location' : '',
        showPreCarriage ? 'pre_carriage' : '',
        showPolBuffer ? 'pol_movement' : '',
        'main_carriage',
        showPodBuffer ? 'pod_movement' : '',
        showOnCarriage ? 'on_carriage' : '',
        showDeStuffing ? 'destuffing_location' : '',
      ]}
      bordered={false}
      className="routing-detail-custom-collapse"
    >
      {showStuffingSection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showStuffing}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  const pre_carriage_leg = all_routing_legs.find(
                    (leg: any) => leg.routing_type === 'pre_carriage'
                  );
                  const pol_buffer_leg = all_routing_legs.find(
                    (leg: any) => leg.routing_type === 'pol_buffer'
                  );
                  if (pol_buffer_leg && !pre_carriage_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pol_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  if (pre_carriage_leg && !pol_buffer_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pre_carriage',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  if (!pre_carriage_leg && !pol_buffer_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pre_carriage',
                      'pol_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  form.setFieldValue('stuffing_type', 'null');

                  onStuffingChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const origin_node = getNewRoutingNode({
                    tags: ['stuffing_location'],
                  } as RoutingNodeValue);
                  const destination_tag = showPreCarriage
                    ? 'place_of_carrier_receipt'
                    : 'port_of_loading';
                  const destination_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes(destination_tag)
                  );
                  const stuffing_routing_legs = [
                    {
                      routing_type: 'pickup',
                      origin_id: `${origin_node['_id']}`,
                      sequence_number: 0.1,
                      destination_id: destination_node?.[0],
                    } as RoutingLegValue,
                  ];
                  onStuffingChange({
                    routing_legs: stuffing_routing_legs,
                    routing_nodes: { [origin_node._id || '']: origin_node },
                  });
                }
              }}
            >
              {loadType === LOAD_TYPE_FCL ? 'Stuffing Location' : 'Stuffing / Carting Location'}
            </Checkbox>
          }
          key="stuffing_location"
        >
          {showStuffing && (
            <>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    required
                    label="Stuffing Location Type"
                    rules={[{ required: true }]}
                    name="stuffing_type"
                  >
                    <Radio.Group
                      options={stuffingOptions}
                      disabled={disableNodes}
                      onChange={(e) => {
                        if (e.target.value === STUFFING_TYPE_FACTORY) {
                          const shipper = form?.getFieldValue(['party', 'shipper']);
                          form.setFieldsValue({ stuffing_location: shipper });
                          const stuffing_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pickup'
                          );
                          const stuffing_node_id = stuffing_leg?.origin_id;
                          if (stuffing_node_id) {
                            all_routing_nodes[stuffing_node_id] = {
                              ...all_routing_nodes[stuffing_node_id],
                              address: value ? shipper?.party_address : null,
                              company: value ? shipper?.party_company : null,
                              location: null,
                            };
                          }

                          if (stuffing_node_id) {
                            onStuffingChange({
                              routing_legs: [stuffing_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        } else {
                          form.setFieldsValue({ stuffing_location: null });
                          const stuffing_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pickup'
                          );
                          const stuffing_node_id = stuffing_leg?.origin_id;
                          if (stuffing_node_id) {
                            all_routing_nodes[stuffing_node_id] = {
                              ...all_routing_nodes[stuffing_node_id],
                              address: null,
                              company: null,
                              location: null,
                            };
                          }

                          if (stuffing_node_id) {
                            onStuffingChange({
                              routing_legs: [stuffing_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        }

                        if (e.target.value === STUFFING_TYPE_ICD) {
                          setDisabledRoutingPaths([
                            ...disabledRoutingPaths,
                            'pol_buffer',
                            'pre_carriage',
                          ]);
                          const pre_carriage_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pre_carriage'
                          );
                          if (!pre_carriage_leg) {
                            const origin_node = getNewRoutingNode({
                              tags: ['place_of_carrier_receipt', 'stuffing_location'],
                            } as RoutingNodeValue);

                            const pol_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) => value?.tags?.includes('port_of_loading')
                            );
                            const pre_legs = [
                              {
                                routing_type: 'pre_carriage',
                                origin_id: origin_node._id,
                                destination_id: pol_node?.[0],
                                sequence_number: 1.1,
                              } as RoutingLegValue,
                            ];
                            const stuffing_leg = all_routing_legs.find(
                              (leg: any) => leg.routing_type === 'pickup'
                            );
                            if (stuffing_leg) {
                              stuffing_leg.destination_id = origin_node?._id;
                            }
                            if (pol_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[pol_node?.[0]],
                                tags: ['port_of_loading'],
                              } as RoutingNodeValue);
                              if (pol_node?.[1]?.id || pol_node?.[1]?._id) {
                                all_routing_nodes[pol_node?.[1]?._id || pol_node?.[1]?.id || ''] =
                                  new_origin_node;
                              }
                            }
                            const stuffing_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) =>
                                value?.tags?.includes('stuffing_location') &&
                                !value?.tags?.includes('place_of_carrier_receipt')
                            );
                            if (stuffing_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[stuffing_node?.[0]],
                                tags: [],
                              } as unknown as RoutingNodeValue);
                              if (stuffing_node?.[1]?.id || stuffing_node?.[1]?._id) {
                                all_routing_nodes[
                                  stuffing_node?.[1]?._id || stuffing_node?.[1]?.id || ''
                                ] = new_origin_node;
                              }
                            }
                            onPreCarriageChange({
                              routing_legs: pre_legs,
                              routing_nodes: { [origin_node._id || '']: origin_node },
                            });
                          } else {
                            const pocd_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) => value?.tags?.includes('place_of_carrier_receipt')
                            );
                            if (pocd_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[pocd_node?.[0]],
                                tags: ['place_of_carrier_receipt', 'stuffing_location'],
                              } as RoutingNodeValue);
                              if (pocd_node?.[1]?.id || pocd_node?.[1]?._id) {
                                all_routing_nodes[pocd_node?.[1]?._id || pocd_node?.[1]?.id || ''] =
                                  new_origin_node;
                              }
                            }
                            const stuffing_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) =>
                                value?.tags?.includes('stuffing_location') &&
                                !value?.tags?.includes('place_of_carrier_receipt')
                            );
                            if (stuffing_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[stuffing_node?.[0]],
                                tags: [],
                              } as unknown as RoutingNodeValue);
                              if (stuffing_node?.[1]?.id || stuffing_node?.[1]?._id) {
                                all_routing_nodes[
                                  stuffing_node?.[1]?._id || stuffing_node?.[1]?.id || ''
                                ] = new_origin_node;
                              }
                            }
                            onPreCarriageChange({
                              routing_legs: get_legs_by_routing_type(
                                all_routing_legs,
                                'pre_carriage'
                              ),
                              routing_nodes: all_routing_nodes,
                            });
                          }

                          const pol_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                            value?.tags?.includes('port_of_loading')
                          );
                          if (pol_node?.[0]) {
                            const new_origin_node = getNewRoutingNode({
                              ...all_routing_nodes[pol_node?.[0]],
                              tags: ['port_of_loading'],
                            } as RoutingNodeValue);
                            if (pol_node?.[1]?.id || pol_node?.[1]?._id) {
                              all_routing_nodes[pol_node?.[1]?._id || pol_node?.[1]?.id || ''] =
                                new_origin_node;
                            }
                          }
                        }
                        if (e.target.value === STUFFING_TYPE_CFS) {
                          const new_disabled_routes = removeRoutesFromDisabled(
                            disabledRoutingPaths,
                            ['pre_carriage']
                          );
                          const pickup_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pickup'
                          );
                          const pickup_origin_id = pickup_leg?.origin_id;
                          const stuffing_old_node = Object.entries(all_routing_nodes).find(
                            ([key, value]) =>
                              value?.id === pickup_origin_id || value?._id === pickup_origin_id
                          );
                          if (stuffing_old_node?.[0]) {
                            const new_dest_node = getNewRoutingNode({
                              ...all_routing_nodes[stuffing_old_node?.[0]],
                              tags: ['stuffing_location'],
                            } as RoutingNodeValue);
                            if (stuffing_old_node?.[1]?.id || stuffing_old_node?.[1]?._id) {
                              all_routing_nodes[
                                stuffing_old_node?.[1]?._id || stuffing_old_node?.[1]?.id || ''
                              ] = new_dest_node;
                            }
                          }
                          const place_of_carrier_receipt_node = Object.entries(
                            all_routing_nodes
                          ).find(
                            ([key, value]) =>
                              value?.tags?.includes('place_of_carrier_receipt') &&
                              !value?.tags?.includes('port_of_loading')
                          );
                          if (place_of_carrier_receipt_node?.[0]) {
                            const new_pocd_node = getNewRoutingNode({
                              ...all_routing_nodes[place_of_carrier_receipt_node?.[0]],
                              tags: ['place_of_carrier_receipt'],
                            } as RoutingNodeValue);
                            if (
                              place_of_carrier_receipt_node?.[1]?.id ||
                              place_of_carrier_receipt_node?.[1]?._id
                            ) {
                              all_routing_nodes[
                                place_of_carrier_receipt_node?.[1]?._id ||
                                  place_of_carrier_receipt_node?.[1]?.id ||
                                  ''
                              ] = new_pocd_node;
                            }
                          }
                          if (pickup_leg) {
                            onStuffingChange({
                              routing_legs: [pickup_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                          setDisabledRoutingPaths([...new_disabled_routes, 'pol_buffer']);
                        }
                        if (e.target.value === STUFFING_TYPE_FACTORY) {
                          const pre_carriage_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pre_carriage'
                          );
                          const pol_buffer_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pol_buffer'
                          );
                          if (pre_carriage_leg) {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['pre_carriage']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          } else if (pol_buffer_leg) {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['pol_buffer']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          } else {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['pol_buffer', 'pre_carriage']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          }
                          const pickup_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pickup'
                          );
                          const pickup_origin_id = pickup_leg?.origin_id;
                          const stuffing_old_node = Object.entries(all_routing_nodes).find(
                            ([key, value]) =>
                              value?.id === pickup_origin_id || value?._id === pickup_origin_id
                          );
                          if (stuffing_old_node?.[0]) {
                            const new_dest_node = getNewRoutingNode({
                              ...all_routing_nodes[stuffing_old_node?.[0]],
                              tags: ['stuffing_location'],
                            } as RoutingNodeValue);
                            if (stuffing_old_node?.[1]?.id || stuffing_old_node?.[1]?._id) {
                              all_routing_nodes[
                                stuffing_old_node?.[1]?._id || stuffing_old_node?.[1]?.id || ''
                              ] = new_dest_node;
                            }
                          }
                          const place_of_carrier_receipt_node = Object.entries(
                            all_routing_nodes
                          ).find(
                            ([key, value]) =>
                              value?.tags?.includes('place_of_carrier_receipt') &&
                              !value?.tags?.includes('port_of_loading')
                          );
                          if (place_of_carrier_receipt_node?.[0]) {
                            const new_pocd_node = getNewRoutingNode({
                              ...all_routing_nodes[place_of_carrier_receipt_node?.[0]],
                              tags: ['place_of_carrier_receipt'],
                            } as RoutingNodeValue);
                            if (
                              place_of_carrier_receipt_node?.[1]?.id ||
                              place_of_carrier_receipt_node?.[1]?._id
                            ) {
                              all_routing_nodes[
                                place_of_carrier_receipt_node?.[1]?._id ||
                                  place_of_carrier_receipt_node?.[1]?.id ||
                                  ''
                              ] = new_pocd_node;
                            }
                          }

                          if (pickup_leg) {
                            onStuffingChange({
                              routing_legs: [pickup_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const stuffingType = getFieldValue('stuffing_type');
                      if (stuffingType === STUFFING_TYPE_FACTORY) {
                        return (
                          <Form.Item
                            name="stuffing_location"
                            label="Stuffing Location"
                            required
                            rules={[{ required: true }]}
                          >
                            <AddressCompanySearch
                              companySearchProps={{
                                disabled: disableNodes,
                              }}
                              addressSearchProps={{
                                disabled: disableNodes,
                              }}
                              onChange={(val: any) => {
                                const stuffing_leg = all_routing_legs.find(
                                  (leg: any) => leg.routing_type === 'pickup'
                                );
                                const stuffing_node_id = stuffing_leg?.origin_id;
                                if (stuffing_node_id) {
                                  all_routing_nodes[stuffing_node_id] = {
                                    ...all_routing_nodes[stuffing_node_id],
                                    address: value ? val?.party_address : null,
                                    company: value ? val?.party_company : null,
                                    location: null,
                                  };
                                }

                                if (stuffing_node_id) {
                                  onStuffingChange({
                                    routing_legs: [stuffing_leg],
                                    routing_nodes: all_routing_nodes,
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        );
                      } else if (stuffingType === STUFFING_TYPE_CFS) {
                        return (
                          <Form.Item
                            name="stuffing_location"
                            label="Stuffing Location"
                            required
                            rules={[{ required: true }]}
                          >
                            <GlobalSearch
                              disabled={disableNodes}
                              doc_type="Global::Location"
                              searchProps={{
                                type: [LOCATION_TYPE_CFS],
                              }}
                              onChange={(val: any) => {
                                const stuffing_leg = all_routing_legs.find(
                                  (leg: any) => leg.routing_type === 'pickup'
                                );
                                const stuffing_node_id = stuffing_leg?.origin_id;
                                if (stuffing_node_id) {
                                  all_routing_nodes[stuffing_node_id] = {
                                    ...all_routing_nodes[stuffing_node_id],
                                    address: null,
                                    company: null,
                                    location: val,
                                  };
                                }

                                if (stuffing_leg) {
                                  onStuffingChange({
                                    routing_legs: [stuffing_leg],
                                    routing_nodes: all_routing_nodes,
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        );
                      } else return <></>;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Panel>
      )}
      {showPolBufferSection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showPolBuffer}
              disabled={
                disableNodes ||
                onlyTranshipmentHopEditable ||
                disabledRoutingPaths.includes('pol_buffer')
              }
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  if (showStuffing) {
                    const destination_tag = 'port_of_loading';
                    const destination_node_for_stuffing = Object.entries(all_routing_nodes).find(
                      ([key, value]) => value?.tags?.includes(destination_tag)
                    );
                    const stuffing_leg = get_legs_by_routing_type(all_routing_legs, 'pickup')[0];
                    stuffing_leg.destination_id = destination_node_for_stuffing?.[0];
                    if (stuffing_leg) {
                      onStuffingChange({
                        routing_legs: [stuffing_leg],
                        routing_nodes: all_routing_nodes,
                      });
                    }
                  }
                  const stuffing_type = form?.getFieldValue('stuffing_type');
                  const stuffing_leg = get_legs_by_routing_type(all_routing_legs, 'pickup')[0];
                  if (stuffing_type === STUFFING_TYPE_FACTORY || !stuffing_type || !stuffing_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pre_carriage',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  setStuffingOptions(stuffing_location_types(loadType as string, []));
                  onPolBufferChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  setDisabledRoutingPaths([...disabledRoutingPaths, 'pre_carriage']);
                  form.setFieldValue('pol_buffer_location', null);
                  const origin_node = getNewRoutingNode({
                    tags: ['origin_cfs'],
                  } as RoutingNodeValue);
                  const destination_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_loading')
                  );
                  const pol_buffer_legs = [
                    {
                      routing_type: 'pol_buffer',
                      destination_id: destination_node?.[0],
                      origin_id: origin_node._id,
                      sequence_number: 0.2,
                    } as RoutingLegValue,
                  ];
                  if (showStuffing) {
                    const stuffing_leg = get_legs_by_routing_type(all_routing_legs, 'pickup')[0];
                    if (stuffing_leg) {
                      stuffing_leg.destination_id = origin_node._id;
                    }
                  }
                  const new_stuffing_options = stuffing_location_types(loadType as string, [
                    STUFFING_LOCATION_TYPE_CFS,
                    STUFFING_LOCATION_TYPE_ICD,
                  ]);
                  setStuffingOptions(new_stuffing_options);
                  onPolBufferChange({
                    routing_legs: pol_buffer_legs,
                    routing_nodes: { [origin_node._id || '']: origin_node },
                  });
                }
              }}
            >
              Origin CFS Movement
            </Checkbox>
          }
          key="pol_movement"
        >
          {showPolBuffer && (
            <>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="Buffer Location"
                    name="pol_buffer_location"
                    required
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{
                        type: [LOCATION_TYPE_CFS],
                      }}
                      disabled={disableNodes}
                      onChange={(val: any) => {
                        const pol_buffer_leg = all_routing_legs.find(
                          (leg: any) => leg.routing_type === 'pol_buffer'
                        );
                        const origin_cfs_node_id = pol_buffer_leg?.origin_id;
                        if (origin_cfs_node_id) {
                          all_routing_nodes[origin_cfs_node_id] = {
                            ...all_routing_nodes[origin_cfs_node_id],
                            location: val,
                          };
                        }

                        if (pol_buffer_leg) {
                          onPolBufferChange({
                            routing_legs: [pol_buffer_leg],
                            routing_nodes: all_routing_nodes,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Panel>
      )}

      {freightType === 'ocean' && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showPreCarriage}
              disabled={
                disableNodes ||
                onlyTranshipmentHopEditable ||
                disabledRoutingPaths.includes('pre_carriage')
              }
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  if (showStuffing) {
                    const destination_tag = 'port_of_loading';
                    const destination_node_for_stuffing = Object.entries(all_routing_nodes).find(
                      ([key, value]) => value?.tags?.includes(destination_tag)
                    );
                    const stuffing_leg = get_legs_by_routing_type(all_routing_legs, 'pickup')[0];
                    stuffing_leg.destination_id = destination_node_for_stuffing?.[0];
                    if (stuffing_leg) {
                      onStuffingChange({
                        routing_legs: [stuffing_leg],
                        routing_nodes: all_routing_nodes,
                      });
                    }
                  }
                  const stuffing_type = form?.getFieldValue('stuffing_type');
                  const stuffing_legs = get_legs_by_routing_type(all_routing_legs, 'pickup')[0];
                  if (stuffing_type === STUFFING_TYPE_FACTORY || !stuffing_type || !stuffing_legs) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pol_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  const pol_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_loading')
                  );
                  if (pol_node?.[0]) {
                    const new_origin_node = getNewRoutingNode({
                      ...all_routing_nodes[pol_node?.[0]],
                      tags: ['port_of_loading', 'place_of_carrier_receipt'],
                    } as RoutingNodeValue);
                    if (pol_node?.[1]?.id || pol_node?.[1]?._id) {
                      all_routing_nodes[pol_node?.[1]?._id || pol_node?.[1]?.id || ''] =
                        new_origin_node;
                    }
                  }
                  const pickup_leg = all_routing_legs.find(
                    (leg: any) => leg.routing_type === 'pickup'
                  );
                  if (pickup_leg) {
                    const pickup_origin_id = pickup_leg?.origin_id;
                    const stuffing_old_node = Object.entries(all_routing_nodes).find(
                      ([key, value]) =>
                        value?.id === pickup_origin_id || value?._id === pickup_origin_id
                    );
                    if (stuffing_old_node?.[0]) {
                      const new_dest_node = getNewRoutingNode({
                        ...all_routing_nodes[stuffing_old_node?.[0]],
                        tags: ['stuffing_location'],
                      } as RoutingNodeValue);
                      if (stuffing_old_node?.[1]?.id || stuffing_old_node?.[1]?._id) {
                        all_routing_nodes[
                          stuffing_old_node?.[1]?._id || stuffing_old_node?.[1]?.id || ''
                        ] = new_dest_node;
                      }
                    }
                  }

                  onPreCarriageChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  setDisabledRoutingPaths([...disabledRoutingPaths, 'pol_buffer']);
                  const origin_node = getNewRoutingNode({
                    tags: ['place_of_carrier_receipt'],
                  } as RoutingNodeValue);
                  const pre_legs = updatePreCarriageDestination(
                    [
                      {
                        routing_type: 'pre_carriage',
                        origin_id: origin_node._id,
                        sequence_number: 1.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'main_carriage')
                  );
                  const pol_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_loading')
                  );
                  if (pol_node?.[0]) {
                    const new_origin_node = getNewRoutingNode({
                      ...all_routing_nodes[pol_node?.[0]],
                      tags: ['port_of_loading'],
                    } as RoutingNodeValue);
                    if (pol_node?.[1]?.id || pol_node?.[1]?._id) {
                      all_routing_nodes[pol_node?.[1]?._id || pol_node?.[1]?.id || ''] =
                        new_origin_node;
                    }
                  }

                  if (showStuffing) {
                    const stuffing_leg = get_legs_by_routing_type(
                      all_routing_legs,
                      'stuffing_location'
                    )[0];
                    if (stuffing_leg) {
                      stuffing_leg.destination_id = origin_node._id;
                    }
                  }
                  onPreCarriageChange({
                    routing_legs: pre_legs,
                    routing_nodes: { [origin_node._id || '']: origin_node },
                  });
                }
              }}
            >
              Pre carriage
            </Checkbox>
          }
          key="pre_carriage"
        >
          {showPreCarriage && (
            <RoutingDetails
              startSequence={1}
              endSequence={2}
              routing_type="pre_carriage"
              value={{
                routing_legs: preCarriageRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              onChange={onPreCarriageChange}
              locationSearchType={['ICD', 'Seaport']}
              ref={ref}
              disableNodes={
                disableNodes
                  ? _range(preCarriageRoutingLegs.length + 1)
                  : [preCarriageRoutingLegs.length]
              }
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? [0, preCarriageRoutingLegs.length] : []
              }
              disableVoyageUpdate={disableVoyageUpdate}
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
              validateVesselVoyage={false}
              showTerminal={true}
            />
          )}
        </Panel>
      )}

      <Panel
        collapsible={'disabled'}
        forceRender
        showArrow={false}
        header={
          <Checkbox checked={true} disabled={false}>
            Main Carriage
          </Checkbox>
        }
        key="main_carriage"
      >
        <RoutingDetails
          startSequence={2}
          endSequence={3}
          routing_type="main_carriage"
          value={{
            routing_legs: mainCarriageRoutingLegs,
            routing_nodes: all_routing_nodes,
          }}
          onChange={onMainCarriageChange}
          locationSearchType={freightType === 'ocean' ? ['Seaport'] : ['Airport']}
          ref={ref}
          disableNodes={disableNodes ? _range(mainCarriageRoutingLegs.length + 1) : []}
          disableRoutingLegs={
            onlyTranshipmentHopEditable ? [0, mainCarriageRoutingLegs.length] : []
          }
          disableAddRemoveTranshipmentHop={disableNodes}
          validateVesselVoyage={validateVesselVoyage}
          showTerminal={freightType === 'air' ? false : true}
          freightType={freightType}
          globalCarrierId={globalCarrierId}
          isReeferContainer={isReeferContainer}
          bookingType={bookingType}
          allowVoyageScheduleSearch={allowVoyageScheduleSearch}
          disableVoyageUpdate={disableVoyageUpdate}
          showDpd={showDpdOnMainCarriage}
        />
      </Panel>

      {freightType !== 'air' && (
        <Panel
          collapsible={'disabled'}
          forceRender
          showArrow={false}
          header={
            <Checkbox
              checked={showOnCarriage}
              disabled={
                disableNodes ||
                onlyTranshipmentHopEditable ||
                disabledRoutingPaths.includes('on_carriage')
              }
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  if (showDeStuffing) {
                    const origin_tag = 'port_of_discharge';
                    const origin_node_for_destuffing = Object.entries(all_routing_nodes).find(
                      ([key, value]) => value?.tags?.includes(origin_tag)
                    );
                    const destuffing_leg = get_legs_by_routing_type(
                      all_routing_legs,
                      'delivery'
                    )[0];
                    destuffing_leg.origin_id = origin_node_for_destuffing?.[0];
                    if (destuffing_leg) {
                      onStuffingChange({
                        routing_legs: [destuffing_leg],
                        routing_nodes: all_routing_nodes,
                      });
                    }
                  }
                  const destuffing_type = form?.getFieldValue('destuffing_type');
                  const destuffing_legs = get_legs_by_routing_type(all_routing_legs, 'delivery')[0];
                  if (
                    destuffing_type === STUFFING_TYPE_FACTORY ||
                    !destuffing_type ||
                    !destuffing_legs
                  ) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pod_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  const pod_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_discharge')
                  );
                  if (pod_node?.[0]) {
                    const new_origin_node = getNewRoutingNode({
                      ...all_routing_nodes[pod_node?.[0]],
                      tags: ['port_of_discharge', 'place_of_carrier_delivery'],
                    } as RoutingNodeValue);
                    if (pod_node?.[1]?.id || pod_node?.[1]?._id) {
                      all_routing_nodes[pod_node?.[1]?._id || pod_node?.[1]?.id || ''] =
                        new_origin_node;
                    }
                  }
                  onOnCarriageChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  setDisabledRoutingPaths([...disabledRoutingPaths, 'pod_buffer']);
                  form.setFieldValue('direct_port_delivery', null);
                  const destination_node = getNewRoutingNode({
                    tags: ['place_of_carrier_delivery'],
                  } as RoutingNodeValue);
                  const on_legs = updateOnCarriageOrigin(
                    [
                      {
                        routing_type: 'on_carriage',
                        destination_id: destination_node._id,
                        sequence_number: 3.1,
                      } as RoutingLegValue,
                    ],
                    get_legs_by_routing_type(all_routing_legs, 'main_carriage')
                  );
                  const pod_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_discharge')
                  );
                  if (pod_node?.[0]) {
                    const new_origin_node = getNewRoutingNode({
                      ...all_routing_nodes[pod_node?.[0]],
                      tags: ['port_of_discharge'],
                    } as RoutingNodeValue);
                    if (pod_node?.[1]?.id || pod_node?.[1]?._id) {
                      all_routing_nodes[pod_node?.[1]?._id || pod_node?.[1]?.id || ''] =
                        new_origin_node;
                    }
                  }
                  if (showDeStuffing) {
                    const destuffing_leg = get_legs_by_routing_type(
                      all_routing_legs,
                      'delivery'
                    )[0];
                    if (destuffing_leg) {
                      destuffing_leg.origin_id = destination_node._id || '';
                    }
                  }
                  onOnCarriageChange({
                    routing_legs: on_legs,
                    routing_nodes: { [destination_node._id || '']: destination_node },
                  });
                }
              }}
            >
              On Carriage
            </Checkbox>
          }
          key="on_carriage"
        >
          {showOnCarriage && (
            <RoutingDetails
              startSequence={3}
              endSequence={4}
              routing_type="on_carriage"
              value={{
                routing_legs: onCarriageRoutingLegs,
                routing_nodes: all_routing_nodes,
              }}
              onChange={onOnCarriageChange}
              locationSearchType={['ICD', 'Seaport']}
              ref={ref}
              disableNodes={disableNodes ? _range(onCarriageRoutingLegs.length + 1) : [0]}
              disableRoutingLegs={
                onlyTranshipmentHopEditable ? [0, onCarriageRoutingLegs.length] : []
              }
              disableAddRemoveTranshipmentHop={onlyTranshipmentHopEditable || disableNodes}
              validateVesselVoyage={false}
              disableVoyageUpdate={disableVoyageUpdate}
              showTerminal={true}
            />
          )}
        </Panel>
      )}
      {showPodBufferSection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showPodBuffer}
              disabled={
                disableNodes ||
                onlyTranshipmentHopEditable ||
                disabledRoutingPaths.includes('pod_buffer')
              }
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  if (showDeStuffing) {
                    const origin_tag = 'port_of_discharge';
                    const origin_node_for_destuffing = Object.entries(all_routing_nodes).find(
                      ([key, value]) => value?.tags?.includes(origin_tag)
                    );
                    const destuffing_leg = get_legs_by_routing_type(
                      all_routing_legs,
                      'delivery'
                    )[0];
                    destuffing_leg.origin_id = origin_node_for_destuffing?.[0];
                    if (destuffing_leg) {
                      onStuffingChange({
                        routing_legs: [destuffing_leg],
                        routing_nodes: all_routing_nodes,
                      });
                    }
                  }
                  const destuffing_type = form?.getFieldValue('destuffing_type');
                  const destuffing_leg = get_legs_by_routing_type(all_routing_legs, 'delivery')[0];
                  if (
                    destuffing_type === STUFFING_TYPE_FACTORY ||
                    !destuffing_type ||
                    !destuffing_leg
                  ) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'on_carriage',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  setDestuffingOptions(stuffing_location_types(loadType as string, []));
                  onPodBufferChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  form.setFieldValue('pod_buffer_location', null);
                  setDisabledRoutingPaths([...disabledRoutingPaths, 'on_carriage']);
                  const destination_node = getNewRoutingNode({
                    tags: ['destination_cfs'],
                  } as RoutingNodeValue);
                  const origin_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes('port_of_discharge')
                  );
                  const pod_buffer_legs = [
                    {
                      routing_type: 'pod_buffer',
                      destination_id: destination_node._id,
                      origin_id: origin_node?.[0],
                      sequence_number: 4.1,
                    } as RoutingLegValue,
                  ];
                  if (showDeStuffing) {
                    const destuffing_leg = get_legs_by_routing_type(
                      all_routing_legs,
                      'delivery'
                    )[0];
                    if (destuffing_leg) {
                      destuffing_leg.origin_id = destination_node._id || '';
                    }
                  }
                  const new_destuffing_options = stuffing_location_types(loadType as string, [
                    STUFFING_LOCATION_TYPE_CFS,
                    STUFFING_LOCATION_TYPE_ICD,
                  ]);
                  setDestuffingOptions(new_destuffing_options);
                  onPodBufferChange({
                    routing_legs: pod_buffer_legs,
                    routing_nodes: { [destination_node._id || '']: destination_node },
                  });
                }
              }}
            >
              Destination CFS Movement
            </Checkbox>
          }
          key="pod_movement"
        >
          {showPodBuffer && (
            <>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="pod_buffer_location"
                    label="Buffer Location"
                    required
                    rules={[{ required: true }]}
                  >
                    <GlobalSearch
                      doc_type="Global::Location"
                      searchProps={{
                        type: [LOCATION_TYPE_CFS],
                      }}
                      disabled={disableNodes}
                      onChange={(val: any) => {
                        const pod_buffer_leg = all_routing_legs.find(
                          (leg: any) => leg.routing_type === 'pod_buffer'
                        );
                        const destination_cfs_node_id = pod_buffer_leg?.destination_id;
                        if (destination_cfs_node_id) {
                          all_routing_nodes[destination_cfs_node_id] = {
                            ...all_routing_nodes[destination_cfs_node_id],
                            location: val,
                          };
                        }

                        if (pod_buffer_leg) {
                          onPodBufferChange({
                            routing_legs: [pod_buffer_leg],
                            routing_nodes: all_routing_nodes,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Panel>
      )}

      {showDeStuffingSection && (
        <Panel
          showArrow={false}
          collapsible={'disabled'}
          forceRender
          header={
            <Checkbox
              checked={showDeStuffing}
              disabled={disableNodes || onlyTranshipmentHopEditable}
              onChange={(e) => {
                const checked = e.target.checked;
                if (!checked) {
                  const on_carriage_leg = all_routing_legs.find(
                    (leg: any) => leg.routing_type === 'on_carriage'
                  );
                  const pod_buffer_leg = all_routing_legs.find(
                    (leg: any) => leg.routing_type === 'pod_buffer'
                  );
                  if (pod_buffer_leg && !on_carriage_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'pod_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  if (on_carriage_leg && !pod_buffer_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'on_carriage',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  if (!on_carriage_leg && !pod_buffer_leg) {
                    const new_disabled_routes = removeRoutesFromDisabled(disabledRoutingPaths, [
                      'on_carriage',
                      'pod_buffer',
                    ]);
                    setDisabledRoutingPaths([...new_disabled_routes]);
                  }
                  form.setFieldValue('destuffing_type', 'null');
                  const pocd_node = Object.entries(all_routing_nodes).find(
                    ([key, value]) =>
                      value?.tags?.includes('place_of_carrier_delivery') &&
                      !value?.tags?.includes('port_of_discharge')
                  );
                  if (pocd_node?.[0]) {
                    const new_origin_node = getNewRoutingNode({
                      ...all_routing_nodes[pocd_node?.[0]],
                      tags: ['place_of_carrier_delivery'],
                    } as RoutingNodeValue);
                    if (pocd_node?.[1]?.id || pocd_node?.[1]?._id) {
                      all_routing_nodes[pocd_node?.[1]?._id || pocd_node?.[1]?.id || ''] =
                        new_origin_node;
                    }
                  }
                  onDeStuffingChange({ routing_legs: [], routing_nodes: all_routing_nodes });
                } else {
                  const destination_node = getNewRoutingNode({
                    tags: ['destuffing_location'],
                  } as RoutingNodeValue);
                  const origin_tag = showOnCarriage
                    ? 'place_of_carrier_delivery'
                    : 'port_of_discharge';
                  const origin_node = Object.entries(all_routing_nodes).find(([key, value]) =>
                    value?.tags?.includes(origin_tag)
                  );
                  const destuffing_routing_legs = [
                    {
                      routing_type: 'delivery',
                      origin_id: origin_node?.[0],
                      sequence_number: 4.2,
                      destination_id: `${destination_node['_id']}`,
                    } as RoutingLegValue,
                  ];
                  onDeStuffingChange({
                    routing_legs: destuffing_routing_legs,
                    routing_nodes: { [destination_node._id || '']: destination_node },
                  });
                }
              }}
            >
              Destuffing Location
            </Checkbox>
          }
          key="destuffing_location"
        >
          {showDeStuffing && (
            <>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="Destuffing Location Type"
                    required
                    rules={[{ required: true }]}
                    name="destuffing_type"
                  >
                    <Radio.Group
                      options={destuffingOptions}
                      disabled={disableNodes}
                      onChange={(e) => {
                        // if (e.target.value === STUFFING_LOCATION_TYPE_ICD) {
                        //   all_routing_legs.filter(rl => rl.routing_type != 'delivery');
                        //   onDeStuffingChange({
                        //     routing_legs: [],
                        //     routing_nodes: all_routing_nodes,
                        //   });
                        // } else {
                        //   const delivery_leg = all_routing_legs.find(
                        //     (leg: any) => leg.routing_type === 'delivery'
                        //   );
                        //   if (!delivery_leg) {
                        //     const destination_node = getNewRoutingNode({
                        //       tags: ['destuffing_location'],
                        //     } as RoutingNodeValue);
                        //     const origin_tag = showOnCarriage
                        //       ? 'place_of_carrier_delivery'
                        //       : 'port_of_discharge';
                        //     const origin_node = Object.entries(all_routing_nodes).find(
                        //       ([key, value]) => value?.tags?.includes(origin_tag)
                        //     );
                        //     const destuffing_routing_legs = [
                        //       {
                        //         routing_type: 'delivery',
                        //         origin_id: origin_node?.[0],
                        //         sequence_number: 4.2,
                        //         destination_id: `${destination_node['_id']}`,
                        //       } as RoutingLegValue,
                        //     ];
                        //     onDeStuffingChange({
                        //       routing_legs: destuffing_routing_legs,
                        //       routing_nodes: { [destination_node._id || '']: destination_node },
                        //     });
                        //   }
                        // }

                        if (e.target.value === STUFFING_TYPE_FACTORY) {
                          const consignee = form?.getFieldValue(['party', 'consignee']);
                          form.setFieldsValue({ destuffing_location: consignee });
                          const destuffing_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'delivery'
                          );
                          const destuffing_node_id = destuffing_leg?.destination_id;
                          if (destuffing_node_id) {
                            all_routing_nodes[destuffing_node_id] = {
                              ...all_routing_nodes[destuffing_node_id],
                              address: value ? consignee?.party_address : null,
                              company: value ? consignee?.party_company : null,
                              location: null,
                            };
                          }

                          if (destuffing_node_id) {
                            onDeStuffingChange({
                              routing_legs: [destuffing_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        } else {
                          form.setFieldsValue({ destuffing_location: null });
                          const destuffing_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'delivery'
                          );
                          const destuffing_node_id = destuffing_leg?.destination_id;
                          if (destuffing_node_id) {
                            all_routing_nodes[destuffing_node_id] = {
                              ...all_routing_nodes[destuffing_node_id],
                              address: null,
                              company: null,
                              location: null,
                            };
                          }

                          if (destuffing_node_id) {
                            onDeStuffingChange({
                              routing_legs: [destuffing_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        }

                        if (e.target.value === STUFFING_TYPE_ICD) {
                          setDisabledRoutingPaths([
                            ...disabledRoutingPaths,
                            'pod_buffer',
                            'on_carriage',
                          ]);
                          const on_carriage_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'on_carriage'
                          );
                          if (!on_carriage_leg) {
                            const destination_node = getNewRoutingNode({
                              tags: ['place_of_carrier_delivery', 'destuffing_location'],
                            } as RoutingNodeValue);
                            const pod_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) => value?.tags?.includes('port_of_discharge')
                            );
                            const on_legs = [
                              {
                                routing_type: 'on_carriage',
                                origin_id: pod_node?.[0],
                                destination_id: destination_node._id,
                                sequence_number: 3.1,
                              } as RoutingLegValue,
                            ];

                            if (pod_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[pod_node?.[0]],
                                tags: ['port_of_discharge'],
                              } as RoutingNodeValue);
                              if (pod_node?.[1]?.id || pod_node?.[1]?._id) {
                                all_routing_nodes[pod_node?.[1]?._id || pod_node?.[1]?.id || ''] =
                                  new_origin_node;
                              }
                            }
                            const destuffing_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) =>
                                value?.tags?.includes('destuffing_location') &&
                                !value?.tags?.includes('place_of_carrier_delivery')
                            );
                            if (destuffing_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[destuffing_node?.[0]],
                                tags: [],
                              } as unknown as RoutingNodeValue);
                              if (destuffing_node?.[1]?.id || destuffing_node?.[1]?._id) {
                                all_routing_nodes[
                                  destuffing_node?.[1]?._id || destuffing_node?.[1]?.id || ''
                                ] = new_origin_node;
                              }
                            }
                            onOnCarriageChange({
                              routing_legs: on_legs,
                              routing_nodes: { [destination_node._id || '']: destination_node },
                            });
                            form.setFieldValue('direct_port_delivery', null);
                          } else {
                            const pocd_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) => value?.tags?.includes('place_of_carrier_delivery')
                            );
                            if (pocd_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[pocd_node?.[0]],
                                tags: ['place_of_carrier_delivery', 'destuffing_location'],
                              } as RoutingNodeValue);
                              if (pocd_node?.[1]?.id || pocd_node?.[1]?._id) {
                                all_routing_nodes[pocd_node?.[1]?._id || pocd_node?.[1]?.id || ''] =
                                  new_origin_node;
                              }
                            }
                            const destuffing_node = Object.entries(all_routing_nodes).find(
                              ([key, value]) =>
                                value?.tags?.includes('destuffing_location') &&
                                !value?.tags?.includes('place_of_carrier_delivery')
                            );
                            if (destuffing_node?.[0]) {
                              const new_origin_node = getNewRoutingNode({
                                ...all_routing_nodes[destuffing_node?.[0]],
                                tags: [],
                              } as unknown as RoutingNodeValue);
                              if (destuffing_node?.[1]?.id || destuffing_node?.[1]?._id) {
                                all_routing_nodes[
                                  destuffing_node?.[1]?._id || destuffing_node?.[1]?.id || ''
                                ] = new_origin_node;
                              }
                            }

                            onOnCarriageChange({
                              routing_legs: get_legs_by_routing_type(
                                all_routing_legs,
                                'on_carriage'
                              ),
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        }
                        if (e.target.value === STUFFING_TYPE_CFS) {
                          const new_disabled_routes = removeRoutesFromDisabled(
                            disabledRoutingPaths,
                            ['on_carriage']
                          );
                          const place_of_carrier_delivery_node = Object.entries(
                            all_routing_nodes
                          ).find(
                            ([key, value]) =>
                              value?.tags?.includes('place_of_carrier_delivery') &&
                              !value?.tags?.includes('port_of_discharge')
                          );
                          if (place_of_carrier_delivery_node?.[0]) {
                            const new_pocd_node = getNewRoutingNode({
                              ...all_routing_nodes[place_of_carrier_delivery_node?.[0]],
                              tags: ['place_of_carrier_delivery'],
                            } as RoutingNodeValue);
                            if (
                              place_of_carrier_delivery_node?.[1]?.id ||
                              place_of_carrier_delivery_node?.[1]?._id
                            ) {
                              all_routing_nodes[
                                place_of_carrier_delivery_node?.[1]?._id ||
                                  place_of_carrier_delivery_node?.[1]?.id ||
                                  ''
                              ] = new_pocd_node;
                            }
                          }

                          const delivery_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'delivery'
                          );
                          const delivery_destination_id = delivery_leg?.destination_id;
                          const destuffing_old_node = Object.entries(all_routing_nodes).find(
                            ([key, value]) =>
                              value?.id === delivery_destination_id ||
                              value?._id === delivery_destination_id
                          );
                          if (destuffing_old_node?.[0]) {
                            const new_dest_node = getNewRoutingNode({
                              ...all_routing_nodes[destuffing_old_node?.[0]],
                              tags: ['destuffing_location'],
                            } as RoutingNodeValue);
                            if (destuffing_old_node?.[1]?.id || destuffing_old_node?.[1]?._id) {
                              all_routing_nodes[
                                destuffing_old_node?.[1]?._id || destuffing_old_node?.[1]?.id || ''
                              ] = new_dest_node;
                            }
                          }
                          if (delivery_leg) {
                            onDeStuffingChange({
                              routing_legs: [delivery_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                          setDisabledRoutingPaths([...new_disabled_routes, 'pod_buffer']);
                        }
                        if (e.target.value === STUFFING_TYPE_FACTORY) {
                          const on_carriage_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'on_carriage'
                          );
                          const pod_buffer_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'pod_buffer'
                          );
                          if (on_carriage_leg) {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['on_carriage']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          } else if (pod_buffer_leg) {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['pod_buffer']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          } else {
                            const new_disabled_routes = removeRoutesFromDisabled(
                              disabledRoutingPaths,
                              ['pod_buffer', 'on_carriage']
                            );
                            setDisabledRoutingPaths([...new_disabled_routes]);
                          }
                          const place_of_carrier_delivery_node = Object.entries(
                            all_routing_nodes
                          ).find(
                            ([key, value]) =>
                              value?.tags?.includes('place_of_carrier_delivery') &&
                              !value?.tags?.includes('port_of_discharge')
                          );
                          if (place_of_carrier_delivery_node?.[0]) {
                            const new_pocd_node = getNewRoutingNode({
                              ...all_routing_nodes[place_of_carrier_delivery_node?.[0]],
                              tags: ['place_of_carrier_delivery'],
                            } as RoutingNodeValue);
                            if (
                              place_of_carrier_delivery_node?.[1]?.id ||
                              place_of_carrier_delivery_node?.[1]?._id
                            ) {
                              all_routing_nodes[
                                place_of_carrier_delivery_node?.[1]?._id ||
                                  place_of_carrier_delivery_node?.[1]?.id ||
                                  ''
                              ] = new_pocd_node;
                            }
                          }
                          const delivery_leg = all_routing_legs.find(
                            (leg: any) => leg.routing_type === 'delivery'
                          );
                          const delivery_destination_id = delivery_leg?.destination_id;
                          const destuffing_old_node = Object.entries(all_routing_nodes).find(
                            ([key, value]) =>
                              value?.id === delivery_destination_id ||
                              value?._id === delivery_destination_id
                          );
                          if (destuffing_old_node?.[0]) {
                            const new_dest_node = getNewRoutingNode({
                              ...all_routing_nodes[destuffing_old_node?.[0]],
                              tags: ['destuffing_location'],
                            } as RoutingNodeValue);
                            if (destuffing_old_node?.[1]?.id || destuffing_old_node?.[1]?._id) {
                              all_routing_nodes[
                                destuffing_old_node?.[1]?._id || destuffing_old_node?.[1]?.id || ''
                              ] = new_dest_node;
                            }
                          }
                          if (delivery_leg) {
                            onDeStuffingChange({
                              routing_legs: [delivery_leg],
                              routing_nodes: all_routing_nodes,
                            });
                          }
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => {
                      const destuffingType = getFieldValue('destuffing_type');
                      if (destuffingType === STUFFING_TYPE_FACTORY) {
                        return (
                          <Form.Item
                            name="destuffing_location"
                            label="Destuffing Location"
                            required
                            rules={[{ required: true }]}
                          >
                            <AddressCompanySearch
                              companySearchProps={{
                                disabled: disableNodes,
                              }}
                              addressSearchProps={{
                                disabled: disableNodes,
                              }}
                              onChange={(val: any) => {
                                const destuffing_leg = all_routing_legs.find(
                                  (leg: any) => leg.routing_type === 'delivery'
                                );
                                const destuffing_node_id = destuffing_leg?.destination_id;
                                if (destuffing_node_id) {
                                  all_routing_nodes[destuffing_node_id] = {
                                    ...all_routing_nodes[destuffing_node_id],
                                    address: value ? val?.party_address : null,
                                    company: value ? val?.party_company : null,
                                    location: null,
                                  };
                                }

                                if (destuffing_leg) {
                                  onDeStuffingChange({
                                    routing_legs: [destuffing_leg],
                                    routing_nodes: all_routing_nodes,
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        );
                      } else if (destuffingType === STUFFING_TYPE_CFS) {
                        return (
                          <Form.Item
                            name="destuffing_location"
                            label="Destuffing Location"
                            required
                            rules={[{ required: true }]}
                          >
                            <GlobalSearch
                              doc_type="Global::Location"
                              searchProps={{
                                type: [LOCATION_TYPE_CFS],
                              }}
                              onChange={(val: any) => {
                                const destuffing_leg = all_routing_legs.find(
                                  (leg: any) => leg.routing_type === 'delivery'
                                );
                                const destuffing_node_id = destuffing_leg?.destination_id;
                                if (destuffing_node_id) {
                                  all_routing_nodes[destuffing_node_id] = {
                                    ...all_routing_nodes[destuffing_node_id],
                                    address: null,
                                    company: null,
                                    location: val,
                                  };
                                }

                                if (destuffing_leg) {
                                  onDeStuffingChange({
                                    routing_legs: [destuffing_leg],
                                    routing_nodes: all_routing_nodes,
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        );
                      } else return <></>;
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Panel>
      )}
    </Collapse>
  );
});

const BookingRoutingDetailsNew = React.memo(BookingRoutingDetailsComp);

export default BookingRoutingDetailsNew;
