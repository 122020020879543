import React, { useEffect } from 'react';
import { getUpsertCompanyPayload, UpsertCompanyFormValues } from '../CompanyForm';
import { FormInstance } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { UPSERT_COMPANY } from 'network/graphql/company';
import { message, Form } from '@shipmnts/pixel-hub';
import { CompanyValue, CompanyAccountsData } from 'network/models/Company';
import { errorMessageHandlerGraphQLString } from 'common';

const SectionEditFormWrapper = React.memo(function SectionEditFormWrapper(props: {
  form: FormInstance;
  initialValues?: Partial<UpsertCompanyFormValues>;
  setEditMode: (value: boolean) => void;
  setLoading?: (value: boolean) => void;
  children?: React.ReactNode;
  company?: CompanyValue;
  onSuccess?: (company: CompanyValue, companyAccountsData?: CompanyAccountsData) => void;
}): JSX.Element {
  const { form, children, initialValues, setLoading, setEditMode, onSuccess } = props;
  const [upsertCompany, { data, loading, error }] = useMutation(UPSERT_COMPANY);

  useEffect(() => {
    if (setLoading) setLoading(loading);
  }, [setLoading, loading]);

  useEffect(() => {
    if (error) {
    }
    if (data?.upsert_company) {
      const { company, company_accounts_data } = data?.upsert_company;
      if (onSuccess) onSuccess(company, company_accounts_data);
      message.success('Saved successfully!');
      setEditMode(false);
    }
  }, [data, error, setEditMode, onSuccess]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values: Partial<UpsertCompanyFormValues>) => {
        const payload = getUpsertCompanyPayload(values, initialValues);
        if (!payload) return;
        if (payload.is_msme !== undefined) payload.is_msme = payload.is_msme ? 1 : 0;
        upsertCompany({ variables: { company: payload } });
      }}
    >
      {error && errorMessageHandlerGraphQLString(error)}
      <Form.Item noStyle name="id">
        <span />
      </Form.Item>
      {children}
    </Form>
  );
});

export default SectionEditFormWrapper;
