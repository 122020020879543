import { InquiryValue } from 'sales_hub/models/inquiry';
import {
  FREIGHT_TYPE_OCEAN,
  FREIGHT_TYPE_ROAD,
  INQUIRY_OPTIONS_COLOR_HASH,
  INQUIRY_OPTIONS_DISPLAY_HASH,
  PERMISSION_BUY_ESTIMATE,
  PERMISSION_SELL_ESTIMATE,
  RATES_RESOURCE_TYPE_MAP,
  SERVICE_TYPE_DESTINATION_CLEARANCE,
  SERVICE_TYPE_FREIGHT,
  SERVICE_TYPE_ORIGIN_CLEARANCE,
} from './constants';
import { calculateVolumetricWeight, convertNumber } from './dimensionHelper';
import { Unit } from 'convert-units';
import {
  RateExplorerData,
  RateExplorerResource,
} from 'sales_hub/components/Inquiry/RateExplorer/RateExplorer.type';
import { Column } from 'common/models/Report';
import { baseHelper, baseConstants } from 'common';

import { PERMISSION_CUSTOMER_VIEW } from 'network/permissions';
import { SessionDataValue } from 'common/models/SessionData';
import { ShipmentEstimateValue } from 'sales_hub/models/ShipmentEstimate';
import { pick as _pick, startCase as _startCase, round as _round } from 'lodash';
import { Modal, hasPermission } from '@shipmnts/pixel-hub';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
const { PERMISSION_CUSTOMER_CREATE_EDIT, LOAD_TYPE_LCL } = baseConstants;
const { getExchangeRate, renderShorthandServices } = baseHelper;

/* eslint-disable no-template-curly-in-string */
const typeTemplate = '${name} is not a valid ${type}';

const getFinalColumns = (
  sessionData: SessionDataValue,
  columnDefs: { [key: string]: Column },
  resource: RateExplorerResource
) => {
  const finalCols = [];
  for (const key in columnDefs || {}) {
    if (
      key === 'freight_buy_unit_rate' &&
      !hasPermission(sessionData?.permissions, {
        name: PERMISSION_BUY_ESTIMATE,
        docType: 'NewAccounting::ShipmentEstimate',
      })
    )
      continue;
    if (
      key === 'freight_sell_unit_rate' &&
      !hasPermission(sessionData?.permissions, {
        name: PERMISSION_SELL_ESTIMATE,
        docType: 'NewAccounting::ShipmentEstimate',
      })
    )
      continue;

    if (
      key === 'total_buy' &&
      !hasPermission(sessionData?.permissions, {
        name: PERMISSION_BUY_ESTIMATE,
        docType: 'NewAccounting::ShipmentEstimate',
      })
    )
      continue;
    if (
      key === 'total_sell' &&
      !hasPermission(sessionData?.permissions, {
        name: PERMISSION_SELL_ESTIMATE,
        docType: 'NewAccounting::ShipmentEstimate',
      })
    )
      continue;
    if (
      ['margin', 'margin_per'].includes(key) &&
      (!hasPermission(sessionData?.permissions, {
        name: PERMISSION_BUY_ESTIMATE,
        docType: 'NewAccounting::ShipmentEstimate',
      }) ||
        !hasPermission(sessionData?.permissions, {
          name: PERMISSION_SELL_ESTIMATE,
          docType: 'NewAccounting::ShipmentEstimate',
        }))
    )
      continue;

    if (
      key === 'customer_link' &&
      !hasPermission(sessionData?.permissions, {
        name: PERMISSION_CUSTOMER_VIEW,
        docType: 'Network::Company',
      })
    )
      continue;

    if (key === 'conatiner_types' && resource?.freight_type !== FREIGHT_TYPE_OCEAN) continue;

    finalCols.push(key);
  }

  return finalCols;
};

export const getRateExplorerColumn = (
  result: RateExplorerData,
  resource: RateExplorerResource,
  sessionData: SessionDataValue,
  setRates: (rates: RateExplorerData | undefined) => void
) => {
  const columnDefs: { [key: string]: Column } = {
    quote_link: {
      headerName: 'Reference ID',
      field: 'resource_link',
      colId: 'resource_link',
      width: 200,
      cellStyle: {
        color: '#084FCA',
        cursor: 'pointer',
      },
      onCellClicked: (params) => {
        setRates(params?.data);
      },
      keyCreator: (params: any) => params?.value?.value,
      pinned: 'left',
    },
    carrier_name: {
      headerName: 'Carrier',
      field: 'carrier_name',
      colId: 'carrier_name',
      columnType: 'String',
      width: 200,
      cellRenderer: 'string_with_tool_tip',
      hide: resource.freight_type === FREIGHT_TYPE_ROAD || resource.is_custom,
    },
    rate_type: {
      headerName: 'Rate Type',
      field: 'resource_type',
      colId: 'resource_type',
      columnType: 'String',
      cellRenderer: 'TagsRender',
      width: 200,
      valueGetter: (params) => {
        const resourceItemType = params?.data?.resource_type;
        return [RATES_RESOURCE_TYPE_MAP[resourceItemType]]; // returning in array, as TagsRender expects to do so.
      },
    },
    freight_buy_unit_rate: {
      headerName: 'Freight Buy / Unit',
      field: 'freight_buy_unit_rate',
      colId: 'freight_buy_unit_rate',
      aggFunc: undefined,
      width: 200,
      columnType: 'Currency',
      hide: resource.freight_type === FREIGHT_TYPE_ROAD || resource.is_custom,
      cellRendererParams: {
        currency_column: 'freight_buy_unit_cur',
      },
    },
    freight_sell_unit_rate: {
      headerName: 'Freight Sell / Unit',
      field: 'freight_sell_unit_rate',
      colId: 'freight_sell_unit_rate',
      aggFunc: undefined,
      minWidth: 100,
      hide: resource.freight_type === FREIGHT_TYPE_ROAD || resource.is_custom,
      columnType: 'Currency',
      cellRendererParams: {
        currency_column: 'freight_sell_unit_cur',
      },
    },
    total_freight_margin_unit: {
      headerName: 'Freight Unit / Margin',
      field: 'total_freight_margin_unit',
      colId: 'total_freight_margin_unit',
      width: 200,
      hide: resource.freight_type === FREIGHT_TYPE_ROAD || resource.is_custom,
      cellRenderer: 'MarginRender',
      cellRendererParams: {
        sell_currency_column: 'freight_sell_unit_cur',
        buy_currency_column: 'freight_buy_unit_cur',
        buy_amount_column: 'freight_buy_unit_rate',
        sell_amount_column: 'freight_sell_unit_rate',
      },
    },
    total_sell: {
      headerName: 'Total Income',
      field: 'total_sell',
      colId: 'total_sell',
      aggFunc: undefined,
      width: 200,
      columnType: 'Currency',
    },
    total_buy: {
      headerName: 'Total Expense',
      field: 'total_buy',
      colId: 'total_buy',
      aggFunc: undefined,
      width: 200,
      columnType: 'Currency',
    },
    margin: {
      headerName: 'Margin',
      field: 'margin',
      colId: 'margin',
      aggFunc: undefined,
      width: 200,
      columnType: 'Currency',
    },
    quote_status: {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      aggFunc: undefined,
      cellRenderer: 'StatusRenderer',
      width: 200,
      columnType: 'String',
      cellRendererParams: {
        filter_options: {
          colors: INQUIRY_OPTIONS_COLOR_HASH,
          display_hash: INQUIRY_OPTIONS_DISPLAY_HASH,
        },
      },
    },
    routing: {
      headerName: 'Routing',
      field: 'routing',
      colId: 'routing',
      hide: resource.freight_type !== FREIGHT_TYPE_ROAD,
      columnType: 'String',
      width: 200,
      cellRenderer: 'string_with_tool_tip',
    },
    valid_from: {
      headerName: 'Quote Date',
      field: 'valid_from',
      colId: 'valid_from',
      width: 200,
      hide: true,
      columnType: 'DateTime',
    },
    valid_to: {
      headerName: 'Valid till date',
      field: 'valid_to',
      colId: 'valid_to',
      width: 200,
      columnType: 'DateTime',
    },
    customer_link: {
      headerName: 'Customer',
      field: 'customer_link',
      colId: 'customer_link',
      columnType: 'Link',
      width: 200,
      cellRendererParams: {
        id_field: 'id',
        doc_type_id: 'Network::Company',
      },
    },
    conatiner_types: {
      headerName: 'Container Type',
      field: 'conatiner_types',
      colId: 'conatiner_types',
      width: 200,
      cellRenderer: 'TagsRender',
      hide: resource.freight_type === resource.is_custom,
    },
    pol_name: {
      headerName: 'Port Of Loading',
      field: 'pol_name',
      colId: 'pol_name',
      columnType: 'String',
      width: 200,
      hide: resource.freight_type === FREIGHT_TYPE_ROAD,
      cellRenderer: 'string_with_tool_tip',
    },
    pod_name: {
      headerName: 'Port Of Discharge',
      field: 'pod_name',
      colId: 'pod_name',
      hide: resource.freight_type === FREIGHT_TYPE_ROAD,
      columnType: 'String',
      width: 200,
      cellRenderer: 'string_with_tool_tip',
    },
    services: {
      headerName: 'Scope Of Work',
      field: 'services',
      colId: 'services',
      width: 200,
      columnType: 'String',
      cellRenderer: 'TagsRender',
    },
  };
  const finalCols = getFinalColumns(sessionData, columnDefs, resource);
  return Object.values(finalCols.map((col: string) => columnDefs[col]));
};

export const defaultValidateMessages = {
  default: 'Validation error on field ${name}',
  required: 'Required',
  enum: '${name} must be one of [${enum}]',
  whitespace: '${name} cannot be empty',
  date: {
    format: '${name} is invalid for format date',
    parse: '${name} could not be parsed as date',
    invalid: '${name} is invalid date',
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: '${name} must be exactly ${len} characters',
    min: '${name} must be at least ${min} characters',
    max: '${name} cannot be longer than ${max} characters',
    range: '${name} must be between ${min} and ${max} characters',
  },
  number: {
    len: '${name} must equal ${len}',
    min: '${name} cannot be less than ${min}',
    max: '${name} cannot be greater than ${max}',
    range: '${name} must be between ${min} and ${max}',
  },
  array: {
    len: '${name} must be exactly ${len} in length',
    min: '${name} cannot be less than ${min} in length',
    max: '${name} cannot be greater than ${max} in length',
    range: '${name} must be between ${min} and ${max} in length',
  },
  pattern: {
    mismatch: '${name} does not match pattern ${pattern}',
  },
};

export function isOnlyClearance(services: any): boolean {
  return (
    ((services || [])?.includes(SERVICE_TYPE_ORIGIN_CLEARANCE) ||
      (services || [])?.includes(SERVICE_TYPE_DESTINATION_CLEARANCE)) &&
    !(services || [])?.includes(SERVICE_TYPE_FREIGHT)
  );
}

export function getChargeableWeightCondition(value: string, inquiry: InquiryValue) {
  const data = (value || '').split('_');
  const operator = data?.[0] === 'less' ? '<=' : '>';
  const fieldValue = data?.[data.length - 1];
  return {
    chargeable_weight: fieldValue ? operator + fieldValue : undefined,
  };
}

export function getChargeableWeight(inquiry: InquiryValue) {
  const cargos = inquiry?.cargos;
  if (!cargos || cargos?.length <= 0) return 0;

  let totalGrossWeight = cargos?.reduce((init: number, ele: ProductOrderItemValue) => {
    return init + (ele.gross_weight || 0);
  }, 0);
  const weightUnit = (cargos?.[0]?.weight_unit || 'kg') as Unit;
  const volumeUnit = (cargos?.[0]?.volume_unit || 'cbm') as Unit;

  const grossWeightInTon = convertNumber(totalGrossWeight, weightUnit, 'mt');
  totalGrossWeight = convertNumber(totalGrossWeight, weightUnit, 'kg');
  const totalGrossVolumn = cargos?.reduce((init: number, ele: ProductOrderItemValue) => {
    return init + (ele.gross_volume || 0);
  }, 0);

  const totalVolumatricWeight = calculateVolumetricWeight({
    volume: totalGrossVolumn,
    volumeUnit: volumeUnit,
    weightUnit: weightUnit,
    factor: 6000,
  });

  let chargeableWeight;
  if (inquiry?.load_type === LOAD_TYPE_LCL && inquiry?.freight_type === FREIGHT_TYPE_OCEAN)
    chargeableWeight = totalGrossVolumn > grossWeightInTon ? totalGrossVolumn : grossWeightInTon;
  else
    chargeableWeight =
      totalGrossWeight > totalVolumatricWeight ? totalGrossWeight : totalVolumatricWeight;

  return _round(chargeableWeight || 0, 2);
}

export const getQuoteTableDate = (data: RateExplorerData[] | undefined) => {
  if (!data) return [];
  return data.map((reateExplorer: RateExplorerData) => {
    const totalBuy = reateExplorer?.total_buy || 0;
    const totalSell = reateExplorer?.total_sell || 0;
    const margin = totalSell - totalBuy;
    const unitFreightSell = reateExplorer?.freight_sell_unit_rate || 0;
    const unitFreightBuy = reateExplorer?.freight_buy_unit_rate || 0;
    const freightMargin = {
      extra_fields: {
        freight_sell_unit_cur: reateExplorer?.freight_sell_unit_cur,
        freight_buy_unit_cur: reateExplorer?.freight_buy_unit_cur,
        freight_buy_unit_rate: reateExplorer?.freight_buy_unit_rate,
        freight_sell_unit_rate: reateExplorer?.freight_sell_unit_rate,
      },
    };
    const carrier = reateExplorer?.carrier?.name;
    const customer = reateExplorer.customer_company?.registered_name;
    const services = (reateExplorer.services || []).map((ele) => {
      if (typeof ele === 'string')
        return { name: renderShorthandServices(_startCase(ele)), tooltip: _startCase(ele) };
      return { name: ele?.name, tooltip: ele?.tooltip };
    });

    return {
      ...reateExplorer,
      total_buy: totalBuy,
      total_sell: totalSell,
      freight_sell_unit_rate: unitFreightSell,
      freight_buy_unit_rate: unitFreightBuy,
      customer: customer,
      pol_name: reateExplorer?.port_of_loading?.name,
      pod_name: reateExplorer?.port_of_discharge?.name,
      carrier_name: carrier,
      margin: margin,
      total_freight_margin_unit: freightMargin,
      services: services,
      conatiner_types: reateExplorer?.container_types,
      customer_link: {
        value: reateExplorer?.customer_company?.registered_name,
        extra_fields: { id: reateExplorer?.customer_company?.id },
      },
      resource_link: reateExplorer.resource_number,
    };
  });
};

export function getRateExplorerFromInquiry(inquiry: InquiryValue): RateExplorerResource {
  return {
    freight_type: inquiry?.freight_type,
    customer_company: inquiry?.customer_company,
    is_custom: isOnlyClearance(inquiry.services),
    trade_type: inquiry?.trade_type,
  } as RateExplorerResource;
}
const COMMON_SHIPMENT_ESTIMATES_FIELDS = [
  'item',
  'uom',
  'tag',
  'remarks',
  'equipment_type',
  'equipment_name',
  'sequence',
  'item_group',
  'item_description',
  'tax_percentage',
  'taxable',
  'taxability',
  'markup_type',
  'markup_value',
];
const SELL_SHIPMENT_ESTIMATES_FIELDS = [
  'sell_terms',
  'sell_rate',
  'sell_exchange_rate',
  'sell_currency',
  'customer_company_id',
  'total_sell_amount',
];

const BUY_SHIPMENT_ESTIMATES_FIELDS = [
  'buy_terms',
  'buy_rate',
  'buy_exchange_rate',
  'buy_currency',
  'supplier_company_id',
  'supplier_company',
  'total_buy_amount',
];

export const getNewEstimates = (oldEstimates: ShipmentEstimateValue[] | undefined) => {
  return (oldEstimates || []).map((ele: ShipmentEstimateValue) => {
    let shipmentEst: Partial<ShipmentEstimateValue> = {
      ..._pick(ele, COMMON_SHIPMENT_ESTIMATES_FIELDS),
      sell_terms: 'prepaid',
      sell_rate: 0,
      sell_status: 'unbilled',
      sell_exchange_rate: 1,
      buy_terms: 'prepaid',
      buy_rate: 0,
      buy_status: 'unbilled',
      buy_exchange_rate: 1,
      inquiry_option_id: 'new',
      quantity: 1,
    };

    shipmentEst = {
      ...shipmentEst,
      ..._pick(ele, BUY_SHIPMENT_ESTIMATES_FIELDS),
      ..._pick(ele, SELL_SHIPMENT_ESTIMATES_FIELDS),
    };

    return shipmentEst;
  });
};

export const showConvertLeadErrorModal = (
  session?: SessionDataValue,
  resourceType?: string,
  inquiry?: InquiryValue
) => {
  const hasCustomerPermisson = hasPermission(session?.permissions || [], {
    AND: [
      { name: PERMISSION_CUSTOMER_VIEW, docType: 'Network::Company' },
      { name: PERMISSION_CUSTOMER_CREATE_EDIT, docType: 'Network::Company' },
    ],
  });

  const errorMsg = hasCustomerPermisson
    ? `To create ${_startCase(resourceType)} convert this Lead into a Customer.`
    : 'You do not have the authorization to convert this Lead into Customer. Kindly reach out to the designated Individual with Customer Creation Privileges to proceed further.';

  Modal.error({
    title: 'Error',
    okText: hasCustomerPermisson ? 'Create Customer' : 'Ok',
    maskClosable: true,
    closable: true,
    content: errorMsg,
    onOk() {
      if (hasCustomerPermisson) {
        const url = `/view/company/${inquiry?.customer_company?.id}`;
        window.open(url, '_self');
      }
    },
  });
};

export const convertEstimateInQuoteCurrency = async (
  quoteCurrency: string | undefined | null,
  estimates: Partial<ShipmentEstimateValue>[]
) => {
  if (!quoteCurrency) return estimates;
  const newEstimates = [];
  const exchangeRate: Record<string, number> = {};
  for (const estimate of estimates) {
    const newEstimate = _pick(estimate, [
      ...COMMON_SHIPMENT_ESTIMATES_FIELDS,
      'sell_terms',
      'sell_rate',
      'sell_currency',
      'buy_terms',
      'buy_rate',
      'buy_currency',
      'buy_status',
      'sell_status',
      'inquiry_option_id',
      'quantity',
    ]);
    newEstimate['sell_exchange_rate'] = exchangeRate?.[estimate?.sell_currency || '']
      ? exchangeRate?.[estimate?.sell_currency || '']
      : (await getExchangeRate(estimate?.sell_currency, quoteCurrency)) || 1;
    exchangeRate[estimate?.sell_currency || ''] = newEstimate['sell_exchange_rate'] || 1;

    newEstimate['buy_exchange_rate'] = exchangeRate?.[estimate?.buy_currency || '']
      ? exchangeRate?.[estimate?.buy_currency || '']
      : (await getExchangeRate(estimate?.buy_currency, quoteCurrency)) || 1;
    exchangeRate[estimate?.buy_currency || ''] = newEstimate['buy_exchange_rate'] || 1;

    newEstimate['total_sell_amount'] =
      (newEstimate['sell_exchange_rate'] || 1) *
      (newEstimate['sell_rate'] || 0) *
      (newEstimate['quantity'] || 1);

    newEstimate['total_buy_amount'] =
      (newEstimate['buy_exchange_rate'] || 1) *
      (newEstimate['buy_rate'] || 0) *
      (newEstimate['quantity'] || 1);

    newEstimates.push(newEstimate);
  }

  return newEstimates as ShipmentEstimateValue[];
};
